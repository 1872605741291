import React from 'react'
import Modal from 'react-modal'

const customModalStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
}

export default function MLSettings(props) {
  // TOOD: fetch this from backend, waiting for Node.js
  const defaultProjects = [
    {"name": "DEMO", "id": 257, "checked": true},
    {"name": "AVR CSV 7", "id": 337, "checked": true},
    {"name": "WSSC 3", "id": 284, "checked": false},
    {"name": "AWWU 2019 4", "id": 192, "checked": false},
    {"name": "GWINNETT 2018 DEC", "id": 161, "checked": false}
  ]

  const defaultInclusions = {}
  defaultProjects.forEach((p) => { defaultInclusions[p.id] = p.checked })

  const [modalIsOpen,setIsOpen] = React.useState(false)
  const [included, setIncluded] = React.useState(defaultInclusions)

  function closeModal(){
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  function saveSettings() {
    closeModal()
    // TODO: waiting for Node.js
  }

  return (
    <>
      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customModalStyles}
        contentLabel="Settings"
      >
        <div style={{margin: "3rem"}}>
          <h2>Projects</h2>
          <p>Please choose which projects to include in large ML model.</p>
          <div className="row">
            <table>
              <thead>
                <tr>
                  <th>Project</th>
                  <th style={{textAlign: "center"}}>Included</th>
                </tr>
              </thead>
              <tbody>
                {defaultProjects.map((p, i) => (
                  <tr key={p["id"]}>
                    <td>{p["name"]}</td>
                    <td style={{textAlign: "center"}}>
                      <input
                        style={{position: "relative", margin: "0"}}
                        className="form-check-input"
                        type="checkbox"
                        defaultChecked={included[p.id]}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="row" style={{marginTop: "2rem"}}>
            <button className="btn btn-primary" style={{marginRight: "1rem"}} onClick={saveSettings}>Save</button>
            <button className="btn btn-secondary" onClick={closeModal}>Close</button>
          </div>
        </div>
      </Modal>
      <button className="btn btn-secondary" onClick={openModal}>
        Settings
      </button>
    </>
  )
}
