import React from 'react'
import { map, trim } from 'lodash'
import Column from './Column'

class EURBreakpointInput extends React.Component {
  state = {
    value: this.props.value
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value })
    }
  }

  onChange = (e) => this.setState({ value: e.currentTarget.value })
  onBlur = () => {
    const value = trim(this.state.value)

    if (value && value !== this.props.value) {
      this.props.onChange(this.props.index, value)
    }
  }

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.onBlur()
    }
  }

  render() {
    const { value } = this.state

    return (
      <input
        type="text"
        name="eul_breakpoints[]"
        value={ value }
        onChange={ this.onChange }
        onBlur={ this.onBlur }
        onKeyDown={ this.onKeyDown }
        className="cohorts-header-eul_breakpoint_input"
      />
    )
  }
}

export default function Header({
  relevance_year, recent_years, highlightColumnProps: columnProps,
  eulBreakpoints, onEulBreakpointChange, updateCohorts, showPredictions
}) {
  return (
    <div className="cohorts-cohort cohorts-cohort-header">
      <div className="cohorts-cohort-cohort" style={{ position: 'relative' }}>
        <div className="cohorts-cohort-cohort-include_in_plan">
          Create and select all sub-cohorts to be analyzed in Plan
          <small>Make sure all selected sub-cohorts do not overlap</small>
          <div className="text-muted">
            (<button
              type="button"
              className="btn btn-link"
              onClick={ () => updateCohorts({ include_in_plan: true }) }
            >Select all</button> • <button
              className="btn btn-link"
              type="button"
              onClick={ () => updateCohorts({ include_in_plan: false }) }
            >Clear all</button>)
          </div>
        </div>
      </div>

      <div className="cohort-numbers">
        <div className="cohort-numbers-group">
          <Column { ...columnProps } name="length_all" wider>ALL</Column>
          <Column { ...columnProps } name="length_act" wider>ACT</Column>
          <Column { ...columnProps } name="length_abn" wider>ABN</Column>
          <div className="cohort-numbers-overtitle">Length (mi)</div>
        </div>
        <Column { ...columnProps } name="abn_rate" wide>Rate of ABN/ALL</Column>
        <div className="cohort-numbers-group">
          <Column { ...columnProps } name="aybr_all">ALL</Column>
          <Column { ...columnProps } name="aybr_act">ACT</Column>
          <Column { ...columnProps } name="aybr_abn">ABN</Column>
          <div className="cohort-numbers-overtitle"><span>Average <br />Yearly Break Rate <sup>(1)</sup><sup>(2)</sup></span></div>
        </div>
        <Column { ...columnProps } name="aybr_abn_all" wide>Ratio of Average Yearly Break Rate ABN/ALL</Column>
        <Column { ...columnProps } name="recent_break_rate" wide><span>Average Break Rate { recent_years[0] }–{ recent_years[1] } <sup>(1)</sup></span></Column>
        <div className="cohort-numbers-group">
          <Column { ...columnProps } name="average_age_all">ALL</Column>
          <Column { ...columnProps } name="average_age_act">ACT</Column>
          <Column { ...columnProps } name="average_age_abn">ABN</Column>
          <div className="cohort-numbers-overtitle">Average Age <sup>(2)</sup></div>
        </div>
        {
          showPredictions && <>
              <Column className="cohorts-cohort-prediction-name">Casses Model</Column>
              <Column { ...columnProps } name="pbr_next_year" wide><span>Predicted Break Rate { relevance_year + 1 } <sup>(1)</sup></span></Column>
              <Column { ...columnProps } name="aging_factor"><span>Aging Factor <sup>(3)</sup></span></Column>
              <div className="cohort-numbers-group">
                { map(eulBreakpoints, (pbr, index) => (
                    <Column key={ index } { ...columnProps } name={ `eul_${index}` }>
              <span>
                <EURBreakpointInput
                    index={ index }
                    value={ pbr }
                    onChange={ onEulBreakpointChange }
                /><sup>(1)</sup></span>
                    </Column>
                ))}
                <div className="cohort-numbers-overtitle">Effective Useful Life (years)</div>
              </div>
              <Column className="cohorts-cohort-prediction-delete" />
          </>
        }
      </div>
    </div>
  )
}
