import React from 'react'

// import s from './Button.scss'

export default ({ method, href, ...props }) => {
  const buttonProps = { ...props }

  if (method && (method.toLowerCase() !== 'get')) {
    buttonProps.type = 'submit'

    //      <form className={ s.inlineForm } method="POST" action={ href }>
    return (
      <form style={{"display": "inline"}} method="POST" action={ href }>
        <input type="hidden" name="_method" value={ method } />
        <button type="submit" { ...buttonProps } />
      </form>
    )
  }

  return <button type="button" { ...buttonProps } />
}
