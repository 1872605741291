import {useEffect, useRef, useState} from "react";


function useApi(method, url, data, options)
{
    const loadingRef = useRef(true);

    const [state, setState] = useState({
        loading: true
    });

    const fetchData = ()=>{
        loadingRef.current = true;
        console.log(url)
        Api[method](url, data, options)
            .then(data => {
                loadingRef.current = false;
                setState({ data })
            })
            .catch(error => {
                loadingRef.current = false;
                setState({ error })
            })

    }

    useEffect(fetchData, [url, JSON.stringify(data)]);

    const setData = (data)=>{
        setState((prevState) =>{
            return {
                ...prevState,
                data
            }
        })
    };

    return {
        ...state,
        loading: loadingRef.current,
        setData,
        reloadData: fetchData
    };
}

for(const method of ["get", "post", "patch", "put", "delete"]) {
    useApi[method] = function () {
        return useApi(method, ...arguments);
    }
}

export default useApi;
