import React from 'react'
import {
  map, min, max, range, compact,
  round, ceil, keys, mapKeys, uniq
} from 'lodash'
import memoize from 'lru-memoize'

import LineAndPoints from '../Chart/LineAndPoints'

const getYears = (breakRates) => map(keys(breakRates), (year) => parseFloat(year), 2)

const getXs = (breakRates) => {
  const years = getYears(breakRates)
  const yearsRounded = map(years, (year) => ceil(year))

  const from = min(yearsRounded)
  const to = max(yearsRounded) + 60

  return uniq(range(from, to).concat(
    map(years, (year) => round(year, 2))
  ).sort((a, b) => a - b))
}

const getLine = (xs, breakRateByAge) => map(xs, (age) => ({
  x: age,
  y: breakRateByAge(age)
}))

// We round break rate points age value
// to snap it to the grid.
// Not 100% correct, but Annie asked for it.
// To revert it, read breakRates instead of ceailedBreakRates and
// add .concat(years).sort(...) to getXs()
const getPoints = (xs, breakRates) => {
  const roundedBreakRates = mapKeys(breakRates, (_value, key) => round(parseFloat(key), 2))

  return compact(map(xs, (age) => {
    const breakRate = roundedBreakRates[age]

    if (!breakRate) return null

    return {
      x: age,
      y: breakRate
    }
  }))
}

const getSeries = memoize()((breakRateByAge, breakRates) => {
  const xs = getXs(breakRates)

  const line = {
    label: 'Geometric Prediction Curve',
    color: 'rgba(150, 200, 200, 1)',
    data: getLine(xs, breakRateByAge)
  }

  const points = {
    label: 'Data',
    color: 'rgba(125,99,132,1)',
    data: getPoints(xs, breakRates)
  }

  return { line, points }
})

const renderTooltipValue = (value) => round(value, 3)

export default class Chart extends React.PureComponent {
  chart = React.createRef()

  download = () => {
    const svg = this.chart.current && this.chart.current.svg.current

    if (svg) {
      window.downloadSvg(svg, {
        filename: this.props.name.replace(/\.zip/, '')
      })
    }
  }

  render() {
    const { breakRateByAge, breakRates } = this.props
    const { line, points } = getSeries(breakRateByAge, breakRates)

    return (
      <div className="prediction-chart">
        <div style={{ height: 500 }}>
          <LineAndPoints
            line={ line }
            points={ points }
            bottomLabel="Age"
            leftLabel="Predicted Break Rate (Nb Breaks/mi/yr)"
            ref={ this.chart }
            renderTooltipValue={ renderTooltipValue }
          />
        </div>

        <button
          type="button"
          className="prediction-chart-download btn btn-sm btn-secondary"
          onClick={ this.download }
        >
          Save as Image
        </button>
      </div>
    )
  }
}
