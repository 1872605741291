import React from "react";

export default function CohortsNotes() {
    return <ol className="cohorts-notes">
        <li>number of breaks / mile / year</li>
        <li>as of now for ACT pipes, as of YOA for ABN pipes</li>
        <li>
            <div className="prediction-curve-formula">
                <i>PBR</i>
                <sub>year&nbsp;n+1</sub>
                <span> = </span>
                <i>PBR</i>
                <sub>year&nbsp;n</sub>
                <span> × </span>
                <strong>Aging Factor</strong>
            </div>
        </li>
        <li>Capped at 200</li>
    </ol>
}