import React from 'react'
import {useState} from 'react'
import MaterialColors from '../ColorMapping/ColorMapping'
import RegisterCode from "../RegisterCode";

const SETTINGS = {
    "material_colors": {
        title: "Material Colors",
        component: <MaterialColors/>
    },
    "register_code": {
        title: "Register Code",
        component: <RegisterCode/>
    }
}

const Settings = () => {
    const [chosenSetting, setChosenSetting] = useState(null)

    const gridStyle = {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gap: '10px',
    };

    const itemStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    return (
        <div>
            <h1>Settings <small>(Pick a setting to edit)</small></h1>
            <div style={gridStyle}>
                {Object.keys(SETTINGS).map((setting, index) =>
                     <div style={itemStyle} key={index} onClick={() => setChosenSetting(setting)}>
                       <button style={{
                           width: '100%',
                           height: '100%',
                       }} disabled={(setting === chosenSetting)}>
                        {SETTINGS[setting].title}
                       </button>
                    </div>
                )}
            </div>
            <hr/>
            {chosenSetting && SETTINGS[chosenSetting].component}
        </div>
    )
}

export default Settings