import React, {useRef} from 'react'
import { useState, useEffect } from 'react'
import ColorPicker from './ColorPicker';

export default (props) => {
    const { material, color:originalColor, onSave, style } = props;
    const [color, setColor] = useState(originalColor);
    const [pickerVisible, setPickerVisible] = useState(false);

    useEffect(()=>{
        setColor(originalColor);
    }, [originalColor]);

    const save = () => {
        setPickerVisible(false);
        return Api.put(`settings/material-colors`, { color, material })
            .then(() =>{
                toastr.success(`color for ${material} material was updated.`);
                onSave && onSave({material, color});
            })
            .catch((e)=>{
                console.log(e)
                toastr.error("something went wrong, color map was not updated.");
                setColor(originalColor);
            });
    }

    const cancel = () => {
        setPickerVisible(false);
        setColor(originalColor);
    }

    return <>
        <div style={{display: 'flex', alignItems: "baseline", alignContent: 'space-between',  borderRadius: 12, margin: 12,  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', ...style}}>
            <div style={{ width: '8rem', alignSelf: 'center',  margin: 12,  padding: '0.5rem', textAlign: 'left' }}>{material||"(empty)"}</div>
            <div style={{ display: 'flex',  alignItems: 'center', padding: '0.5rem', flexDirection: 'row' }}>
                <div
                    onClick={() => { setPickerVisible(true) }}
                    style={{
                        border: "thin solid black",
                        cursor: "pointer",
                        width: '20px',
                        height: '20px',
                        alignItems: 'center',
                        padding: '20px',
                        backgroundColor: color,
                        float: 'left',
                        margin: 12
                    }}
                />
            <div style={{  }}>
                <p style={{ marginBottom: "0" }}>
                    Value:&nbsp;
                    <input value={color}
                           type={"text"}
                           onBlur={save}
                           style={{ width: "5rem"}}
                           onChange={(e) => {
                               setColor(e.target.value)
                           }}
                    />
                </p>
            </div>
          </div>
            {
                pickerVisible &&
                    <ColorPicker color={color}
                                 onUpdateColor={setColor}
                                 onClickSave={save}
                                 onClickCancel={cancel}
                    />
            }
        </div>
    </>

}