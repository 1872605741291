import React from 'react'
import { map } from 'lodash'

import Cohort from './Cohort'

export default ({
  cohorts,
  urls,
  highlightColumnProps,
  replacePrediction,
  getJSONWithCache,
  updateCohort,
  updating,
  showPredictions
}) => (
  <div>
    { map(cohorts, ({ cohort, predictions, subcohorts }) => (
      <Cohort
        key={ cohort ? cohort.id : '(empty)' }
        cohort={ cohort }
        predictions={ predictions }
        subcohorts={ subcohorts }
        urls={ urls }
        highlightColumnProps={ highlightColumnProps }
        replacePrediction={ replacePrediction }
        getJSONWithCache={ getJSONWithCache }
        updateCohort={ updateCohort }
        updating={ updating }
        showPredictions={ showPredictions }
      />
    )) }
  </div>
)
