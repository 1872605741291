import React from 'react';
import Tab from "./Tab";

export default function ({tabs, currentAction}) {
    //!TODO: Tabs should come from Menus.js

    const infraplanTabBg = {
        background: "#a9b3bb",
        marginTop: "1rem",
        marginBottom: "0",
        display: 'flex',
        gap: '5px'
    }

    return (
        <>
            <ul style={infraplanTabBg}>
                {tabs.map((tab, index) =>
                    <Tab key={index} tab={tab}></Tab>
                )}
            </ul>
        </>
    )

}