import React from 'react'
import {
  compact, uniq, map
} from 'lodash'

import { scaleBand, scaleLinear } from '@vx/scale'
import { withParentSize } from '@vx/responsive'
import memoize from 'lru-memoize'
import { Brush as VxBrush } from '@vx/brush'
import Base from './Base'

import {
  minmax
} from '../misc'

const combineSeries = memoize()((bar, line) => compact([bar, line]))

const BOX_STYLE = {
  fill: 'rgba(0, 0, 0, .3)',
  stroke: 'rgba(0, 0, 0, .8)',
  strokeWidth: 1
}


class Brush extends React.PureComponent {
  render() {
    const {
      margin,
      bar,
      line,
      onChange,
      onClick,
      parentWidth: width,
      parentHeight: height
    } = this.props

    if (!width || !height) return null

    const series = combineSeries(bar, line)

    const xMax = width - margin.left - margin.right
    const yMax = height - margin.top - margin.bottom

    const domain = uniq([...map(bar.data, 'x'), ...map(line.data, 'x')])

    const xScale = scaleBand({
      domain,
      range: [0, xMax],
      padding: 0.2
    })

    const yScale = scaleLinear({
      domain: [0, minmax(series, 'y')[1]],
      range: [yMax, 0]
    })

    return (
      <Base
        margin={ margin }
        showVerticalAxes={ false }
        showPoints={ false }
        { ...this.props }
      >
        <VxBrush
          margin={ margin }
          xScale={ xScale }
          yScale={ yScale }
          width={ xMax }
          height={ yMax }
          handleSize={ 8 }
          resizeTriggerAreas={ ['left', 'right', 'bottomRight'] }
          brushDirection="horizontal"
          onChange={ onChange }
          onClick={ onClick }
          selectedBoxStyle={ BOX_STYLE }
        />
      </Base>
    )
  }
}

export default withParentSize(Brush)
