const percentFromRatio = (count, total) => {
    let result = (count / total) * 100;
    return (total ? prettyFloat(result) + '%' : '0%');
}
const prettyInt = (num) => num.toLocaleString();
const prettyFloat = (num) => {
    if (num < 1) {
        return num.toFixed(2);
    }
    return num.toFixed(1);
}
const renderTitle = (itemType, level, metric, initial, status) => {
    // Implement the logic to generate the title based on the parameters
    return `${itemType} ${level} ${metric} ${initial ? 'Initial' : 'Current'} ${status}`;
};

export { percentFromRatio, prettyInt, prettyFloat, renderTitle };