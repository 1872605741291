import React from 'react'
import { map, isString } from 'lodash'

export default class Select extends React.Component {
  onChange = (e) => {
    const { onChange } = this.props

    if (onChange) {
      onChange(e.currentTarget.value)
    }
  }

  componentDidMount() {
    const {
      value, includeBlank, onChange, options
    } = this.props

    if (!value && !includeBlank && onChange && options.length) {
      onChange(options[0][1])
    }
  }

  render() {
    const {
      options, name, className, value, includeBlank, ...props
    } = this.props

    const includeBlankLabel = isString(includeBlank) ? includeBlank : ''

    return (
      <select
        { ...props }
        name={ name }
        className={ className }
        value={ value || '' }
        onChange={ this.onChange }
      >
        { includeBlank && <option value="">{ includeBlankLabel }</option> }
        { map(options, ([label, option_value]) => (
          <option
            key={ option_value }
            value={ option_value }
          >{ label }</option>
        )) }
      </select>
    )
  }
}
