import { compact, repeat } from 'lodash'

const roundWithPrecision = (number, precision) => {
  const pow = 10 ** precision

  return Math.round(number * pow) / pow
}


const group = (integer, separator = ',') => (
  integer
    .split('')
    .reverse()
    .join('')
    .match(/\d{1,3}/g)
    .join(separator)
    .split('')
    .reverse()
    .join('')
)

export default (number, precision = 1) => {
  if (typeof number !== 'number') { return '' }

  const result = roundWithPrecision(number, precision).toString()

  let [integer, decimal] = result.split('.')
  integer = group(integer, ' ')

  const decimalLength = decimal ? decimal.length : 0

  if (decimalLength < precision) {
    decimal = (decimal || '') + repeat('0', precision - decimalLength)
  }

  return compact([integer, decimal]).join('.')
}

export const formatPercent = (number, precision = 0) => {
  if (number === undefined || Number.isNaN(number)) return ''

  const pow = 10 ** precision

  return `${Math.round(number * pow * 100) / pow}%`
}
