import { keys, uniq, reduce } from 'lodash'

export default (a, b) => reduce(
  uniq(keys(a).concat(keys(b))),
  (changedKeys, key) => {
    if (a[key] !== b[key]) {
      changedKeys.push(key)
    }

    return changedKeys
  },
  []
)
