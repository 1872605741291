import React from 'react'
import {
  uniq, map, range
} from 'lodash'

import { Group } from '@vx/group'
import { AxisLeft, AxisRight, AxisBottom } from '@vx/axis'
import { scaleBand, scaleLinear } from '@vx/scale'
import { Bar, LinePath, Circle } from '@vx/shape'
import { curveLinear } from '@vx/curve'
import { Motion, spring, presets } from 'react-motion'
import memoize from 'lru-memoize'

import {
  tickLabelLeft,
  tickLabelRight,
  tickLabelBottom,
  numTicksFromWidth,
  numTicksFromHeight,
  gray,
  minmax,
  titleStyle,
  axisLabelStyle
} from '../misc'

const getBandTickValues = memoize()((xScale, numXTicks) => {
  const domain = xScale.domain()
  const length = domain.length

  const indexes = uniq(range(0, length, Math.ceil(length / numXTicks)))

  return map(indexes, (index) => domain[index])
})

const Line = ({
  line, xScale, xMax, yMax, numYTicks, showAxis, showPoints
}) => {
  if (!line) return null

  const offset = xScale.bandwidth() / 2
  const x = (d) => xScale(d.x) + offset

  const maxValue = minmax([line], 'y')[1] * 1.2

  const yLineScale = scaleLinear({
    domain: [0, maxValue],
    rangeRound: [yMax, 0]
  })

  return (
    <>
      { showAxis && (
        <>
          <AxisRight
            label={ line.label }
            left={ xMax }
            scale={ yLineScale }
            numTicks={ numYTicks }
            tickLabelProps={ tickLabelRight }
            stroke={ gray }
            hideTicks
            labelProps={ axisLabelStyle }
            labelOffset={ 50 }
          />
          <AxisRight left={ xMax } scale={ yLineScale }>{ (props) => (
            <rect
              width="10"
              height="20"
              fill={ line.color }
              x={ props.axisFromPoint.x + 40 }
              y={ Math.round((props.axisFromPoint.y + props.axisToPoint.y) / 2) - 10 }
            />
          )}</AxisRight>
        </>
      ) }
      <Motion
        defaultStyle={{ scale: 0 }}
        style={{
          scale: spring(1, presets.wobbly)
        }}
      >{ ({ scale }) => { // eslint-disable-line no-shadow
        const yLine = (d) => yLineScale(d.y * scale)

        return (
          <>
            <LinePath
              data={ line.data }
              x={ x }
              y={ yLine }
              curve={ curveLinear }
              stroke={ line.color }
              strokeWidth={ 2 }
            />
            { showPoints && map(line.data, (d) => (
              <Circle
                key={ d.x }
                cx={ x(d) }
                cy={ yLine(d) }
                r={ 3 }
                fill={ line.color }
              />
            )) }
          </>
        )
      } }</Motion>
    </>
  )
}

const Bars = ({
  bar, xScale, yMax, numYTicks, showAxis
}) => {
  if (!bar) return null

  const maxValue = minmax([bar], 'y')[1] * 1.2

  const yBarScale = scaleLinear({
    domain: [0, maxValue],
    rangeRound: [yMax, 0]
  })

  const xBarScale = xScale
  const barWidth = xBarScale.bandwidth()

  return (
    <>
      { showAxis && (
        <>
          <AxisLeft
            labelClassName="yoyo-left"
            label={ bar.label }
            scale={ yBarScale }
            tickLabelProps={ tickLabelLeft }
            numTicks={ numYTicks }
            stroke={ gray }
            hideTicks
            labelProps={ axisLabelStyle }
            labelOffset={ 50 }
          />
          <AxisLeft scale={ yBarScale }>{ (props) => (
            <rect
              width="10"
              height="20"
              fill={ bar.color }
              x={ props.axisFromPoint.x - 50 }
              y={ Math.round((props.axisFromPoint.y + props.axisToPoint.y) / 2) - 10 }
            />
          )}</AxisLeft>
        </>
      ) }

      { map(bar.data, (point) => {
        const barX = xBarScale(point.x)
        const barY = yBarScale(point.y)
        const yZero = yBarScale(0)
        const barHeight = yZero - barY

        return (
          <Motion
            key={ point.x }
            defaultStyle={{ barY: yZero, barHeight: 0 }}
            style={{
              barY: spring(barY, presets.stiff),
              barHeight: spring(barHeight, presets.stiff)
            }}
          >{ ({ barY, barHeight }) => ( // eslint-disable-line no-shadow
            <Bar
              x={ barX }
              y={ barY }
              width={ barWidth }
              height={ barHeight }
              fill={ point.color || bar.color }
            />
          )}
          </Motion>
        )
      }) }
    </>
  )
}

export default class Base extends React.PureComponent {
  static defaultProps = {
    margin: {
      left: 70,
      right: 70,
      top: 10,
      bottom: 50
    },
    showVerticalAxes: true,
    showPoints: true
  }

  svg = React.createRef()

  render() {
    const {
      margin,
      bar,
      line,
      parentWidth: width,
      parentHeight: height,
      children,
      showVerticalAxes,
      showPoints,
      bottomLabel,
      title
    } = this.props

    if (!width || !height) return null

    const xMax = width - margin.left - margin.right
    const yMax = height - margin.top - margin.bottom

    const domain = uniq([...map(bar.data, 'x'), ...map(line.data, 'x')])

    const xScale = scaleBand({
      domain,
      range: [0, xMax],
      padding: 0.2
    })

    const numXTicks = numTicksFromWidth(xMax)
    const numYTicks = numTicksFromHeight(yMax)

    return (
      <svg
        width={ width }
        height={ height }
        ref={ this.svg }
      >
        { title && (
          <text
            { ...titleStyle }
            x={ Math.round(width / 2) }
            y={ 0 }
            className="chart-title"
          >{ title }</text>
        ) }
        <AxisBottom
          top={ height - margin.bottom }
          left={ margin.left }
          scale={ xScale }
          tickLabelProps={ tickLabelBottom }
          tickValues={ getBandTickValues(xScale, numXTicks) }
          stroke={ gray }
          hideTicks
          label={ bottomLabel }
          labelProps={ axisLabelStyle }
          labelOffset={ 20 }
        />

        <Group top={ margin.top } left={ margin.left }>
          <Bars
            xScale={ xScale }
            yMax={ yMax }
            bar={ bar }
            numYTicks={ numYTicks }
            showAxis={ showVerticalAxes }
          />

          <Line
            xScale={ xScale }
            xMax={ xMax }
            yMax={ yMax }
            line={ line }
            numYTicks={ numYTicks }
            showAxis={ showVerticalAxes }
            showPoints={ showPoints }
          />

          { children }
        </Group>
      </svg>
    )
  }
}
