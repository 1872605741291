import React, {useRef, useState} from "react";

const getSizeMB = (file) => ((file?.size || 0) / 1000000.0);

export default ({
    onFileUpload = ()=>{},
    onFileSelectSuccess = ()=>{},
    onFileSelectError = ()=>{},
    accept = [],
    maxSizeMB = 100
})=> {

    const [selectedFile, setSelectedFile] = useState(null);

    const selectFile = async (e) => {
        let uploadInput = document.createElement("input");
        uploadInput.type = "file";
        uploadInput.accept = accept instanceof Array ? accept.join(",") : accept.toString();
        uploadInput.onchange = async (e) => {
            const file = (e?.path[0] || e?.target)?.files[0];
            if(!file) {
                return;
            }

            if(maxSizeMB && getSizeMB(file) > maxSizeMB) {
                onFileSelectError({ error: `The file should not exceed ${maxSizeMB}MB!`});
                e.target.value = null;
                return;
            }

            await onFileSelectSuccess(file);
            setSelectedFile(file);
        };
        uploadInput.click();
    }

    const handleFileUpload = async () => {
        await onFileUpload(selectedFile);
        setSelectedFile(null);
    }

    return selectedFile ?
        <>
            <span style={{ padding: ".25em" }}>{selectedFile?.name} (<small><i>{getSizeMB(selectedFile)?.toFixed(1)} MB</i></small>)</span>
            <button className={"btn btn-danger infraplan_button_red"} onClick={()=>setSelectedFile(null)}>Cancel</button>&nbsp;
            <button className={"btn btn-primary infraplan_button"} onClick={handleFileUpload}>Upload</button>
        </>:
        <>
            <button className={"btn btn-primary infraplan_button"} onClick={selectFile}><i className="fa fa-upload"></i> Click to select a file</button>
        </>;
}