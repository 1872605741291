import React from 'react'
import {
  map, sortBy, transform, last, each, filter
} from 'lodash'
import List from './List'
import Header from './Header'

import Sticky from '../Sticky'
import CohortsNotes from "./Notes";

const { $ } = window

const sortCohorts = (cohorts) => (
  each(
    sortBy(cohorts, ({ cohort }) => {
      if (!cohort) return '1'
      if (cohort.name.toLowerCase() === 'all') return '2'

      return `3-${cohort.name}`
    }),
    (r) => {
      if (r.subcohorts) {
        r.subcohorts = sortCohorts(r.subcohorts) // eslint-disable-line no-param-reassign
      }
    }
  )
)

const replacePrediction = (cohorts, cohortId, predictionId, newPrediction) => (
  cohorts && map(cohorts, (currentCohort) => {
    const result = {
      ...currentCohort,
      subcohorts: replacePrediction(currentCohort.subcohorts, cohortId, predictionId, newPrediction)
    }

    if (!currentCohort.cohort || (currentCohort.cohort.id !== cohortId)) {
      return result
    }

    result.predictions = map(currentCohort.predictions, (currentPrediction) => (
      currentPrediction.id === predictionId ? newPrediction : currentPrediction
    ))

    return result
  })
)

const updateCohort = (cohorts, cohortId, update) => (
  cohorts && map(cohorts, (currentCohort) => {
    const result = {
      ...currentCohort,
      subcohorts: updateCohort(currentCohort.subcohorts, cohortId, update)
    }

    if (!currentCohort.cohort || (currentCohort.cohort.id !== cohortId)) {
      return result
    }

    return {
      ...result,
      cohort: {
        ...result.cohort,
        ...update
      },
    }
  })
)

const updateCohorts = (cohorts, update) => (
  cohorts && map(cohorts, (currentCohort) => ({
    ...currentCohort,
    subcohorts: updateCohorts(currentCohort.subcohorts, update),
    cohort: currentCohort.cohort && {
      ...currentCohort.cohort,
      ...update
    }
  }))
)

// mutates cohorts
const arrangeCohorts = (cohorts) => {
  const cohortPointers = transform(cohorts, (result, r) => {
    if (r.cohort) {
      result[r.cohort.id] = r // eslint-disable-line no-param-reassign
    }
  }, {})

  return sortCohorts(
    filter(cohorts, (r) => {
      if (r.cohort && r.cohort.ancestry) {
        const parentId = last(r.cohort.ancestry.split('/'))
        const parent = cohortPointers[parentId]

        parent.subcohorts = parent.subcohorts || []
        parent.subcohorts.push(r)

        return false
      }

      return true
    })
  )
}

const _getJSONCache = {}
const getJSONWithCache = (url, onLoaded) => {
  if (_getJSONCache[url]) {
    onLoaded(_getJSONCache[url])
  } else {
    $.getJSON(url, (data) => {
      _getJSONCache[url] = data
      onLoaded(data)
    })
  }
}

export default class Cohorts extends React.PureComponent {
  state = {
    loading: true,
    updating: false,
    cohorts: undefined,
    highlightedColumn: false,
    eulBreakpoints: this.props.eul_breakpoints,
    eulBreakpointsVersion: (new Date()).getTime()
  }

  highlightColumn = (column) => this.setState({ highlightedColumn: column })

  componentDidMount() {
    this.loadCohorts()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.eulBreakpoints !== this.state.eulBreakpoints) {
      this.loadCohorts()
    }
  }

  loadCohorts = () => {
    const { urls: { cohorts_url }} = this.props

    this.setState({ loading: true })
    $.getJSON(cohorts_url, this.onCohortsLoaded)
  }

  updateCohorts = (update) => {
    const { urls: { cohorts_url }} = this.props

    this.setState({ updating: true })

    $.post(cohorts_url, {
      _method: 'patch',
      ...update
    }).then(
      () => {
        this.updateStateCohorts(update)
        this.setState({ updating: false })
      },
      () => {
        alert('Error updating') // eslint-disable-line no-alert
        this.setState({ updating: false })
      }
    )
  }

  onCohortsLoaded = (data) => {
    this.setState({
      loading: false,
      cohorts: arrangeCohorts(data.cohorts)
    })
  }

  replacePrediction = (cohortId, predictionId, newPrediction) => this.setState(({ cohorts }) => ({
    cohorts: replacePrediction(cohorts, cohortId, predictionId, newPrediction)
  }))

  updateStateCohort = (cohortId, update) => this.setState(({ cohorts }) => ({
    cohorts: updateCohort(cohorts, cohortId, update)
  }))

  updateStateCohorts = (update) => this.setState(({ cohorts }) => ({
    cohorts: updateCohorts(cohorts, update)
  }))

  onEulBreakpointChange = (index, indexValue) => {
    const newValue = [...this.state.eulBreakpoints]
    newValue[index] = indexValue

    this.setState({ loading: true })

    $.post(this.props.urls.project_settings_url, {
      _method: 'patch',
      project: {
        eul_breakpoints: newValue
      }
    }).then(
      this.onEulBreakpointUpdateSuccess,
      this.onEulBreakpointUpdateFailure
    )
  }

  onEulBreakpointUpdateSuccess = (response) => {
    this.setState({
      eulBreakpoints: response.data.eul_breakpoints,
      eulBreakpointsVersion: (new Date()).getTime()
    })
  }

  onEulBreakpointUpdateFailure = () => {
    window.alert(`Error saving data`) // eslint-disable-line no-alert

    this.setState(({ eulBreakpoints }) => ({
      eulBreakpoints: [...eulBreakpoints],
      eulBreakpointsVersion: (new Date()).getTime()
    }))
  }

  render() {
    const {
      urls, recent_years, relevance_year, showPredictions = true
    } = this.props
    const {
      cohorts, loading, updating, highlightedColumn,
      eulBreakpoints, eulBreakpointsVersion
    } = this.state
    const { download_xlsx_url, download_csv_url } = urls

    const highlightColumnProps = {
      highlightColumn: this.highlightColumn,
      highlightedColumn
    }

    return (
      <div className="cohorts">
        <div className="mb-5">
          <a
            href={ urls.new_cohort_url }
            className="btn btn-primary btn-sm infraplan_button"
          >Create cohort</a>

          <div className="btn-group float-right">
            <a className="btn btn-sm btn-secondary" href={ download_xlsx_url }>Download</a>

            <button type="button" className="btn btn-sm btn-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span className="sr-only">Toggle Dropdown</span>
            </button>

            <div className="dropdown-menu dropdown-menu-right">
              <a className="dropdown-item" href={ download_xlsx_url }>Excel</a>
              <a className="dropdown-item" href={ download_csv_url }>CSV</a>
            </div>
          </div>
        </div>

        { loading && 'Loading…' }

        <Sticky containerId="main">
          <Header
            key={ eulBreakpointsVersion }
            recent_years={ recent_years }
            relevance_year={ relevance_year }
            highlightColumnProps={ highlightColumnProps }
            eulBreakpoints={ eulBreakpoints }
            onEulBreakpointChange={ this.onEulBreakpointChange }
            updateCohorts={ this.updateCohorts }
            showPredictions={ showPredictions }
          />
        </Sticky>

        <div className="cohorts-body">
          { !loading && (
            <List
              cohorts={ cohorts }
              urls={ urls }
              updateCohort={ this.updateStateCohort }
              replacePrediction={ this.replacePrediction }
              highlightColumnProps={ highlightColumnProps }
              getJSONWithCache={ getJSONWithCache }
              updating={ updating }
              showPredictions={ showPredictions }
            />
          )}
        </div>

        <CohortsNotes />
      </div>
    )
  }
}
