import React, {useEffect, useState} from 'react';
import {GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup} from 'firebase/auth'
import {auth} from "../../utils/firebase";

const LoadingYouIn = () => {
    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        message: {
            marginBottom: '20px',
            color: '#333',
            fontWeight: 'bold',
            fontSize: '1.5rem',
            textAlign: 'center',
            animation: 'fadeIn 1.5s ease-in-out',
        },
        spinner: {
            width: '4rem',
            height: '4rem',
            borderWidth: '0.3rem',
        },
        '@keyframes fadeIn': {
            from: { opacity: 0.6 },
            to: { opacity: 1 },
        },
        '@keyframes spin': {
            to: { transform: 'rotate(360deg)' },
        },
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.message}>Just a moment while we sign you in.</h2>
            <div className="spinner-border" style={styles.spinner} role="status" aria-label="Loading">
            </div>
        </div>
    );
};


const Login = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const loginWithToken = async (token) => {
        const loginResponse = await fetch('/login/authenticate_via_firebase', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            credentials: 'same-origin',
            body: JSON.stringify({token}),
        });

        if (loginResponse.ok) {
            toastr.success('You have successfully logged in!');
            Cookies.set('authToken', token);
            window.location.href = '/';
        } else {
            const data = await loginResponse.json();
            console.error(data);
            setErrorMessage(data.message);
            toastr.error('Invalid credentials. Please try again.');
        }
    }

    useEffect(() => {
        document.title = 'Please identify yourself | InfraSOFT';

        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                setIsLoading(true);
                try {
                    const token = await user.getIdToken(true);
                    await loginWithToken(token);
                } catch (error) {
                    console.error('Error during authentication:', error);
                } finally {
                    setIsLoading(false);
                }
            }
        });

        return () => unsubscribe();
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setErrorMessage('');

        signInWithEmailAndPassword(auth, formData.email, formData.password)
        .catch(async (error) => {
            setErrorMessage(error.message);
            console.error(error);
            toastr.error('An error occurred trying to login with firebase. Will try legacy login.');
        }).finally(() => {
            setIsSubmitting(false);
        })
    };

    const handleGoogleLogin = () => {
        signInWithPopup(auth, new GoogleAuthProvider()).catch((error) => {
            setErrorMessage(error.message);
            console.error(error);
            toastr.error('An error occurred. Please try again.');
        }).finally(() => {
            setIsSubmitting(false);
        })
    };

    const centerLoginStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '60px'
    };

    const cardStyle = {
        padding: '2rem',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        width: '400px',
        backgroundColor: '#fff',
    };

    if (isLoading) {
        return (
            <div style={centerLoginStyle}>
                <div style={cardStyle}>
                    <LoadingYouIn />
                </div>
            </div>
        )
    }

    return (
        <div style={centerLoginStyle}>
            <div style={cardStyle}>
                <h1 className="text-center mb-4">Please identify yourself</h1>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Email</label>
                        <input
                            type="email"
                            name="email"
                            className="form-control"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form-group position-relative">
                        <label>Password</label>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            className="form-control"
                            value={formData.password}
                            onChange={handleChange}
                            required
                            style={{ paddingRight: '2.5rem', backgroundColor: '#fff9c4' }}  // Background color from the image and space for the icon
                        />
                        <button
                            type="button"
                            className="btn btn-link position-absolute"
                            onClick={togglePasswordVisibility}
                            style={{
                                top: '50%',
                                right: '10px',
                                padding: '0',
                                fontSize: '1.25rem',
                                color: '#6c757d',
                            }}
                        >
                            {showPassword ? (
                                <i className="fa-solid fa-eye-slash"></i> // Font Awesome "eye-slash" icon for hiding
                            ) : (
                                <i className="fa-solid fa-eye"></i> // Font Awesome "eye" icon for showing
                            )}
                        </button>
                    </div>

                    {errorMessage && (
                        <div className="alert alert-danger mt-3" role="alert">
                            {errorMessage}
                        </div>
                    )}

                    <button
                        type="submit"
                        className="btn btn-primary w-100 mt-3"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Logging in...' : 'Log in'}
                    </button>

                    <div className="text-center mt-4">
                        <p>or</p>
                        <button
                            title="In development"
                            type="button"
                            className="btn btn-primary w-100"
                            onClick={handleGoogleLogin}
                        >
                            <i className="fab fa-google"></i> Login with Google
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;