import React from 'react'
import {
  round, memoize, map, uniq,
  without
} from 'lodash'
import { formatPercent } from '../../utils/number'

const getCovariatesList = memoize((model) => (
  without(uniq(map(model, (row) => (
    row['Z[i]'].replace(/\[(.+)?$/, '')
  ))), 'Alpha', 'Delta', 'Zeta0', 'Zeta1', 'Beta0', 'LNL')
))

class CVTTable extends React.PureComponent {
  getTitle(row) { // eslint-disable-line class-methods-use-this
    const id = row['Z[i]']

    if (id === 'Alpha') { return 'Previous breaks' }
    if (id === 'Delta') { return 'Aging' }
    if (id === 'LNL') { return 'Length' }

    return id
  }

  getMessage(row) { // eslint-disable-line class-methods-use-this
    const id = row['Z[i]']
    const theta = parseFloat(row.theta)

    if (id === 'Alpha') {
      if (theta < 0.001) { return 'Influence of previous breaks on the degradation of the pipes could not be demonstrated' }
      if (theta < 2) { return 'Influence of previous breaks on the degradation of the pipes is very low' }
      if (theta < 4) { return 'Influence of previous breaks on the degradation of the pipes is moderate' }
      return 'Influence of previous breaks on the degradation of the pipes is very high'
    }

    if (id === 'Delta') {
      if (theta < 1.001) { return 'Influence of aging on the degradation of the pipes could not be demonstrated' }
      if (theta < 2) { return 'Influence of aging on the degradation of the pipes is very low' }
      if (theta < 4) { return 'Influence of aging on the degradation of the pipes is moderate' }
      return 'Influence of aging on the degradation of the pipes is very high'
    }

    if (id === 'LNL') {
      const likelihoodOfFailure = round(((1.1 ** theta) - 1) * 100, 2)

      return `If length increases by 10%, the likelihood of failure increases by ${likelihoodOfFailure}%`
    }

    if (id === 'DDP' || id === 'DIAM') {
      const temp = round(Math.exp(theta), 6)

      if (temp > 1) {
        return `If ${id} increases by 1 unit the likelihood of failure increases by ${formatPercent(temp - 1, 4)}`
      }
      if (temp < 1) {
        return `If ${id} increases by 1 unit the likelihood of failure decreases by ${formatPercent(1 - temp, 4)}`
      }
      return `The influence of ${id} could not be demonstrated`
    }

    const bn = Math.exp(parseFloat(row.theta))

    if (Math.abs(1 - bn) < 0.001) {
      return 'Is the reference value'
    }

    return `${round(bn, 2)} times more likely to break than reference value`
  }

  renderRow = (row) => {
    const id = row['Z[i]']

    if (id === 'Zeta0' || id === 'Zeta1' || id === 'Beta0') {
      return null
    }

    return (
      <tr key={ id }>
        <td>{ this.getTitle(row) }</td>
        <td>{ this.getMessage(row) }.</td>
      </tr>
    )
  }

  render() {
    const { model } = this.props

    if (!model) return null

    return (
      <table>
        <tbody>
          { map(model, this.renderRow) }
        </tbody>
      </table>
    )
  }
}

export default class CovariatesTable extends React.Component {
  render() {
    const { validation_model, model } = this.props
    const covariate_list = getCovariatesList(model)

    const calibrationDates = (
      (validation_model
        && validation_model.calibration_period
        && validation_model.validation_period) ? [
          validation_model.calibration_period[0], validation_model.validation_period[1]
        ].join('–') : null
    )

    return (
      <div>
        <p>
          The co-variates that are significant in the prediction model
          { calibrationDates ? ` (calibrated on ${calibrationDates}) ` : ' ' }
          are <strong>L, { covariate_list.join(', ') }</strong>.
          The results below show the increased likelihood of failure for each value
          of a significant co-variate as well as the influence of previous breaks and aging.
        </p>

        <CVTTable model={ model } />
      </div>
    )
  }
}
