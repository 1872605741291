import React from 'react'
import DropdownButton from "./DropdownButton";
import {faChartPie, faHandSparkles, faHatWizard, faListCheck} from "@fortawesome/free-solid-svg-icons";
import ListItem from "./ListItem";
import {basicBtn} from "./StyleConstants";
import Menus from "./Menus";

function checkUrl(pattern) {
    const currentUrl = window.location.pathname;
    const urlParts = currentUrl.split('/');
    const patternParts = pattern.split('/');
    if (urlParts.length !== patternParts.length && patternParts[patternParts.length - 1] !== '*') {
        return false;
    }
    for (let i = 0; i < urlParts.length; i++) {
        const urlPart = urlParts[i];
        const patternPart = patternParts[i];
        if (patternPart[0] === ':') {
            continue;
        }
        if(patternPart === "*") {
            return true;
        }
        if (urlPart !== patternPart) {
            return false;
        }
    }
    return true;
}

function applyUrlParams(pattern, urlParams) {
    const patternParts = pattern.split('/');
    const urlParts = [];
    for (let i = 0; i < patternParts.length; i++) {
        const patternPart = patternParts[i];
        if (patternPart[0] === ':') {
            const paramName = patternPart.substring(1);
            const paramValue = urlParams[paramName];
            urlParts.push(paramValue);
        }
        else {
            urlParts.push(patternPart);
        }
    }
    return urlParts.join('/');
}

function getMenuItems() {
    let items = Menus();
    items_loop:
    for (let item of items) {
        for(let option of item.options) {
            let urls = option.url instanceof Array ? option.url : [option.url];
            if(urls.find(u=>checkUrl(u))) {
                item.isActive = true;
                break items_loop;
            }
        }
    }
    return items;
}

export default ({projectId}) => {

    const items = getMenuItems();

    function getUrl(url, params) {
        if(url instanceof Array) {
            url = url[0];
        }
        let finalUrl = applyUrlParams(url, params);
        if(finalUrl.endsWith("/*")) {
            return finalUrl.slice(0, -2);
        }
        return finalUrl;
    }

    return (
        <ul
            style={{
                display: "flex",
                justifyContent: "space-between",
                gap: '0.5rem'
            }}
        >
            {items.map((menu, index) => {
                return <DropdownButton
                    text={menu.title}
                    isActive={menu.isActive}
                    icon={menu.icon}
                    key={index}>
                    {menu.options ?
                        <div style={{
                            position: "absolute",
                            background: "#fff",
                            padding: '0.2rem',
                            borderTopRightRadius: '5px',
                            borderBottomLeftRadius: '5px',
                            borderBottomRightRadius: '5px',
                            boxShadow: "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
                            zIndex: 99,
                        }} key={index}>
                            {
                                menu.options.map((option, index) => (
                                    <ListItem title={option.title}
                                              url={getUrl(option.url, {projectId})}
                                              key={index}
                                              index={index}/>
                                ))
                            }
                        </div>
                        : <>
                            <a key={index} style={basicBtn} href={items.url}>{items.title}</a>
                        </>
                    }
                </DropdownButton>
            })}
        </ul>
    )
}