import React from 'react'
import c from 'classnames'
import { mapValues } from 'lodash'
import memoize from 'lru-memoize'

import List from '../List'
import Numbers from '../Numbers'
import Predictions from '../Predictions'
import Actions from './Actions'

const { $ } = window

const insertCohortId = memoize(50)((urls, cohortId) => mapValues(urls, (url) => url.replace(/COHORT_ID/, cohortId)))

class IncludeInPlanCheckbox extends React.Component {
  state = {
    saving: false,
    value: this.props.value
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value })
    }
  }

  onChange = (e) => {
    const { cohortId } = this.props
    const { currentTarget: { checked: value }} = e

    this.setState({ value, saving: true })

    $.ajax({
      url: `cohorts/${cohortId}.json`,
      method: 'post',
      dataType: 'text',
      data: {
        _method: 'PATCH',
        cohort: { include_in_plan: value }
      }
    }).then(
      this.onSubmitSuccess,
      this.onSubmitError
    )
  }

  onSubmitSuccess = () => {
    this.setState({ saving: false })

    const { cohortId, updateCohort } = this.props

    updateCohort(cohortId, { include_in_plan: this.state.value })
  }

  onSubmitError = () => {
    this.setState({ value: this.props.value, saving: false })
  }

  render() {
    const { disabled } = this.props
    const { value, saving } = this.state

    return (
      <div
        className={ c(
          'cohorts-cohort-cohort-include_in_plan',
          saving && 'cohorts-cohort-cohort-include_in_plan--saving'
        ) }
      >
        <input
          type="checkbox"
          checked={ value }
          onChange={ this.onChange }
          disabled={ disabled || saving }
        />
      </div>
    )
  }
}

export default class Cohort extends React.PureComponent {
  state = {
    loading: true,
    stats: undefined,
    expanded: false,
    showSubcohorts: false,
    urls: insertCohortId(this.props.urls, this.props.cohort && this.props.cohort.id)
  }

  toggleSubcohorts = () => this.setState(
    ({ showSubcohorts }) => ({ showSubcohorts: !showSubcohorts })
  )

  componentDidMount() {
    const { getJSONWithCache, cohort } = this.props
    const { urls: { cohort_stats_url, total_stats_url }} = this.state

    const url = cohort ? cohort_stats_url : total_stats_url

    getJSONWithCache(url, this.onStatsLoaded)
  }

  componentWillUnmount() {
    this._unmounted = true
  }

  onStatsLoaded = (data) => {
    if (this._unmounted) return

    this.setState({
      loading: false,
      stats: data
    })
  }

  replacePrediction = (predictionId, newPrediction) => (
    this.props.replacePrediction(this.props.cohort.id, predictionId, newPrediction)
  )

  render() {
    const {
      cohort, predictions, highlightColumnProps, subcohorts,
      replacePrediction, updateCohort, urls: rawUrls, getJSONWithCache, updating,
      showPredictions
    } = this.props
    const {
      urls, stats, expanded, loading, showSubcohorts
    } = this.state
    const level = (cohort && cohort.ancestry) ? cohort.ancestry.split('/').length : 0

    return (
      <div>
        <div className={ `cohorts-cohort cohorts-cohort-level-${level}` }>
          <div className="cohorts-cohort-cohort">
            <div className="cohorts-cohort-name">
              <div className="cohorts-cohort-expander">
                { subcohorts && (
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={ this.toggleSubcohorts }
                  ><span className={ c('reveal', showSubcohorts ? null : 'show') } /></button>
                ) }
              </div>
              { cohort ? (
                <Actions
                  cohort={ cohort }
                  urls={ urls }
                />
              ) : (
                'Total'
              ) }
              { cohort && (
                <IncludeInPlanCheckbox
                  cohortId={ cohort.id }
                  value={ cohort.include_in_plan }
                  updateCohort={ updateCohort }
                  disabled={ updating }
                />
              ) }
            </div>

            { cohort && <div dangerouslySetInnerHTML={{ __html: cohort.description }} /> }
          </div>

          <Numbers
            numbers={ stats }
            loading={ loading }
            highlightColumnProps={ highlightColumnProps }
          />

          {
            showPredictions &&
              <Predictions
                  cohort={cohort}
                  predictions={predictions}
                  expanded={expanded}
                  urls={urls}
                  highlightColumnProps={highlightColumnProps}
                  replacePrediction={this.replacePrediction}
                  getJSONWithCache={getJSONWithCache}
              />
          }
        </div>
        { showSubcohorts && (
          <List
            cohorts={ subcohorts }
            urls={ rawUrls }
            highlightColumnProps={ highlightColumnProps }
            replacePrediction={ replacePrediction }
            getJSONWithCache={ getJSONWithCache }
            updateCohort={ updateCohort }
          />
        )}
      </div>
    )
  }
}
