import React, {useEffect, useState} from 'react';

const Filters = ({projectId}) => {
    const [filterList, setFilterList] = useState(null);

    useEffect(() => {
        Api.get(`projects/${projectId}/filters`)
            .then(response => setFilterList(response));
    }, [])

    const handleDelete = (filterId) => {
        const confirmed = window.confirm('Are you sure you want to delete this filter?');
        if (confirmed) {
              Api.delete(`projects/${projectId}/filters/${filterId}`)
                  .then(() => {
                      toastr.success('Filter deleted successfully');
                      window.location.reload();
                  })
        }
    };

    if(!filterList) {
        return (
            <div>
                <a href={`/projects/${projectId}/filters/new?target=pipes`}>
                    <i className="add icon"></i>
                    New filter
                </a>
            </div>
        );
    }

    return (
        <div>
            <p>Pipes Filters</p>
        <table className="filters alternate">
            <thead>
            <tr>
                <th>Name</th>
                <th>Matches</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {filterList && filterList.pipes.map(filter => (
                <tr className="item" key={filter.id}>
                    <td>
                        <a href={`/projects/${projectId}/filters/${filter.id}/edit`}>{filter.name}</a>
                    </td>
                    <td>{filter.count || 0} </td>
                    <td className="actions">
                        <button
                            className="delete"
                            title="Delete filter"
                            onClick={() => handleDelete(filter.id)}
                        >
                            Delete
                        </button>
                    </td>
                </tr>
            ))}
            <tr>
                <td colSpan="2">
                    <a href={`/projects/${projectId}/filters/new?target=pipes`}>
                        <i className="add icon"></i>
                        New filter
                    </a>
                </td>
            </tr>
            </tbody>
        </table>

            <p>Breaks Filters</p>
            <table className="filters alternate">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Matches</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {filterList && filterList.breaks.map(filter => (
                    <tr className="item" key={filter.id}>
                        <td>
                            <a href={`/projects/${projectId}/filters/${filter.id}/edit`}>{filter.name}</a>
                        </td>
                        <td>{filter.count || 0} </td>
                        <td className="actions">
                            <button
                                className="delete"
                                title="Delete filter"
                                onClick={() => handleDelete(filter.id)}
                            >
                                Delete
                            </button>
                        </td>
                    </tr>
                ))}
                <tr>
                    <td colSpan="2">
                        <a href={`/projects/${projectId}/filters/new?target=break`}>
                            <i className="add icon"></i>
                            New filter
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    );
};

export default Filters;
