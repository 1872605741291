import React, { useCallback } from 'react'
import c from 'classnames'
import {
  map, round, includes, without, uniq, get, find
} from 'lodash'

const renderValue = (value) => round(value, 2)

const Label = ({
  id, label, checked, onChange: _onChange, color, value, dasharray
}) => {
  const onChange = (e) => {
    const newChecked = e.currentTarget.checked

    _onChange(id, newChecked)
  }

  const symbol = dasharray ? (
    <svg viewBox="0 0 48 2" xmlns="http://www.w3.org/2000/svg">
      <line x1="0" y1="1" x2="48" y2="1" stroke="black" strokeWidth="2" strokeDasharray={ dasharray } />
    </svg>
  ) : (
    <span
      className="chart-tooltipContent-dot"
      style={label==="New Pipes"?{
            background: `repeating-linear-gradient(
                            -45deg,
                            white,
                            white 1px,
                            darkblue 1px,
                            darkblue 2px
                            )`
      }:{
        backgroundColor: color || '#000'
      }}
    />
  )

  return (
    <div key={ id } className="plan-legend-row">
      <label>
        <input
          type="checkbox"
          value={ id }
          checked={ checked }
          onChange={ onChange }
        />&nbsp;
        <div className="plan-legend-symbol">{ symbol }</div>
        <strong className="plan-legend-title">{ label }</strong>
      </label>
      { !!value && <span className="plan-legend-value">: {renderValue(value)}</span> }
    </div>
  )
}

export default ({
  series, selected, setSelected, currentData, colors, showToggleAll
}) => {
  const updateSelected = (id, checked) => {
    let newSelected

    if (checked) {
      newSelected = uniq([...selected, id])
    } else {
      newSelected = without(selected, id)
    }

    setSelected(newSelected)
  }

  const selectAll = useCallback(() => {
    setSelected(series.map(({ id }) => id))
  }, [selected, series])

  const clearAll = useCallback(() => {
    setSelected([])
  }, [selected])

  return (
    <div className={ c('plan-legend', showToggleAll && 'plan-legend--withToggleAll', currentData && 'plan-legend--withCurrentData') }>
      <div className="plan-legend-year">
        { currentData && `Year ${currentData.x}` }
      </div>
      { showToggleAll && (
        <div className="plan-legend-allToggle">
          <button
            type="button"
            className="btn btn-link"
            onClick={ selectAll }
          >Select all</button> • <button
            type="button"
            className="btn btn-link"
            onClick={ clearAll }
          >Clear all</button>
        </div>
      )}
      { map(series, ({ id, label, dasharray }) => (
        <Label
          key={ id }
          id={ id }
          label={ label }
          dasharray={ dasharray }
          checked={ includes(selected, id) }
          color={ colors[id] }
          value={ currentData && get(find(currentData.y, (point) => point.label === id), ['y']) }
          onChange={ updateSelected }
        />
      ))}
    </div>
  )
}
