import React from 'react'
import {
  map, compact, includes, cloneDeep
} from 'lodash'
import FullscreenTable from './FullscreenTable'
import { stringify as stringifyQuery } from '../utils/querystring'

const { helpers } = window

export default class PipesTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      breaksQuery: '',
      columns: [],
      MaterialOptions: [],
      LifeStatusOptions: [],
      LocationOptions: []
    };
  }

  async componentDidMount() {
    await this.fetchOptions();
    this.initializeColumns();
  }

  fetchOptions = async () => {
    try {
      const [materialsData, lifeStatusesData, locationsData] = await Promise.all([
        Api.get(`projects/${Api.getProjectId()}/materials`),
        Api.get(`projects/${Api.getProjectId()}/life_statuses`),
        Api.get(`projects/${Api.getProjectId()}/locations`)
      ]);

      const MaterialOptions = materialsData.map((material) => material.materialRef);
      const LifeStatusOptions = lifeStatusesData.map((lifeStatus) => lifeStatus.lifeStatus);
      const LocationOptions = locationsData.map((location) => location.locationRef);

      this.setState({ MaterialOptions, LifeStatusOptions, LocationOptions });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  initializeColumns = () => {
    const { customFields, allowedOrdering } = this.props;
    const { MaterialOptions, LifeStatusOptions, LocationOptions } = this.state;

    let columns = compact([
      {
        data: 'pipe.pipe_ref',
        title: 'ID',
        render(data, type, row) {
          if (type === 'display') {
            return `<a target="_blank" href="pipes/${row.pipe.id}">${data}</a>`
          }

          return data
        },
        defaultContent: ''
      },
      helpers.dt.data({
        data: 'pipe.updated_at',
        title: 'Last Update',
        small: true,
        type: 'date',
        editable: false,
        orderSequence: ['desc', 'asc'],
        defaultContent: ''
      }),
      {
        data: 'pipe.latest_log_entry_creator_name',
        title: 'Updated By',
        render(data, type) {
          if (type === 'display') {
            const initials = (data || '')
                .split(',')
                .map((x) => { return (x || '').trim()[0] || '' })
                .filter((x) => { return x })
                .join('')
            return `<span title='${data}'>${initials}</span>`
          }

          return data
        },
        defaultContent: ''
      },
      {
        data: 'pipe.editor_status',
        title: 'Status',
        render(data) {
          if (typeof data === 'undefined' || data === null || data === '') {
            return 'No changes'
          }
          return data
        },
        defaultContent: ''
      },
      helpers.dt.data({
        data: 'pipe.issues',
        title: '# Issues',
        type: 'list-length',
        editable: false,
        defaultContent: '',
        showInitial: true
      }),

      helpers.dt.data({
        data: 'pipe.issues',
        title: 'Issues',
        type: 'list',
        small: true,
        orderable: false,
        defaultContent: '',
        editable: false,
        showInitial: true,
        className: 'datatable-longline'
      }),
        helpers.dt.data({
          data: 'pipe.filters',
            title: '# Filters',
            type: 'list-length',
          small: true,
            defaultContent: '',
            editable: false,
            showInitial: true
        }),
        helpers.dt.data({
          data: 'pipe.filters',
            title: 'Filters',
            type: 'list',
            small: true,
            orderable: false,
            defaultContent: '',
            editable: false,
            showInitial: true
        }),
        helpers.dt.data({
          data: 'pipe.breaks',
          title: '# Breaks',
            type: 'list-length',
            small: true,
            defaultContent: '',
            editable: false,
            showInitial: false
        }), {
        data: 'pipe.breaks',
        title: 'Breaks',
        render(data, type, row) {
          if (type === 'display') {
            // breakData[0] is the break_ref, breakData[1] is the id
            return data.map((breakData) =>
                `<a target="_blank" href="breaks/${breakData[1]}/edit">${breakData[0]}</a>`
            )
          }

          return data
        },
        small: true,
        defaultContent: '',
        editable: false,
        showInitial: false,
        className: 'datatable-longline'
      },
      helpers.dt.data({
        data: 'pipe.installed_on',
        title: 'Date of<br>Installation',
        type: 'date',
        format: 'YYYY/MM/DD',
        editable: true,
        defaultContent: '',
        showInitial: true,
      }),
      helpers.dt.data({
        data: 'pipe.diameter',
        title: 'Diameter',
        type: 'two-decimals',
        editable: true,
        defaultContent: '',
        showInitial: true
      }),
      helpers.dt.data({
        data: 'pipe.material_ref',
        title: 'Material',
        options: MaterialOptions,
        type: 'select',
        defaultContent: '',
        showInitial: true,
        editable: true
      }),
      helpers.dt.data({
        data: 'pipe.length',
        title: 'Length',
        type: 'two-decimals',
        editable: true,
        defaultContent: '',
        showInitial: true,
      }),
      helpers.dt.data({
        data: 'pipe.location_ref',
        title: 'Location',
        options: LocationOptions,
        type: 'select',
        defaultContent: '',
        editable: true,
        showInitial: true
      }),
      helpers.dt.data({
        data: 'pipe.life_status',
        title: 'Life<br>Status',
        options: LifeStatusOptions,
        editable: true,
        type: 'select',
        defaultContent: '',
        showInitial: true
      }),
      helpers.dt.data({
        data: 'pipe.yoaq',
        title: 'Year of<br>Acquisition',
        type: 'date',
        format: 'YYYY/MM/DD',
        defaultContent: '',
        editable: true,
        showInitial: true,
      }),
      helpers.dt.data({
        data: 'pipe.abandoned_on',
        title: 'Date of<br>Abandonment',
        type: 'date',
        format: 'YYYY/MM/DD',
        editable: true,
        defaultContent: '',
        showInitial: true
      }),
      helpers.dt.data({
        data: 'pipe.cof',
        title: 'COF',
        editable: true,
        defaultContent: '',
        showInitial: true
      }),
      helpers.dt.data({
        data: 'pipe.cost',
        title: 'COST',
        editable: true,
        defaultContent: '',
        showInitial: true
      }),
      helpers.dt.data({
        data: 'pipe.custom_1',
        defaultContent: '',
        showInitial: true
      }),
      helpers.dt.data({
        data: 'pipe.custom_2',
        defaultContent: '',
        showInitial: true
      }),
      helpers.dt.data({
        data: 'pipe.custom_3',
        defaultContent: '',
        showInitial: true
      }),

      helpers.dt.data({
        data: 'pipe.custom_4',
        defaultContent: '',
        showInitial: true
      }),
      helpers.dt.data({
        data: 'pipe.custom_5',
        defaultContent: '',
        showInitial: true
      }),
      helpers.dt.data({
        data: 'pipe.custom_6',
        defaultContent: '',
        showInitial: true
      }),
      helpers.dt.data({
        data: 'pipe.custom_7',
        defaultContent: '',
        showInitial: true
      }),
      helpers.dt.data({
        data: 'pipe.custom_8',
        defaultContent: '',
        showInitial: true
      }),
      helpers.dt.data({
        data: 'pipe.comment',
        title: 'Comment',
        editable: true,
        defaultContent: ''
      })
    ])

    // Add / remove custom fields
    columns = map(columns, (column) => {
      if ((/^pipe.custom_/).test(column.data)) {
        const key = column.data.split('.')[1];

        if (customFields[key]) {
          return { ...column, title: customFields[key] };
        }

        return null;
      }

      return column;
    });

    // Remove empty
    columns = compact(columns);

    // Add indices and other properties
    columns = map(columns, (column, index) => ({
      ...column,
      name: column.name || column.data,
      index,
      showInitial: true,
      orderable: (
          column.orderable !== undefined ? column.orderable : includes(allowedOrdering, column.data)
      ),
    }));

    this.setState({ columns });
  };

  onQueryChanged = (query) => {
    const queryWithStringifiedPredicate = {
      ...query,
      predicates: query.predicates ? JSON.stringify(query.predicates) : undefined,
    };
    window.localStorage.setObject('PipeListScope', queryWithStringifiedPredicate);

    const breaksQuery = cloneDeep(query);
    delete breaksQuery.sort;
    if (/^(Filter|Issue)_/.test(breaksQuery.scope)) {
      delete breaksQuery.scope;
    }

    this.setState({ breaksQuery: stringifyQuery(breaksQuery) });
  };

  render() {
    const { columns } = this.state;
    const title = <h2>Pipes</h2>;

    if (!columns || columns.length === 0) {
      return <div>Loading...</div>; // Add a loading state to handle cases when columns are not yet set
    }

    return (
        <FullscreenTable
            {...this.props}
            title={title}
            columns={columns}
            mapUrl="./pipes/map"
            csvUrl="./pipes.csv"
            xlsxUrl="./pipes.xlsx"
            dataUrl="./pipes.json"
            onQueryChanged={this.onQueryChanged}
            defaultOrder={['pipe.updated_at', 'desc']}
        />
    );
  }
}