import React, {useEffect, useRef, useState} from "react";

export default ({style, min = Number.MIN_VALUE, max = Number.MAX_VALUE, step = 1, initial = 0, onValue = ()=>{}}) => {

    const ref = useRef(null);

    const updateCurrentValue = (newValue) => {
        if(newValue.trim() === "" ||  isNaN(newValue)) {
            onValue(null);
        } else {
            newValue = Number(newValue);
            if(typeof max === "number" && newValue > max) {
                newValue = max;
            }
            if(typeof min === "number" && newValue < min) {
                newValue = min;
            }
            onValue(newValue);
            ref.current.value = newValue;
        }
    }

    useEffect(()=>{
        updateCurrentValue(initial?.toString()||"");
    }, []);

    useEffect(()=>{
        updateCurrentValue(ref.current.value);
    }, [min, max]);

    return <input
        style={style}
        ref={ref}
        min={min}
        max={max}
        step={step}
        type={"number"}
        onWheel={(e) => e.target.blur()}
        onInput={(e)=>{
            const newValue = e.target.value;
            clearTimeout(ref.current.__timeout);
            ref.current.__timeout = setTimeout(()=> {
                updateCurrentValue(newValue);
                delete ref.current.__timeout;
            }, 500);
        }} />;
}