import React from 'react'
import c from 'classnames'

const getOffsetTop = (node, container) => {
  let current = node
  let offset = node.offsetTop

  do {
    current = current.offsetParent

    if (current === container || current === document.body) current = null

    if (current && typeof current.offsetTop === 'number') {
      offset += current.offsetTop
    }
  } while (current)

  return offset
}

window.getOffsetTop = getOffsetTop

export default class Sticky extends React.PureComponent {
  static defaultProps = {
    enabled: true
  }

  state = {
    sticky: false,
    height: 0,
    width: undefined,
    top: undefined
  }

  getContainer() {
    const { containerId } = this.props

    if (containerId) {
      const container = document.getElementById(containerId)

      if (!container) {
        throw new Error(`Can't find container with #${containerId}`)
      }

      return container
    }

    return window
  }

  enable() {
    const container = this.getContainer()

    container.addEventListener('scroll', this.updateSticky)
    container.addEventListener('resize', this.updateSticky)

    this.updateSticky()
  }

  disable() {
    const container = this.getContainer()

    container.removeEventListener('scroll', this.updateSticky)
    container.removeEventListener('resize', this.updateSticky)

    this.setState({ sticky: false })
  }

  UNSAFE_componentDidUpdate(nextProps) {
    if (!this.props.enabled && nextProps.enabled) this.enable()
    else if (this.props.enabled && !nextProps.enabled) this.disable()
  }

  componentDidMount() { if (this.props.enabled) this.enable() }
  componentWillUnmount() { this.disable() }

  updateSticky = () => {
    const container = this.getContainer()

    if (!this.node || !this.inner) { return }

    const scrollTop = container === window ? window.pageYOffset : container.scrollTop
    const nodeHeight = this.inner.offsetHeight
    const nodeTop = getOffsetTop(this.node, container)

    window.node = this.node
    window.container = container

    const sticky = nodeTop <= scrollTop

    this.setState(({ width }) => ({
      sticky,
      height: nodeHeight,
      width: sticky && this.node ? this.node.offsetWidth : width,
      top: sticky ? (container === 'window' ? 0 : getOffsetTop(container)) : undefined
    }))
  }

  render() {
    const {
      enabled, children, className, stickyClassName
    } = this.props
    const {
      height, sticky, width, top
    } = this.state

    return (
      <div
        ref={ (ref) => { this.node = ref } }
        className="sticky-container"
        style={{ height: enabled ? height : null }}
      >
        <div
          className={ c(
            'sticky-inner',
            className,
            sticky && 'sticky-sticky',
            sticky && stickyClassName
          ) }
          style={{ width, top }}
          ref={ (ref) => { this.inner = ref } }
        >{ children }</div>
      </div>
    )
  }
}
