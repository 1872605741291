import React from 'react'
import { map } from 'lodash'

const SubmitInputs = ({ is_steady, regrouped_variables }) => (
  <>
    <input type="hidden" name="complete[dummy]" value="true" />
    { is_steady && <input type="hidden" name="complete[include_base]" value="true" /> }
    <input type="hidden" name="complete[groupings]" value={ JSON.stringify(regrouped_variables) } />
  </>
)

export default class Base extends React.Component {
  render() {
    const {
      regrouped_variables,
      base_variable: { name },
      is_steady,
      createRegrouping,
      openRegrouping,
      form: Form,
      submitting
    } = this.props

    return (
      <>
        <div className="calibration-info">
          { is_steady && (
            <>
              <p>Is is recommended that { name } be introduced as a continuous variable.</p>
              { regrouped_variables.length === 0 && (
                <p>
                  Do you also want to test a discretized form of { name } including test whether
                  a discretized form with only 2 modalities could be used to split the cohort?
                </p>
              )}
            </>
          ) }
          { !is_steady && (
            <p>
              It is rec that { name } be introduced in model in a
              discretized form or be used to split the cohort.
            </p>
          ) }
          { regrouped_variables.length > 0 && (
            <>
              <p>Following discretizations will be introduced to the model:</p>

              <ul>{
                map(regrouped_variables, ({ groups }, index) => (
                  <li key={ index }>
                    { name }{ groups.length }: { map(groups, (range) => range.join('-')).join(', ') }
                    <button
                      type="button"
                      className="btn btn-sm btn-secondary ml-2"
                      onClick={ () => openRegrouping(index) }
                    >change</button>
                  </li>
                ))
              }</ul>

              <p>Do you want to try another discretization?</p>
            </>
          ) }
        </div>
        <div className="calibration-submit">
          { regrouped_variables.length !== 0 && (
            <div className="row calibration-variables-variable-buttonsRow">
              <div className="col-6">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={ createRegrouping }
                >Yes, create another</button>
              </div>
              <div className="col-6">
                <Form>
                  <SubmitInputs
                    is_steady={ is_steady }
                    regrouped_variables={ regrouped_variables }
                  />

                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={ submitting }
                  >No, continue</button>
                </Form>
              </div>
            </div>
          )}
          { regrouped_variables.length === 0 && (
            <>
              { is_steady && (
                <div className="row calibration-variables-variable-buttonsRow">
                  <div className="col-6">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={ createRegrouping }
                    >Yes</button>
                  </div>
                  <div className="col-6">
                    <Form>
                      <SubmitInputs
                        is_steady={ is_steady }
                        regrouped_variables={ regrouped_variables }
                      />

                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={ submitting }
                      >No, continue</button>
                    </Form>

                  </div>
                </div>
              ) }
              { !is_steady && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={ createRegrouping }
                  disabled={ submitting }
                >Discretize</button>
              ) }
            </>
          )}
        </div>
      </>
    )
  }
}
