import React from 'react'
import { first, map, sortBy } from 'lodash'
import memoize from 'lru-memoize'

import Prediction from './Prediction'

const { $ } = window

const sortPredictions = memoize(20)((predictions) => (
  sortBy(
    sortBy(
      predictions,
      ({ created_at }) => -created_at
    ),
    ({ state }) => (state === 'processed' ? 1 : state === 'initial' ? 2 : 3)
  )
))

export default class Predictions extends React.PureComponent {
  state = {
    showAll: true
  }

  open = () => this.setState({ showAll: true })

  render() {
    const {
      cohort, urls, predictions, highlightColumnProps,
      replacePrediction, getJSONWithCache
    } = this.props
    const { showAll } = this.state
    const canShowAll = predictions.length > 1
    const sortedPredictions = sortPredictions(predictions)

    const shownPredictions = showAll ? sortedPredictions : [first(sortedPredictions)]

    return (
      <div className="cohorts-cohort-predictions">
        { (shownPredictions.length === 0) && (
          <Prediction
            key="dummy"
            urls={ urls }
            highlightColumnProps={ highlightColumnProps }
          />
        ) }
        { map(shownPredictions, (prediction) => (
          <Prediction
            key={ prediction.id }
            prediction={ prediction }
            cohort={ cohort }
            urls={ urls }
            highlightColumnProps={ highlightColumnProps }
            replacePrediction={ replacePrediction }
            getJSONWithCache={ getJSONWithCache }
          />
        )) }

        { canShowAll && !showAll && (
          <button
            type="button"
            className="btn btn-light btn-xs"
            onClick={ this.open }
          >Show All</button>
        ) }
      </div>
    )
  }
}
