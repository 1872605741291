import React from 'react'
import Section from "./Section";
import {downloadFileFromData} from "../../utils/downloader";
import DistributionBuilder from "./DistributionBuilder";


export default ()=> {

    const [cost, setCost] = React.useState([]);
    const [cof, setCof] = React.useState([]);
    const [keep, setKeep] = React.useState(true);

    return <Section
        title={"Download randomly generated costs/cofs"}
        description={"This allows for downloading a file with random costs/cofs, following a distribution. You can edit the resulting file and resubmit."}
    >
        <DistributionBuilder title={"Cost"} dist={cost} setDist={setCost} />
        <br/>
        <DistributionBuilder title={"Cof"} dist={cof} setDist={setCof} />

        <br/>

        <input type="checkbox" checked={keep} onChange={(e)=>setKeep(!keep)}/> Keep original values
        <small><i>(if checked, the generated values will only apply to pipes without cost/cof)</i></small>

        <br/><br/>

        <button className={"btn btn-primary infraplan_button"} onClick={async () => {
            helpers.showProgress(true, { message: ("downloading csv...") });
            try {
                downloadFileFromData({
                    data: await Api.download(`projects/${Api.getProjectId()}/pipes/costs/csv/down`, {
                        data: {cof, cost, keep}
                    }).finally(() => helpers.showProgress(false)),
                    filename: `gen_costs_${Api.getProjectId()}_${new Date().toISOString()}.csv`
                });
            } catch(err) {
                toastr.error(err?.details?.map(d => d.msg)?.join("\n")||err.error||"unknown error");
            }
        }}><i className="fa fa-download"></i> Generate</button>
    </Section>;
}