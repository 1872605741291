import React from 'react'
import { find, compact } from 'lodash'

import PredictionSelect from './Predictions/PredictionSelect'
import TabSelect from './Predictions/TabSelect'
import Model from './Predictions/Model'
import AgingCurve from './Predictions/AgingCurve'

export default class Predictions extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      selectedPrediction: props.selectedPrediction || '',
      selectedCohort: props.selectedCohort || '',
      tab: 'curve'
    }

    this.baseUrl = window && window.location.href.replace(/(projects\/[^/]+)(.+)?/, '$1')
  }

  onPredictionChange = ({ cohort, prediction }) => this.setState({
    selectedPrediction: prediction,
    selectedCohort: cohort
  })

  onTabChange = (tab) => this.setState({ tab })

  componentDidUpdate(prevProps, prevState) {
    const { selectedCohort, selectedPrediction } = this.state

    if (prevState.selectedCohort !== selectedCohort
     || prevState.selectedPrediction !== selectedPrediction) {
      this.updateBrowserLocation()
    }
  }

  updateBrowserLocation() {
    const { selectedCohort, selectedPrediction } = this.state

    if (window.history.replaceState) {
      const newLocation = compact([
        this.baseUrl,
        selectedCohort && `cohorts/${selectedCohort}`,
        selectedPrediction && `predictions/${selectedPrediction}`,
      ]).join('/')

      window.history.replaceState({}, window.title, newLocation)
    }
  }

  getPrediction() {
    const { predictions } = this.props
    const { selectedPrediction } = this.state

    return find(predictions, ({ id }) => id == selectedPrediction) // eslint-disable-line eqeqeq
  }

  render() {
    const { predictions, cohorts, relevance_year: relevanceYear } = this.props
    const { tab, selectedPrediction, selectedCohort } = this.state

    const prediction = this.getPrediction()

    return (
      <div>
        <PredictionSelect
          baseUrl={ this.baseUrl }
          cohorts={ cohorts }
          predictions={ predictions }
          selectedCohort={ selectedCohort }
          selectedPrediction={ selectedPrediction }
          onChange={ this.onPredictionChange }
        />

        { (prediction && prediction.state === 'processed') ? (
          <div>
            <TabSelect
              selected={ tab }
              onChange={ this.onTabChange }
            />

            { tab === 'curve' && (
              <AgingCurve
                prediction={ prediction }
                relevanceYear={ relevanceYear }
              />
            ) }
            { tab === 'model' && (
              <Model prediction={ prediction } />
            ) }
          </div>
        ) : null }
      </div>
    )
  }
}
