import React, {useEffect} from "react";
import {useState} from "react";

const Clone = ({projectId, projectName}) => {
    const form = useState({
        name: '',
        materialMappings: [],
    })

    const containerCenterOfScreenStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        height: '80%',
        backgroundColor: 'white',
        padding: '30px',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        overflow: 'auto',
        zIndex: 1000
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        Api.post(`projects/${projectId}/tasks`, {
            taskType: 'cloneProject',
            payload: form
        }).then((data) => {
            console.log(data)
        })
    }

    return (
        <div style={containerCenterOfScreenStyle}>
            <h2>Clone Project <b>{projectName}</b></h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        value={form.name}
                        onChange={(e) => form.name = e.target.value}
                    />
                </div>
                <div className="form-group">
                    <label>Material Mappings</label>
                    <div className="form-row">
                        <div className="col">
                            <input
                                type="text"
                                className="form-control"
                                  />
                        </div>
                        <div className="col">
                            <input
                                type="text"
                                className="form-control"
                                />
                        </div>
                    </div>
                </div>
                <button type="submit" className="btn btn-primary">Clone</button>
            </form>
        </div>
    )

}

export default Clone;