import React from 'react'
import CostsUploader from "./CostsUploader";
import CostsDownloader from "./CostsDownloader";
import GeneratedCostsDownloader from "./GeneratedCostsDownloader";

export default ()=> {

  return <>
      <CostsUploader />
      <CostsDownloader />
      <GeneratedCostsDownloader />
  </>;
}