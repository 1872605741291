import React from 'react'
import { map } from 'lodash'

import { Grid } from '@vx/grid'
import { Group } from '@vx/group'
import { AxisLeft, AxisBottom } from '@vx/axis'
import { scaleLinear } from '@vx/scale'
import { LinePath, Circle } from '@vx/shape'
import { curveMonotoneX } from '@vx/curve'
import { ParentSize } from '@vx/responsive'

import {
  WithTooltip, Tooltip,
  Line as TooltipLine,
  Sensor as TooltipSensor,
  Content as TooltipContent
} from './Tooltip'

import {
  tickLabelLeft,
  tickLabelBottom,
  numTicksFromWidth,
  numTicksFromHeight,
  gray,
  minmax,
  axisLabelStyle,
  getColorsMap
} from './misc'

const Line = ({
  line, xScale, yScale
}) => {
  if (!line) return null

  const x = (d) => xScale(d.x)
  const y = (d) => yScale(d.y)

  return (
    <LinePath
      data={ line.data }
      x={ x }
      y={ y }
      curve={ curveMonotoneX }
      stroke={ line.color }
      strokeWidth={ 2 }
    />
  )
}

const Points = ({
  points, xScale, yScale
}) => {
  if (!points) return null

  const x = (d) => xScale(d.x)
  const y = (d) => yScale(d.y)

  return (
    map(points.data, (d) => (
      <Circle
        key={ d.x }
        cx={ x(d) }
        cy={ y(d) }
        r={ 3 }
        fill="#fff"
        stroke={ points.color }
        strokeWidth={ 2 }
      />
    ))
  )
}

export default class LineAndPoints extends React.PureComponent {
  static defaultProps = {
    margin: {
      left: 80,
      right: 10,
      top: 0,
      bottom: 70
    }
  }

  svg = React.createRef()

  render() {
    const {
      margin,
      line,
      points,
      bottomLabel,
      leftLabel,
      renderTooltipValue
    } = this.props

    return (
      <ParentSize>{ ({ height, width }) => {
        if (!width || !height) return null

        const series = [line, points]

        const colors = getColorsMap(series)

        const xMax = width - margin.left - margin.right
        const yMax = height - margin.top - margin.bottom

        const xDomain = minmax(series, 'x')
        const xScale = scaleLinear({
          domain: [Math.floor(xDomain[0]), xDomain[1]],
          range: [0, xMax]
        })

        const yDomain = minmax(series, 'y')
        const yScale = scaleLinear({
          domain: [yDomain[0] * 0.8, yDomain[1] * 1.1],
          range: [yMax, 0],
        })

        const numXTicks = numTicksFromWidth(xMax, 40)
        const numYTicks = numTicksFromHeight(yMax)

        return (
          <WithTooltip
            series={ series }
            xScale={ xScale }
            margin={ margin }
            getSvg={ () => this.svg.current }
            parentWidth={ width }
            parentHeight={ height }
          >{ (tooltipProps) => (
            <>
              <svg
                width={ width }
                height={ height }
                ref={ this.svg }
              >
                <AxisLeft
                  top={ margin.top }
                  left={ margin.left }
                  scale={ yScale }
                  tickLabelProps={ tickLabelLeft }
                  numTicks={ numYTicks }
                  stroke={ gray }
                  hideTicks
                  label={ leftLabel }
                  labelProps={ axisLabelStyle }
                  labelOffset={ 50 }
                />

                <AxisBottom
                  top={ height - margin.bottom }
                  left={ margin.left }
                  scale={ xScale }
                  tickLabelProps={ tickLabelBottom }
                  numTicks={ numXTicks }
                  stroke={ gray }
                  hideTicks
                  label={ bottomLabel }
                  labelProps={ axisLabelStyle }
                  labelOffset={ 20 }
                />

                <Group top={ margin.top } left={ margin.left }>
                  <Grid
                    stroke="#ddd"
                    xScale={ xScale }
                    yScale={ yScale }
                    width={ xMax }
                    height={ yMax }
                    numTicksRows={ numYTicks }
                    numTicksColumns={ numXTicks }
                  />

                  <Line
                    xScale={ xScale }
                    yScale={ yScale }
                    xMax={ xMax }
                    yMax={ yMax }
                    line={ line }
                  />

                  <Points
                    xScale={ xScale }
                    yScale={ yScale }
                    yMax={ yMax }
                    points={ points }
                  />

                  <TooltipLine { ...tooltipProps } yMax={ yMax } />
                  <TooltipSensor { ...tooltipProps } xMax={ xMax } yMax={ yMax } />
                </Group>
              </svg>
              <Tooltip
                { ...tooltipProps }
                margin={ margin }
                xMax={ xMax }
                yMax={ yMax }
              >
                <TooltipContent 
                  { ...tooltipProps }
                  colors={ colors }
                  renderValue={ renderTooltipValue }
                />
              </Tooltip>
            </>
          ) }</WithTooltip>
        )
      }}</ParentSize>
    )
  }
}
