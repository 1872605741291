import React from 'react'
import {
  find, isEqual
} from 'lodash'

const { $ } = window


export default class Table extends React.PureComponent {
  tableNode = React.createRef()

  componentDidMount() {
    this.initTable()
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.query, this.props.query)) {
      this.getDataTable().draw()
    }
  }

  /* eslint-disable no-param-reassign */
  getDataRequestParams = (params) => {
    const {
      scope, life_status, initial, search, predicates, excludeFilteredData
    } = this.props.query

    const { column: sortIndex, dir } = params.order[0]
    params.sort = params.columns[sortIndex].data
    params.dir = dir

    delete params.order
    delete params.columns
    delete params.search

    if (scope) { params.scope = scope }
    if (life_status) { params.life_status = life_status }
    if (initial) { params.initial = initial }
    if (search) { params.search = search }
    if (dir) { params.dir = dir }
    if (excludeFilteredData) { params.excludeFilteredData = excludeFilteredData }

    if (predicates) { params.predicates = JSON.stringify(predicates) }
  }
  /* eslint-enable no-param-reassign */

  getDataTable() {
    if (!this.tableNode.current) { return }

    return $(this.tableNode.current).DataTable()
  }

  onOrderChange = () => {
    const dataTable = this.getDataTable()

    if (this.props.onOrderChange && dataTable) {
      const [order] = dataTable.order()
      const { columns } = this.props
      const column = columns[order[0]]

      this.props.onOrderChange(
        column.name || column.data,
        order[1]
      )
    }
  }

  initTable = () => {
    const {
      columns, dataUrl, defaultOrder, query
    } = this.props

    const sort = query.sort || (defaultOrder && defaultOrder[0])
    const dir = query.dir || (defaultOrder && defaultOrder[1])

    if (!this.tableNode.current) { return }

    const orderColumn = (sort && find(columns, (column) => column.name === sort)) || null
    const orderDirection = dir || 'desc'

    const dataTable = $(this.tableNode.current).DataTable({
      ajax: {
        url: dataUrl,
        complete: this.props.onDataRequestComplete,
        data: this.getDataRequestParams
      },
      scrollX: true,
      processing: true,
      serverSide: true,
      columns,
      order: orderColumn ? [[orderColumn.index, orderDirection]] : undefined,
      dom: [
        // `<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>`,
        `<'datatable-fullscreen'`,
        `<'datatable-fullscreen-top'tr>`,
        `<'datatable-fullscreen-bottom'<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>>`,
        `>`,
      ].join(''),
      // iDisplayLength: 25,
      pageLength: 250,
      deferRender: true,
      fixedColumns: { leftColumns: 1 },
      select: true,
    })

    new Event('adjustTable', { cancelable: true, })

    document.querySelector("#pipes-list_wrapper").addEventListener(
      "adjustTable", (e) => {
           dataTable.columns.adjust()
       }
    )

    dataTable.on('order', this.onOrderChange)
    if (this.props.onDataRequested) {
      dataTable.on('preXhr', this.props.onDataRequested)
    }

    dataTable.on('init', () => {})

    dataTable.on('draw', () => {
        document.querySelectorAll(".btnForceRedraw").forEach(element => {
          element.addEventListener('click', (e) => {
            e.preventDefault();
            dataTable.columns.adjust();
          });
        });
    })




  }

  render() {
    return (
      <table
        id="pipes-list"
        ref={ this.tableNode }
        className="pipes selectable"
        style={{ width: '100%' }}
      />
    )
  }
}
