import React from 'react'
import Select from './Select'
import RadioButtons from './RadioButtons'

import BarXAxisSelect from './ChartForm/BarXAxisSelect'
import YAxisSelect from './ChartForm/YAxisSelect'
import PeriodsInput from './ChartForm/PeriodsInput'

export default class ChartForm extends React.Component {
  state = {
    chart_type: this.props.chart_type,
    aggregation_type: this.props.aggregation_type,
    aggregate_id: this.props.aggregate_id,
    yleft: this.props.yleft,
    yright: this.props.yright,
    periods_list: this.props.periods_list
  }

  onChartTypeChange = (value) => this.setState({ chart_type: value, aggregation_type: 'covariate' })
  onBarXAxisChange =
    ({ type, id }) => this.setState({ aggregation_type: type, aggregate_id: id })
  onAggregateIdChange = (value) => this.setState({ aggregate_id: value })
  onYLeftChange = (value) => this.setState({ yleft: value })
  onYRightChange = (value) => this.setState({ yright: value })
  onPeriodsListChange = (value) => this.setState({ periods_list: value })

  render() {
    const {
      chart_type_options,
      aggregate_options,
      pie_aggregate_options,
      basic_metrics,
      by_age_metrics,
      period_metrics,
      pie_metrics,
      yearly_groups,
      yearly_metric_groups,
      wobo
    } = this.props

    const {
      chart_type,
      aggregation_type,
      aggregate_id,
      yleft,
      yright,
      periods_list
    } = this.state

    const yaxisProps = {
      aggregationType: aggregation_type,
      basic_metrics,
      by_age_metrics,
      period_metrics,
      yearly_groups,
      yearly_metric_groups,
      includeBlank: true
    }

    return (
      <div>
        <RadioButtons
          name="chart[chart_type]"
          options={ chart_type_options }
          value={ chart_type }
          onChange={ this.onChartTypeChange }
        />
        <input
          type="hidden"
          name="chart[aggregate_id]"
          value={ aggregate_id  || '' }
        />
        <input
          type="hidden"
          name="chart[aggregation_type]"
          value={ aggregation_type || '' }
        />
        { chart_type === 'bar' ? (
          <div>
            <div className="row">
              <div className="col-3 bar_chart_only"></div>
              <div className="col-2">
                <div className="form-group">
                  <label>X Axis</label>
                  <BarXAxisSelect
                    options={ aggregate_options }
                    value={ aggregate_id }
                    aggregationType={ aggregation_type }
                    onChange={ this.onBarXAxisChange }
                    className="form-control"
                  />
                </div>
                { aggregation_type === 'period' && (
                  <div className="form-group">
                    <label>Periods</label>
                    <PeriodsInput
                      name="chart[periods_list]"
                      minmax={ wobo }
                      value={ periods_list }
                      onChange={ this.onPeriodsListChange }
                      className="form-control"
                      placeholder="1980-1995, for example"
                    />
                    <small className="form-text text-muted">
                      one per line, WOBO is { wobo.join('—') }
                    </small>
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-3 bar_chart_only">
                <label>Y Left</label>
                <YAxisSelect
                  { ...yaxisProps }
                  name="chart[yleft]"
                  value={ yleft }
                  onChange={ this.onYLeftChange }
                />
              </div>
              <div className="col-2" />
              <div className="col-3 bar_chart_only">
                <label>Y Right</label>
                <YAxisSelect
                  { ...yaxisProps }
                  name="chart[yright]"
                  value={ yright }
                  onChange={ this.onYRightChange }
                />
              </div>
            </div>
          </div>
        ) : chart_type === 'pie' ? (
          <div className="row">
            <div className="col-3">
              <div className="form-group">
                <label>VAR Analyzed</label>
                <Select
                  name="chart[aggregate_id]"
                  options={ pie_aggregate_options }
                  value={ aggregate_id }
                  onChange={ this.onAggregateIdChange }
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Show % of</label>
                <Select
                  name="chart[yleft]"
                  options={ pie_metrics }
                  value={ yleft }
                  onChange={ this.onYLeftChange }
                  className="form-control"
                />
              </div>
            </div>
          </div>
        ) : null }
      </div>
    )
  }
}
