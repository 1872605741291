import React from 'react'
import { debounce } from 'lodash'

export default class SearchInput extends React.PureComponent {
  state = {
    value: this.props.value
  }

  static defaultProps = {
    value: ''
  }

  onChange = (e) => {
    let { value } = e.currentTarget

    this.setState({ value })

    this.reportOnChange()
  }

  reportOnChange = debounce(() => this.props.onChange(this.state.value), 300)

  render() {
    const { value } = this.state

    return (
      <input
        type="text"
        placeholder="Search…"
        value={ value || '' }
        onChange={ this.onChange }
        className="form-control"
        style={{height: 'auto'}}
      />
    )
  }
}
