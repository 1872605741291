import Table from "./Table";
import React, {useState} from "react";
import {useEffect} from "react";
import SummaryTables from "./SummaryTables";
import Tasks from "../Tasks";

const OPTIONS = [
    { name: 'all', label: 'All', disabled: false, checked: true },
    { name: 'act', label: 'Active', disabled: false, checked: true },
    { name: 'abn', label: 'Abandoned', disabled: false, checked: true },
    { name: 'length', label: 'Length', disabled: false },
    { name: 'count', label: 'Number', disabled: true, checked: true }, // Initially disabled
    { name: 'percent', label: 'Percentages', disabled: false, checked: true },
    { name: 'current', label: 'Current Data', disabled: false, checked: true },
    { name: 'initial', label: 'Initial Data', disabled: false, checked: true },
    { name: 'project_stats', label: 'Project Stats', disabled: false },
    { name: 'activeTab', label: 'Active Tab', result: 'pipes'}
];

const SummaryOptions = ({options, changed}) => {
    return (
        <form id="options_form">
            <div className="field radiogroup">
                <label className="infraplan_label">Display Options: </label>
                <div className="values">
                    {options.filter(option => option.name !== 'project_stats').map((option) => (
                        <React.Fragment key={option.name}>
                            <input
                                type="checkbox"
                                name={option.name}
                                id={`options_${option.name}`}
                                value={option.name}
                                checked={option.checked}
                                onChange={changed}
                                disabled={option.disabled}
                            />
                            <label
                                style={{marginLeft: '3px'}}
                                htmlFor={`options_${option.name}`}>{option.label}</label>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </form>
    )
}

const fetchIssuesAndFilterDataFromTags = async (projectId) => {
    const issues = (await Api.get(`projects/${projectId}/issues_with_tag`)).map(issue => {
        return {
            [issue.tag || `Issue_${issue.id}`]: {
                id: issue.id,
                name: issue.name,
                builtin: issue.builtin,
                active: issue.active
            }
        }
        }).reduce((acc, issue) => {
        return {
            ...acc,
            ...issue
        }
    }, {})
    const filters = (await Api.get(`projects/${projectId}/filters_with_tag`)).map(filter => {
        return {
            [filter.tag || `Filter_${filter.id}`]: {
                id: filter.id,
                name: filter.name,
                builtin: filter.builtin,
                can_delete: filter.can_delete,
                target: filter.target,
                tag: filter.tag,
            }
        }
        }).reduce((acc, filter) => {
        return {
            ...acc,
            ...filter
        }
    }, {})
    return { issues, filters };
}

export default (props) => {
    const [options, setOptions] = useState(OPTIONS);
    const [activeTab, setActiveTab] = useState('pipes');
    const [project, setProject] = useState(props.project);
    const [loading, setLoading] = useState(false)
    const [tableWidth, setTableWidth] = useState('100%');

    useEffect(() => {
        // load options from local storage
        const savedOptions = localStorage.getItem('summaryOptions');
        if (savedOptions) {
            const options = JSON.parse(savedOptions);
            setOptions(options);
            if (options.find(op => op.name === 'activeTab')) {
                setActiveTab(options.find(op => op.name === 'activeTab').result );
            }
        }
    }, [])

    useEffect(() => {
        // save options to local storage
        localStorage.setItem('summaryOptions', JSON.stringify(options));
    }, [options])

    const handleOptionChange = (event) => {
        const { name, checked } = event.target;
        setOptions(prevOptions =>
            prevOptions.map(option =>
                option.name === name ? { ...option, checked: checked } : option
            )
        );
    };

    useEffect(() => {
        setLoading(true)
        fetchIssuesAndFilterDataFromTags(project.id)
            .then(({ issues, filters }) => {
                setProject(p => {
                    return {
                        ...p,
                        issues,
                        filters
                    }
                })
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(
            () => setLoading(false)
        )
    }, [])

    useEffect(() => {
        setOptions(prevOptions => {
            const updatedOptions = prevOptions.map(option => {
                if (option.name === 'length') {
                    return activeTab === 'breaks'
                        ? { ...option, disabled: true, checked: false }
                        : { ...option, disabled: false, checked: option.checked || false };
                }
                if (option.name === 'activeTab') {
                    return { ...option, result: activeTab };
                }
                return option;
            });

            // Add activeTab if it doesn't exist
            if (!updatedOptions.find(option => option.name === 'activeTab')) {
                updatedOptions.push({ name: 'activeTab', result: activeTab });
            }

            return updatedOptions;
        });
    }, [activeTab]);

    if (loading) {
        return <div>Loading...</div>
    }

    return <div>
        <SummaryOptions options={options} changed={handleOptionChange} />
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            maxWidth: tableWidth,
        }}>
        <DataSummaryTabs activeTab={activeTab} setActiveTab={setActiveTab}/>
        <div style={{
        display: 'flex',
            gap: 2
        }}>
            <ProjectStatsToggle
            projectStats={options.find(option => option.name === 'project_stats')}
            setProjectStats={handleOptionChange}
        />
        </div>
        </div>

        <SummaryTables
            itemType={activeTab}
            initialData={props.initialData}
            currentData={props.currentData}
            project={project}
            options={options}
            updatedWidth={(width) => setTableWidth(`${width}px`)}
        />
    </div>
}

const ProjectStatsToggle = ({projectStats, setProjectStats}) => {
    const styles = {
        container: {
            display: 'flex',
            alignItems: 'flex-start',
            alignSelf: 'center',
        },
        label: {
            marginRight: '10px',
            fontSize: '16px',
            fontWeight: 'bold',
        },
        toggleSwitch: {
            width: '50px',
            height: '25px',
            borderRadius: '50px',
            backgroundColor: projectStats.checked ? '#007BFF' : '#ccc',
            position: 'relative',
            cursor: 'pointer',
            transition: 'background-color 0.2s ease',
        },
        toggleHandle: {
            width: '23px',
            height: '23px',
            backgroundColor: 'white',
            borderRadius: '50%',
            position: 'absolute',
            top: '1px',
            left: projectStats.checked ? 'calc(100% - 24px)' : '1px',
            transition: 'left 0.2s ease',
        },
    }

    return (
        <div style={styles.container}>
            <label style={styles.label}>Project Stats</label>
            <div style={styles.toggleSwitch} onClick={() => setProjectStats({
                target: {
                    name: 'project_stats',
                    checked: !projectStats.checked
                }
            })}>
                <div style={styles.toggleHandle}></div>
            </div>
        </div>
    )
}

const DataSummaryTabs = ({activeTab, setActiveTab}) => {

    const handleTabClick = (tab) => (event) => {
        event.preventDefault();
        setActiveTab(tab);
    };

    return (
        <div className="tab-system" id="data-summary-tabs">
            <div className="tabs adjusted">
                <a
                    className={`item infraplan_toggle_with_text_left ${activeTab === 'pipes' ? 'active' : ''}`}
                    href="#"
                    onClick={handleTabClick('pipes')}
                    data-tab="pipes"
                >
                    Pipes
                </a>
                <a
                    className={`item infraplan_toggle_with_text_right ${activeTab === 'breaks' ? 'active' : ''}`}
                    href="#"
                    onClick={handleTabClick('breaks')}
                    data-tab="breaks"
                >
                    Breaks
                </a>
            </div>
        </div>
    );
};
