import React from 'react'
import ColorEditor from './ColorEditor';
import useApi from "../Hooks/useApi";
import LoadingIcon from "../Misc/LoadingIcon";

export default function ({projectId}) {
    let {loading, data:materials, setData:setMaterials } = useApi.get(`settings/material-colors`);

    if(loading) {
        return <LoadingIcon />;
    }

    const onSaveMaterial = ({material, color}) => {
        setMaterials({
            ...materials,
            [material]: color
        });
    }

    return <>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: "wrap" }}>
            {
                Object.entries(materials)
                    .map(([material, color]) =>
                        <div key={material} style={{padding: "1rem 2rem"}}>
                            <ColorEditor material={material}
                                         color={color}
                                         projectId={projectId}
                                         onSave={onSaveMaterial}
                                         style={{width: "100%"}}
                            />
                        </div>)
            }
        </div>
    </>;
}
