import React from 'react'
import Section from "./Section";
import {downloadFileFromData} from "../../utils/downloader";


export default ()=>
    <Section title={"Download currents costs/cofs"}
             description={"This allows for downloading a file with the costs/cofs for all existing pipes. You can edit this file and resubmit it above."}
    >
        <button className={"btn btn-primary infraplan_button"} onClick={async ()=>{
            helpers.showProgress(true, { message: ("downloading csv...") });
            downloadFileFromData({
                data:       await Api.download(`projects/${Api.getProjectId()}/pipes/costs/csv/down`).finally(()=>helpers.showProgress(false)),
                filename:   `costs_${Api.getProjectId()}_${new Date().toISOString()}.csv`
            });
        }}><i className="fa fa-download"></i> Download</button>
    </Section>;