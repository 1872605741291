import React from 'react'
import c from 'classnames'
import { map } from 'lodash'
import Column from './Column'
import { loadingFunc } from './Numbers'
import Button from '../Button'
import formatNumber from '../../utils/number'

const { $ } = window

const LOAD_INTERVAL = 2000

export default class Prediction extends React.Component {
  state = {
    deleteHighligted: false
  }

  highlightDelete = () => this.setState({ deleteHighligted: true })
  unhighlightDelete = () => this.setState({ deleteHighligted: false })

  componentDidMount() {
    if (this.props.prediction && this.props.prediction.state === 'initial') {
      this.loadLater()
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  loadLater = () => {
    this.timeout = setTimeout(this.load, LOAD_INTERVAL)
  }

  load = () => {
    const { urls, prediction: { id }} = this.props
    const url = urls.json_prediction_url.replace(/PREDICTION_ID/, id)

    $.getJSON(url, this.onLoaded)
  }

  onLoaded = (data) => {
    if (data.state === 'initial') {
      this.loadLater()
    } else {
      this.props.replacePrediction(this.props.prediction.id, data)
    }
  }

  onDelete = (e) => {
    const { prediction } = this.props

    if (!window.confirm(`Delete ${prediction.name} model?`)) { // eslint-disable-line no-alert
      e.preventDefault()
    }
  }

  render() {
    const {
      cohort, prediction, urls, highlightColumnProps: columnProps
    } = this.props

    const { deleteHighligted } = this.state

    if (!prediction) {
      return (
        <div className="cohorts-cohort-prediction cohort-numbers">
          <Column className="cohorts-cohort-prediction-name" />
          <Column { ...columnProps } name="pbr_next_year" wide />
          <Column { ...columnProps } name="aging_factor" />
          <Column { ...columnProps } name="eul_15" />
          <Column { ...columnProps } name="eul_20" />
          <Column { ...columnProps } name="eul_50" />
          <Column className="cohorts-cohort-prediction-delete" />
        </div>
      )
    }

    const {
      stats, name, id, state, state_comment
    } = prediction

    const render = state === 'processed' ? formatNumber : loadingFunc
    const renderEUL = (number) => {
      const rendered = render(number)

      if (rendered === '200.0') {
        return <span>200.0 <sup>(4)</sup></span>
      }

      return rendered
    }

    const url = (cohort ? urls.cohort_prediction_url : urls.bare_prediction_url).replace(/PREDICTION_ID/, id)

    return (
      <div
        className={ c(
          'cohorts-cohort-prediction',
          'cohort-numbers',
          deleteHighligted && 'cohorts-cohort-prediction-deleteHighlighted'
        ) }
      >
        <Column className={ `cohorts-cohort-prediction-name cohorts-cohort-prediction-state-${state}` }>
          { state === 'processed' && <a href={ url }>{ name }</a> }
          { state === 'initial' && (
            <span className="withSpinner">
              { state === 'initial' && <span className="icon spinner spin" />}
              { name }
            </span>
          ) }
          { state === 'failure' && name }
        </Column>

        { state === 'failure' && (
          <Column className="cohorts-cohort-prediction-failure">
            { state_comment && `(${state_comment.toLowerCase()})` }
          </Column>
        ) }

        { state !== 'failure' && (
          <React.Fragment>
            <Column { ...columnProps } name="pbr_next_year" wide>{ render(stats.pbr_next_year, 3) }</Column>
            <Column { ...columnProps } name="aging_factor">{ render(stats.aging_factor, 3) }</Column>
            { map(stats.euls, ({ pbr, age }, index) => (
              <Column { ...columnProps } key={ pbr } name={ `eul_${index}` }>{ renderEUL(age) }</Column>
            )) }
          </React.Fragment>
        ) }
        <Column className="cohorts-cohort-prediction-delete">
          <Button // eslint-disable-line jsx-a11y/mouse-events-have-key-events
            method="DELETE"
            onClick={ this.onDelete }
            href={ urls.delete_prediction_url.replace('PREDICTION_ID', prediction.id) }
            onMouseOver={ this.highlightDelete }
            onMouseOut={ this.unhighlightDelete }
          >×</Button>
        </Column>
      </div>
    )
  }
}
