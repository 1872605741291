import React from 'react'
import { useTable, usePagination, useSortBy } from 'react-table'
import { CSVLink } from "react-csv";


export default function PredictionsTable({ data, validation, initColumns, downloadable }) {

  if (!data) {
    return <p>No data was provided for this table.</p>
  }

  const projectId = Api.getProjectId()
  const baseRef = `/projects/${projectId}/pipes/`

  
  if (!initColumns) {
    initColumns = [
        {
          Header: "PBN Rank",
          accessor: validation ? "validation_rank_pbn" : "predictions_rank_pbn"
        },
        {
          Header: "PBR Rank",
          accessor: validation ? "validation_rank_pbr" : "predictions_rank_pbr"
        },
        {
          Header: "ID",
          accessor: "utility_pipe_id",
          Cell: props => <a href={baseRef + props.row.original.pipe_id}>{props.value}</a>
        },
        {
          Header: "Length",
          accessor: "length",
          Cell: props => props.value?.toFixed(3) || "-",
          sortMethod: (a, b) => Number(a)-Number(b),
          sortType: "basic"
        },
        {
          Header: "Diameter",
          accessor: "diameter",
        },
        {
          Header: "Material",
          accessor: "material"
        },
        {
          Header: "Date of Installation",
          accessor: "installation_date"
        },
        {
          Header: "PBN",
          accessor: validation ? "validation_pbn" : "predictions_pbn",
          Cell: props => props.value?.toFixed(3) || "-",
          sortMethod: (a, b) => Number(a)-Number(b),
          sortType: "basic"
        },
        //  {
        //    Header: "Survival",
        //    accessor: validation ? "vSingleSurvival" : "pSurvival",
        //    Cell: props => props.value.toFixed(3),
        //    sortMethod: (a, b) => Number(a)-Number(b),
        //    sortType: "basic"
        //  }
      ]

    if (validation) {
      initColumns.push({
        Header: "Breaks - Validation",
        accessor: "validation_breaks"
      })
      initColumns.push({
        Header: "Breaks - Calibration",
        accessor: "calibration_breaks"
      })
    } else {
      initColumns.push({
        Header: "Previous Breaks",
        accessor: "pred_previous_breaks"
      })
    }
  }

  const columns = React.useMemo(
    () => initColumns, []
  )

  /*
    const data = React.useMemo(
      () => initData, []
    )
  */

  const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0 },
      },
      useSortBy,
      usePagination
    )

  return (
    <>
    <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                   {column.isSorted
                     ? column.isSortedDesc
                       ? ' 🔽'
                       : ' 🔼'
                     : ''}
                 </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
   <div className="pagination">
        <button className="btn" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button className="btn" onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button className="btn" onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button className="btn" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
        { downloadable && 
          <div style={{marginLeft: "10px"}} className="float-right">
            <CSVLink
                data={data}
                headers={initColumns.map((e) => e.accessor)}
                target="_blank"
                className="btn btn-secondary float-right"
                filename={"export.csv"}
            >
                Export to CSV
            </CSVLink>
          </div>
        }
      </div>
   </>
  )
}
