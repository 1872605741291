import React from 'react'
import {
  map, transform, findIndex, find, round
} from 'lodash'
import { localPoint } from '@vx/event'
import { Motion, spring, presets } from 'react-motion'
import { Line as VxLine } from '@vx/shape'

export class WithTooltip extends React.Component {
  static defaultProps = {
    xScaleType: 'linear'
  }

  state = {
    open: false,
    left: undefined,
    top: undefined,
    data: undefined
  }

  close = () => this.setState({ open: false })

  mouseLeave = this.close

  mouseMove = (e) => {
    const svg = this.props.getSvg()

    if (!svg) { return }

    const { x, y } = localPoint(svg, e)

    this.showAt(x, y)
  }

  getXMax({ margin, parentWidth } = this.props) {
    return parentWidth - margin.left - margin.right
  }

  getYMax({ margin, parentHeight } = this.props) {
    return parentHeight - margin.top - margin.bottom
  }

  showAt = (x, y) => {
    const {
      series, xScale, xScaleType, margin
    } = this.props

    const xMax = this.getXMax()
    const yMax = this.getYMax()

    const positionX = x - margin.left
    const positionY = y - margin.top

    if (positionX < 0 || positionX > xMax || positionY < 0 || positionY > yMax) {
      this.close()
      return
    }

    // TODO
    this.tooltipWidth = 300

    let xDomain
    if (xScaleType === 'band') {
      const step = xScale.step()
      const paddingOuter = xScale.paddingOuter() * step
      const innerX = x - margin.left - paddingOuter
      const index = Math.floor(innerX / step)

      xDomain = xScale.domain()[index]
    } else {
      xDomain = xScale.invert(x - margin.left)
    }

    const dataPoints = transform(series, (result, { id, label, data }) => {
      const key = id || label
      let point

      if (xScaleType === 'band') {
        point = find(data, (d) => d.x == xDomain) // eslint-disable-line eqeqeq
      } else {
        let index = findIndex(data, (d) => d.x > xDomain)

        if (index === undefined) index = data.length

        const dLeft = data[index - 1]
        const dRight = data[index]

        const isRightCloser = dRight && dLeft && ((xDomain - dLeft.x) > (dRight.x - xDomain))

        point = isRightCloser ? dRight : dLeft
      }

      if (!point) return

      if (point.x && !result.x) {
        result.x = point.x // eslint-disable-line no-param-reassign
        xDomain = point.x
      }

      result.y.push({
        label: key,
        y: point.y
      })
    }, { x: undefined, y: [] })

    let lineLeft
    if (xScaleType === 'band') {
      lineLeft = xScale(dataPoints.x) + xScale.bandwidth() / 2
    } else {
      lineLeft = xScale(dataPoints.x)
    }

    if (dataPoints.x === undefined || dataPoints.y.length === 0) {
      this.close()
      return
    }

    const xOffset = 18
    const yOffset = 18

    const positionXWithOffset = lineLeft + xOffset
    const pastRightSide = positionXWithOffset + this.tooltipWidth > xMax

    const tooltipLeft = pastRightSide ? (
      lineLeft - this.tooltipWidth - xOffset
    ) : positionXWithOffset

    const tooltipTop = positionY - yOffset

    this.setState({
      open: true,
      data: dataPoints,
      left: tooltipLeft,
      top: tooltipTop,
      lineLeft
    })
  }

  render() {
    const { children } = this.props

    return (
      <div style={{ position: 'relative' }}>{
        children({
          mouseLeave: this.mouseLeave,
          mouseMove: this.mouseMove,
          ...this.state
        })
      }</div>
    )
  }
}


export const Sensor = ({
  xMax, yMax, mouseLeave, mouseMove
}) => (
  <rect
    x="0"
    y="0"
    width={ xMax }
    height={ yMax }
    fill="transparent"
    onMouseLeave={ mouseLeave }
    onMouseMove={ mouseMove }
    onTouchMove={ mouseMove }
  />
)

export const Line = ({
  lineLeft, open, yMax
}) => (
  <Motion
    defaultStyle={{ left: lineLeft || 0, opacity: 0 }}
    style={{
      left: spring(lineLeft || 0, presets.stiff),
      opacity: spring(open ? 1 : 0, presets.stiff)
    }}
  >{ (style) => (
    <VxLine
      from={{ x: style.left, y: 0 }}
      to={{ x: style.left, y: yMax }}
      stroke="rgb(0, 0, 0, .5)"
      strokeWidth={ 1 }
      strokeDasharray="8,3"
      opacity={ style.opacity }
    />
  ) }</Motion>
)

export const Tooltip = ({
  children, xMax, yMax, margin, left, open
}) => (
  <div
    className="chart-tooltipContainer"
    style={{
      top: margin.top,
      left: margin.left,
      width: xMax,
      height: yMax
    }}
  >
    <Motion
      defaultStyle={{ left: left || 0, opacity: 0 }}
      style={{
        left: spring(left || 0, presets.stiff),
        opacity: spring(open ? 1 : 0, presets.stiff)
      }}
    >{ (style) => (
      <div
        className="chart-tooltipBox"
        style={{
          left: style.left,
          opacity: style.opacity
        }}
      >{ children }</div>
    )}</Motion>
  </div>
)

const defaultRenderValue = (value) => round(value, 2)

export const Content = ({
  data, colors, renderValue = defaultRenderValue
}) => (
  <div className="chart-tooltipContent">
    <strong>{ data && data.x }</strong>
    { data && map(data.y.reverse(), ({ label, y: value }) => (
      <div key={ label }>
        <span
          className="chart-tooltipContent-dot"
          style={{
            backgroundColor: (colors && colors[label]) || '#000'
          }}
        />
        <strong>{ label }</strong>: { renderValue(value) }
      </div>
    ))}
  </div>
)
