import React from 'react'
import {
  uniq, compact, map
} from 'lodash'

import { scaleBand } from '@vx/scale'
import { withParentSize } from '@vx/responsive'
import memoize from 'lru-memoize'

import Base from './Base'

import {
  WithTooltip, Tooltip,
  Line as TooltipLine,
  Sensor as TooltipSensor,
  Content as TooltipContent
} from '../Tooltip'

import { getColorsMap } from '../misc'

const combineSeries = memoize()((bar, line) => compact([bar, line]))

class Main extends React.PureComponent {
  base = React.createRef()

  render() {
    const {
      margin,
      bar,
      line,
      parentWidth: width,
      parentHeight: height
    } = this.props

    if (!width || !height) return null

    const series = combineSeries(bar, line)

    const xMax = width - margin.left - margin.right
    const yMax = height - margin.top - margin.bottom

    const domain = uniq([...map(bar.data, 'x'), ...map(line.data, 'x')])

    const colors = getColorsMap(series)

    const xScale = scaleBand({
      domain,
      range: [0, xMax],
      padding: 0.2
    })

    return (
      <WithTooltip
        series={ series }
        xScale={ xScale }
        xScaleType="band"
        margin={ margin }
        getSvg={ () => this.base.current && this.base.current.svg.current }
        parentWidth={ width }
        parentHeight={ height }
      >{ (tooltipProps) => (
        <>
          <Base { ...this.props } ref={ this.base }>
            <TooltipLine { ...tooltipProps } yMax={ yMax } />
            <TooltipSensor { ...tooltipProps } xMax={ xMax } yMax={ yMax } />
          </Base>
          <Tooltip
            { ...tooltipProps }
            margin={ margin }
            xMax={ xMax }
            yMax={ yMax }
          >
            <TooltipContent { ...tooltipProps } colors={ colors } />
          </Tooltip>
        </>
      ) }</WithTooltip>
    )
  }
}

export default withParentSize(Main)
