import React from 'react'
import {
  find, get, map, findIndex, isEmpty, mean, round
} from 'lodash'
import * as qs from 'qs'
import c from 'classnames'
import memoize from 'lru-memoize'
import CalibrationSubmitter from './Submitter'
import PeriodsInput from '../ChartForm/PeriodsInput'

const getInitial = (covariates) => map(covariates, (covariate) => ({
  ...covariate,
  recommended: covariate.recommended || 'unknown',
  selected: (covariate.recommended === 'yes' ? true : covariate.recommended === 'no' ? false : undefined)
}))

const getRangesPlaceholder = memoize(1)((minmax) => {
  const [min, max] = minmax
  const middle = round(mean(minmax))

  return `For example:\n${min}-${middle}\n${middle}-${max}`
})

class Covariate extends React.Component {
  onClick = () => {
    this.props.onClick(this.props.covariate.id)
  }

  continue = () => {
    this.props.continue(this.props.covariate.id, true)
  }

  no = () => {
    this.props.continue(this.props.covariate.id, false)
  }

  render() {
    const {
      open,
      covariate: {
        name, recommended, selected, is_diameter
      }
    } = this.props

    const childrenProps = {
      ...this.props,
      continue: this.continue,
      no: this.no
    }

    const children = (
      is_diameter ? <Diameter { ...childrenProps } /> : <CustomCovariate { ...childrenProps } />
    )

    return (
      <div
        className={ c(
          'calibration-variables-variable',
          `calibration-variables-variable--${recommended}`,
          open && 'calibration-variables-variable--open'
        ) }
      >
        <div // eslint-disable-line jsx-a11y/click-events-have-key-events
          className="calibration-variables-name"
          onClick={ this.onClick }
        >
          { selected === undefined && <span className="icon fa-question" /> }
          { selected === true && <span className="icon fa-check" /> }
          { selected === false && <span className="icon fa-times" /> }

          <span className="calibration-variables-name-inner">{ name }</span>
        </div>
        { open && (
          <div className="calibration-variables-content">
            { children }
          </div>
        )}
      </div>
    )
  }
}

class CustomCovariate extends React.Component {
  state = {
    canGoBackToUnknown: this.props.covariate.recommended === 'unknown',
    page: this.props.covariate.recommended
  }

  goToUnknown = () => this.setState({ page: 'unknown' })
  goToNo = () => this.setState({ page: 'no' })
  goToYes = () => this.setState({ page: 'yes' })

  render() {
    const {
      page,
      canGoBackToUnknown
    } = this.state

    const {
      covariate: { name },
      continue: submit, no
    } = this.props

    return (
      <>
        { page === 'yes' && (
          <>
            { canGoBackToUnknown && (
              <div className="mb-4">
                <button
                  type="button"
                  className="btn btn-secondary btn-sm"
                  onClick={ this.goToUnknown }
                >← I&apos;m not sure, go back</button>
              </div>
            ) }

            <p>Is recommended to be introduced to the model.</p>
            <div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={ submit }
              >Continue</button>
            </div>
          </>
        ) }
        { page === 'no' && (
          <>
            { canGoBackToUnknown && (
              <div className="mb-4">
                <button
                  type="button"
                  className="btn btn-secondary btn-sm"
                  onClick={ this.goToUnknown }
                >← I&apos;m not sure, go back</button>
              </div>
            ) }

            <p>
            It is recommended that a new VAR with regrouped
            modalities (VARn, n is the number of new modalities) so that:
            </p>

            <ul>
              <li>
                Each newly created modality has a length of at least 10% of the total length
              </li>
              <li>
                The Average Yearly Break Rate of the modalities to be regrouped are similar
              </li>
              <li>
                The Average Yearly Break Rate of the regrouped modalities differ
                by a ratio of at least 1.1.
              </li>
            </ul>

            <p>You may also continue with existing VAR.</p>

            <div className="row calibration-variables-variable-buttonsRow">
              <div className="col-6">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={ no }
                >Ignore</button>
              </div>
              <div className="col-6">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={ submit }
                >Continue with existing VAR</button>
              </div>
            </div>
          </>
        ) }
        { page === 'unknown' && (
          <>
            <p>The above Chart shows:</p>
            <ul>
              <li>X-axis: { name }</li>
              <li>Left y-axis: Current Average Yearly Break Rate</li>
              <li>Right y-axis: Current length</li>
            </ul>

            <p>
              Does the Average Yearly Break Rate increase or decrease steadily
              (ignore off-trend modality that has very small length)?
            </p>

            <div className="row calibration-variables-variable-buttonsRow">
              <div className="col-6">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={ this.goToYes }
                >Yes</button>
              </div>
              <div className="col-6">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={ this.goToNo }
                >No</button>
              </div>
            </div>
          </>
        ) }
      </>
    )
  }
}

class Diameter extends React.Component {
  state = {
    page: this.props.regroupDiameter ? 'regrouping' : 'raw',
    includeRaw: this.props.selected
  }

  changeIncludeRaw = (e) => this.setState({ includeRaw: e.currentTarget.checked })

  goToRegrouping = () => {
    this.setState({ page: 'regrouping' })
    this.props.changeRegroupDiameter(true)
  }
  goToRaw = () => {
    this.setState({ page: 'raw' })
    this.props.changeRegroupDiameter(false)
  }

  render() {
    const { page, includeRaw } = this.state

    const {
      covariate: {
        minmax
      },
      continue: submit, no,
      diameterRanges: ranges,
      changeDiameterRanges: onRangesChange
    } = this.props

    return (
      <>
        { page === 'raw' && (
          <>
            <p>If it looks okay, we recommend using it.</p>
            <p>You can also regroup it, or ignore completely.</p>

            <div className="calibration-variables-variable-buttonsRow">
              <div className="mb-1">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={ submit }
                >Use as is</button>
              </div>

              <div className="mb-1">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={ this.goToRegrouping }
                >Regroup</button>
              </div>

              <div className="mb-1">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={ no }
                >Ignore</button>
              </div>
            </div>
          </>
        ) }
        { page === 'regrouping' && (
          <>
            <div className="mb-4">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={ this.goToRaw }
              >← Don&apos;t regroup</button>
            </div>

            <p>
              Let&apos;s regroup diameter. We have it ranging from { minmax[0] } to { minmax[1] }.
              The chart on the left will display the choices you&apos;ve made.
            </p>

            <div className="form-group">
              <PeriodsInput
                name="complete[diameter_ranges]"
                minmax={ minmax }
                value={ ranges }
                onChange={ onRangesChange }
                className="form-control"
                placeholder={ getRangesPlaceholder(minmax) }
              />
            </div>

            <p>
              <label>
                <input
                  type="checkbox"
                  name="complete[include_original_diameter]"
                  checked={ includeRaw }
                  onChange={ this.changeIncludeRaw }
                /> Include original diameter as well.
              </label>
            </p>

            <div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={ includeRaw ? submit : no }
              >Continue</button>
            </div>
          </>
        ) }
      </>
    )
  }
}

export default class SelectVariables extends React.PureComponent {
  constructor(props) {
    super(props)

    const openCovariateId = get(this.props.covariates, [0, 'id'])

    const diameterRanges = get(find(this.props.covariates, ({ is_diameter }) => is_diameter), 'ranges')

    this.state = {
      covariates: getInitial(this.props.covariates),
      openCovariateId,
      iframeLoading: true,
      iframeSrc: this.getIframeSrc(openCovariateId),
      regroupDiameter: !!diameterRanges,
      diameterRanges
    }
  }

  iframeLoaded = () => this.setState({ iframeLoading: false })
  getIframeSrc = (openCovariateId) => {
    if (!openCovariateId) return null

    const query = qs.stringify({ covariate_id: openCovariateId })

    return `${this.props.chart_url}?${query}`
  }

  getIframeSrcForDiameter = (ranges) => {
    const query = qs.stringify({ ranges: JSON.stringify(ranges) })

    return `${this.props.diameter_chart_url}?${query}`
  }

  setStateWithIframe = ({
    regroupDiameter: newRegroupDiameter,
    covariate_id: newCovariateId,
    diameterRanges: newRanges
  }) => this.setState(
    ({
      covariates,
      openCovariateId: currentCovariateId,
      iframeSrc: currentIframeSrc,
      regroupDiameter: oldRegroupDiameter,
      diameterRanges: currentRanges
    }) => {
      const regroupDiameter = typeof newRegroupDiameter !== 'undefined' ? !!newRegroupDiameter : oldRegroupDiameter
      let openCovariateId

      if (newCovariateId === null) {
        openCovariateId = null
      } else if (newCovariateId === currentCovariateId) {
        openCovariateId = null
      } else {
        openCovariateId = newCovariateId || currentCovariateId
      }

      const diameterRanges = newRanges || currentRanges
      let iframeSrc

      const diameterCovariateId = get(find(covariates, ({ is_diameter }) => is_diameter), 'id')

      if (
        diameterCovariateId && regroupDiameter && diameterRanges
        && (diameterCovariateId === openCovariateId)
      ) {
        iframeSrc = this.getIframeSrcForDiameter(diameterRanges)
      } else {
        iframeSrc = this.getIframeSrc(openCovariateId)
      }

      return {
        openCovariateId,
        regroupDiameter,
        diameterRanges,
        iframeSrc,
        iframeLoading: iframeSrc !== currentIframeSrc
      }
    }
  )

  openCovariate = (covariate_id) => this.setStateWithIframe({ covariate_id })
  changeDiameterRanges = (diameterRanges) => this.setStateWithIframe({ diameterRanges })
  changeRegroupDiameter = (regroupDiameter) => this.setStateWithIframe({ regroupDiameter })

  continue = (covariateId, add) => {
    this.setState(({ covariates, regroupDiameter }) => ({
      covariates: map(covariates, (covariate) => {
        if (covariate.id !== covariateId) {
          return covariate
        }

        return {
          ...covariate,
          selected: !!add
        }
      })
    }), () => {
      const currentCovariateIndex = findIndex(this.state.covariates, ({ id }) => id === covariateId)
      const nextCovariate = this.state.covariates[currentCovariateIndex + 1]

      if (nextCovariate) {
        this.openCovariate(nextCovariate.id)
      } else {
        this.openCovariate(null)
      }
    })
  }

  render() {
    const {
      iframeSrc, iframeLoading,
      covariates,
      openCovariateId,
      regroupDiameter,
      diameterRanges
    } = this.state

    return (
      <CalibrationSubmitter>{ ({ Form, submitting }) => (
        <div className="calibration-columns">
          <div className="calibration-chart">
            { iframeSrc && (
              <iframe
                title="Chart"
                src={ iframeSrc }
                className={ c(
                  'calibration-regroup-mat-iframe',
                  iframeLoading && 'calibration-regroup-mat-iframe--loading'
                ) }
                onLoad={ this.iframeLoaded }
              />
            )}
          </div>
          <div className="calibration-content">
            <div className="calibration-info">
              <div className="calibration-variables-list">
                { map(covariates, (covariate) => (
                  <Covariate
                    key={ covariate.id }
                    covariate={ covariate }
                    open={ openCovariateId === covariate.id }
                    onClick={ this.openCovariate }
                    continue={ this.continue }
                    regroupDiameter={ regroupDiameter }
                    diameterRanges={ diameterRanges }
                    changeRegroupDiameter={ this.changeRegroupDiameter }
                    changeDiameterRanges={ this.changeDiameterRanges }
                  />
                ))}
              </div>
            </div>
            <div className="calibration-submit">
              { openCovariateId === null && (
                <Form>
                  { map(covariates, ({ id, selected }) => (
                    (selected && <input key={ id } type="hidden" name="complete[selected_ids][]" value={ id } />)
                  ))}

                  { regroupDiameter && diameterRanges && (
                    <input type="hidden" name="complete[diameter_ranges]" value={ JSON.stringify(diameterRanges) } />
                  ) }

                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={ submitting }
                  >Continue</button>
                </Form>
              )}
            </div>
          </div>
        </div>
      )}</CalibrationSubmitter>
    )
  }
}
