import { last, dropRight } from 'lodash'

export const joinWithAnd = (array) => {
  if (array.length < 2) return array

  const lastElement = last(array)
  const rest = dropRight(array, 1)

  return `${rest.join(', ')} and ${lastElement}`
}
