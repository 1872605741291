import React from 'react'
import memoize from 'lru-memoize'
import {
  get, map, transform, isEmpty, compact
} from 'lodash'

import Select from './Select'

const getCohortOptions = memoize(1)((cohorts) => [
  ['None (download everything)', ''],
  ...map(cohorts, ({ id, name }) => ([name, id]))
])

class CalibrationNotes extends React.Component {
  render() {
    const { notes, covariates } = this.props

    const variables = (
      notes.recommended_covariates
      && compact(map(notes.recommended_covariates, (id) => covariates[id]))
    )

    return (
      <div className="alert alert-secondary">
        <h5 className="mb-4">Modeling recommendations</h5>
        { notes.calibration_period && (
          <p>
            <strong>Validation structure</strong>:&nbsp;
            { notes.calibration_period && <span>Validation { notes.calibration_period.join('–') }</span> };&nbsp;
            { notes.validation_period && <span>Calibration { notes.validation_period.join('–') }</span> }
          </p>
        )}
        { variables && !!variables.length && (
          <>
            <p><strong>Variables</strong> (to be added using +Add button):</p>
            <ul>
              { map(variables, (variable) => <li>{ variable }</li>) }
            </ul>
          </>
        )}
      </div>
    )
  }
}

export default class CassesInputFilesForm extends React.Component {
  state = {
    selectedCohort: this.props.selected_cohort,
    cohorts: transform(this.props.cohorts, (result, [id, name, notes]) => {
      result[id] = { id, name, notes } // eslint-disable-line no-param-reassign
    }, {})
  }

  onCohortChange = (cohortId) => this.setState({ selectedCohort: cohortId })

  render() {
    const { covariates } = this.props
    const { cohorts, selectedCohort } = this.state

    const cohortOptions = getCohortOptions(cohorts)

    const calibrationNotes = get(cohorts, [selectedCohort, 'notes'])

    return (
      <>
        <div className="form-group row mb-4">
          <label htmlFor="cohort_id" className="col-1 col-form-label">Cohort:</label>
          <Select
            name="cohort_id"
            options={ cohortOptions }
            value={ selectedCohort }
            onChange={ this.onCohortChange }
            className="form-control col-11"
          />
        </div>
        <div className="row">
          <div className="col-6">
            { calibrationNotes && !isEmpty(calibrationNotes) && (
              <CalibrationNotes
                notes={ calibrationNotes }
                covariates={ covariates }
              />
            )}
          </div>
        </div>
      </>
    )
  }
}
  /*
  <div class="cohort-calibration-notes-container">
    <% covariates_map = @project.covariates.pluck(:id, :name).to_h %>

    <% @project.cohorts.each do |cohort| %>
      <div class="cohort-calibration-notes" id="cohort-calibration-notes-<%= cohort.id %>">
        <% if cohort.calibration_notes['calibration_period'] %>
        <% end %>
        <% if cohort.calibration_notes['validation_period'] %>
          <p>Recommended validation period: <%= cohort.calibration_notes['validation_period'].join('–') %>
        <% end %>
        <% recommended_covariates = cohort.calibration_notes['recommended_covariates']
                                          &.map { |id| covariates_map[id] }
                                          &.select(&:present?) %>
        <% if recommended_covariates&.any? %>
          <p>Recommended additional variables: <%= recommended_covariates.join(', ') %>
        <% end %>
      </div>
    <% end %>
  </div>

*/
