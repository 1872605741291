export const basicBtn = {
    cursor: 'pointer',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0.5rem 1rem 0.5rem 1rem',
    display: "flex",
    gap: '5px',
    background: '#ffffff',
}

export const basicBtnActive = {
    cursor: 'pointer',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0.5rem 1rem 0.5rem 1rem',
    display: "flex",
    gap: '5px',
    background: '#ffffff',
}