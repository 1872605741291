import React from 'react'
import {
  round, map, drop, findLastIndex
} from 'lodash'

const percent = (x) => round(parseFloat(x), 2)

export default class ValidationData extends React.PureComponent {
  state = {
    highlighted: null
  }

  highlightOver = (e) => {
    const highlighted = e.currentTarget.getAttribute('data-highlight')

    this.setState({ highlighted })
  }

  highlightOut = (e) => {
    const highlighted = e.currentTarget.getAttribute('data-highlight')

    this.setState(({ highlighted: currentHighlighted }) => {
      if (highlighted === currentHighlighted) {
        return { highlighted: null }
      }
    })
  }

  highlighter = (tag) => ({
    'data-highlight': tag,
    onMouseOver: this.highlightOver,
    onMouseOut: this.highlightOut,
    onFocus: this.highlightOver,
    onBlur: this.highlightOut,
    className: 'prediction-highlighter'
  })

  renderVersion1({
    example, PBN, ABN, Rn
  }) {
    return (
      <>
        <div className="prediction-model-paragraph">
          <p>
            The Predicted Breaks Number -PBN- during the period of validation
            is <strong>{ PBN }</strong>.
          </p>
          <p>The number of Actual Breaks is <strong>{ ABN }</strong>.</p>
          <p>The ratio Predicted/Actual is <strong>{ Rn }</strong>.</p>
          <p>
            Each pipe has a PBN for each year of the period of validation;
            we sum those PBN for each pipe.
            We then sort out the pipes with the highest sum of PBN first.
          </p>
        </div>

        <p>
          The table below indicates that if we were to replace the pipes that represent,
          for example&nbsp;
          <span { ...this.highlighter('An') }>{ example.An }% (last row, 1<sup>st</sup> column)</span>
          &nbsp;of the total number of pipes (sorted by higher PBN), then we would
          avoid&nbsp;
          <span { ...this.highlighter('Al') }>{ percent(example.Al) }% (2<sup>nd</sup> column)</span>
          &nbsp;of the actual total number of breaks that occurred during the period of validation.
        </p>
      </>
    )
  }

  renderVersion2({
    example, PBN, ABN, Rn
  }) {
    const modelFileName = this.props.validation_model.filename
    const validationPeriod = this.props.validation_model.validation_period.join('–')
    const calibrationPeriod = this.props.validation_model.calibration_period.join('–')

    return (
      <>
        <div className="prediction-model-paragraph">
          <p>
            A validation model <strong>{ modelFileName }</strong> has been created with a calibration period of {
              calibrationPeriod
            } and a validation period of {
              validationPeriod
            }, using breaks that occurred during the calibration period ({
              calibrationPeriod
            }). A Predicted Break Number (PBN) is automatically produced internally for each pipe
            for the validation period ({ validationPeriod }); The sum of the PBN of all the pipes is
            computed, and compared to the actual number of breaks that occurred during
            the validation period of { validationPeriod }.
          </p>
        </div>

        <p>The total PBN during the period of validation is <strong>{ PBN }</strong>.</p>
        <p>The actual breaks number is <strong>{ ABN }</strong>.</p>
        <p>The ratio Predicted/Actual is <strong>{ Rn }</strong>.</p>

        <p>
          The pipes are then sorted out with the higher { validationPeriod } PBNs first.
          The table below indicates that if, during the period of validation ({ validationPeriod }),
          we were to replace the pipes that represent, for example,&nbsp;
          <span { ...this.highlighter('An') }>{ example.An }%&nbsp;(table&apos;s last row, 1<sup>st</sup> column)</span>
          &nbsp;of the total number of pipes (sorted by higher PBN first), then we would avoid&nbsp;
          <span { ...this.highlighter('Al') }>{ percent(example.Al) }%&nbsp;(last row, 2<sup>nd</sup> column)</span>
          &nbsp;of the total number of actual breaks that occurred during
          that ({ validationPeriod }) period of validation;

          if we were to replace the pipes that represent, for example,&nbsp;
          <span { ...this.highlighter('An') }>{ example.An }%&nbsp;(table&apos;s last row, 1<sup>st</sup> column)</span>
          &nbsp;of the total length of pipes (sorted by higher PBN first), then we would avoid&nbsp;
          <span { ...this.highlighter('PBN') }>{ percent(example.PBN) }%&nbsp;(last row, 3<sup>nd</sup> column)</span>
          &nbsp;of the total number of actual breaks that occurred during
          that ({ validationPeriod }) period of validation.
        </p>
      </>
    )
  }

  render() {
    const {
      data,
      chart_by_number,
      chart_by_length,
      version,
    } = this.props
    const { highlighted } = this.state

    const dataWithoutHeader = drop(data, 3)
    const exampleIndex = findLastIndex(dataWithoutHeader, ({ An }) => !!An)
    const example = dataWithoutHeader[exampleIndex]

    const PBN = percent(data[0].PBN)
    const { ABN } = data[0]
    const Rn = percent(data[0].Rn)

    return (
      <div>
        { version === 1 && this.renderVersion1({
          example, PBN, ABN, Rn
        }) }
        { version === 2 && this.renderVersion2({
          example, PBN, ABN, Rn
        }) }
        <table>
          <thead>
            <tr>
              <th>% of pipes replaced</th>
              <th>% of breaks avoided if % of pipes replaced is based on the number of pipes</th>
              <th>% of breaks avoided if % of pipes replaced is based on the length of pipes</th>
            </tr>
          </thead>
          <tbody>{
            map(dataWithoutHeader, (row, index) => {
              const An = parseFloat(row.An)

              if (Number.isNaN(An)) { return null }

              return (
                <tr key={ An }>
                  <td
                    className={
                      index === exampleIndex && highlighted === 'An' ? 'prediction-highlighted' : null
                    }
                  >{ round(An, 2) }</td>
                  <td
                    className={
                      index === exampleIndex && highlighted === 'Al' ? 'prediction-highlighted' : null
                    }
                  >{ percent(row.Al) }</td>
                  <td
                    className={
                      index === exampleIndex && highlighted === 'PBN' ? 'prediction-highlighted' : null
                    }
                  >{ percent(row.PBN) }</td>
                </tr>
              )
            })
          }</tbody>
        </table>

        <div className="mt-5">
          <p>The values shown in the above tables are illustrated as follows:</p>
          { chart_by_number && (
            <p>
              (first Figure below) <span { ...this.highlighter('An') }>{ example.An }%</span> is read on the x-axis and&nbsp;
              <span { ...this.highlighter('Al') }>{ percent(example.Al) }%</span> is read on the y-axis of the red curve.
              The blue curve represents the corresponding % of the total number of predicted breaks
              avoided for <span { ...this.highlighter('An') }>{ example.An }%</span> of the number of pipes replaced.
            </p>
          )}
          { chart_by_length && (
            <p>
              ({ chart_by_number ? 'second' : 'first'} Figure below) <span { ...this.highlighter('An') }>{ example.An }%</span>
              &nbsp;is read on the x-axis and&nbsp;
              <span { ...this.highlighter('PBN') }>{ percent(example.PBN) }%&nbsp;(last row, 3<sup>rd</sup> column in above table</span>) is read on the y-axis of the red curve.
              The blue curve represents the corresponding % of the total number of predicted breaks
              avoided for <span { ...this.highlighter('An') }>{ example.An }%</span> of the length of pipes replaced.
            </p>
          )}
          { chart_by_number && (
            <div className="prediction-model-image mb-3">
              <img src={ chart_by_number } alt="by number" />
            </div>
          )}
          { chart_by_length && (
            <div className="prediction-model-image mb-3">
              <img src={ chart_by_length } alt="by length" />
            </div>
          )}
        </div>

        <p>
          Validation is considered to be better if the predicted/actual ratio
          is closer to 1; if the red curve (actual breaks) has a steep increase
          and is close to the blue curve (predicted breaks).
        </p>
      </div>
    )
  }
}
