import React from 'react'
import {
  map, uniq, includes, without
} from 'lodash'

export default class CohortsSelector extends React.Component {
  onChange = (e) => {
    const { checked } = e.currentTarget
    const currentValue = parseInt(e.currentTarget.value, 10)
    const value = this.props.value

    this.props.onChange(checked ? uniq([...value, currentValue]) : without(value, currentValue))
  }

  selectAll = () => {
    this.props.onChange(map(this.props.cohorts, 'id'))
  }

  clearAll = () => {
    this.props.onChange([])
  }

  render() {
    const {
      colors,
      cohorts,
      value
    } = this.props

    return (
      <div>
        <div>
          <div>
            Create and select cohorts in <a href="./predict">Predict</a> cohorts page <span style={{ whiteSpace: 'nowrap' }}>
              (<button
                type="button"
                className="btn btn-link"
                onClick={ this.selectAll }
              >Select all</button> • <button
                type="button"
                className="btn btn-link"
                onClick={ this.clearAll }
              >Clear all</button>)
            </span>
          </div>
        </div>
        <div>
          { map(cohorts, (cohort) => (
            <div key={ cohort.id } style={{ display: 'inline-block', width: '50%' }}>
              <label>
                <input
                  type="checkbox"
                  value={ cohort.id }
                  checked={ includes(value, cohort.id) }
                  onChange={ this.onChange }
                />&nbsp;
                <span
                  className="chart-tooltipContent-dot"
                  style={{
                    backgroundColor: (colors && colors[cohort.name]) || '#000'
                  }}
                />
                { cohort.name }
              </label>
            </div>
          )) }
        </div>
      </div>
    )
  }
}
