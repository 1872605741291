import React from 'react'
import { transform, filter, debounce } from 'lodash'
import memoize from 'lru-memoize'

import { withParentSize } from '@vx/responsive'

import Main from './Main'
import Brush from './Brush'

const BRUSH_HEIGHT = 150

const filterLineAndBar = memoize()((line, bar, xValues) => {
  if (!xValues) {
    return { line, bar }
  }

  const included = transform(
    xValues,
    (result, value) => { result[value] = true }, // eslint-disable-line no-param-reassign
    {}
  )

  return {
    line: {
      ...line,
      data: filter(line.data, ({ x }) => included[x])
    },
    bar: {
      ...bar,
      data: filter(bar.data, ({ x }) => included[x])
    }
  }
})

const MAIN_MARGIN = {
  left: 100,
  right: 80,
  top: 100,
  bottom: 70
}
const BRUSH_MARGIN = {
  left: 100,
  right: 80,
  top: 0,
  bottom: 40
}

class BarsAndLine extends React.PureComponent {
  state = {
    line: this.props.line,
    bar: this.props.bar
  }

  componentWillUnmount() {
    this._unmounted = true
  }

  componentWillReceiveProps(nextProps) {
    if (
      (
        nextProps.line !== this.props.line
        || nextProps.bar !== this.props.bar
      ) && !this._unmounted
    ) {
      this.setState({
        line: nextProps.line,
        bar: nextProps.bar
      })
    }
  }

  onBrushChange = (domain) => {
    const { line, bar } = this.props

    if (!this._unmounted) {
      this.setState(filterLineAndBar(line, bar, domain && domain.xValues))
    }
  }

  debouncedOnBrushChange = debounce(this.onBrushChange, 100)

  render() {
    const {
      parentHeight: height,
      line, bar,
      useBrush,
      bottomLabel,
      title
    } = this.props

    const { line: selectedLine, bar: selectedBar } = this.state

    if (!height || height < 150) return null

    return (
      <>
        <div style={{ height: useBrush ? (height - BRUSH_HEIGHT) : height }}>
          <Main
            line={ selectedLine }
            bar={ selectedBar }
            bottomLabel={ bottomLabel }
            margin={ MAIN_MARGIN }
            title={ title }
          />
        </div>
        { useBrush && (
          <div style={{ height: BRUSH_HEIGHT }}>
            <Brush
              line={ line }
              bar={ bar }
              onChange={ this.debouncedOnBrushChange }
              margin={ BRUSH_MARGIN }
            />
          </div>
        ) }
      </>
    )
  }
}

export default withParentSize(BarsAndLine)
