import React from 'react'
import Column from './Column'
import formatNumber, { formatPercent } from '../../utils/number'

export const loadingFunc = () => <span className="cohorts-numbers-loading">—</span>

export default class Cohort extends React.PureComponent {
  render() {
    let { numbers } = this.props
    const { highlightColumnProps: columnProps } = this.props
    const { loading } = this.props

    if (!numbers && loading) {
      numbers = {}
    }

    const render = loading ? loadingFunc : formatNumber
    const renderPercent = loading ? loadingFunc : formatPercent

    return (
      <div className="cohort-numbers">
        <Column { ...columnProps } name="length_all" wider>{ render(numbers.length_all, 1) }</Column>
        <Column { ...columnProps } name="length_act" wider>{ render(numbers.length_act, 1) }</Column>
        <Column { ...columnProps } name="length_abn" wider>{ render(numbers.length_abn, 1) }</Column>
        <Column { ...columnProps } name="abn_rate" wide>{ renderPercent(numbers.abn_rate, 1) }</Column>
        <Column { ...columnProps } name="aybr_all">{ render(numbers.aybr_all, 3) }</Column>
        <Column { ...columnProps } name="aybr_act">{ render(numbers.aybr_act, 3) }</Column>
        <Column { ...columnProps } name="aybr_abn">{ render(numbers.aybr_abn, 3) }</Column>
        <Column { ...columnProps } name="aybr_abn_all" wide>{ render(numbers.aybr_abn_all, 1) }</Column>
        <Column { ...columnProps } name="recent_break_rate" wide>{ render(numbers.recent_break_rate, 3) }</Column>
        <Column { ...columnProps } name="average_age_all">{ render(numbers.average_age_all, 1) }</Column>
        <Column { ...columnProps } name="average_age_act">{ render(numbers.average_age_act, 1) }</Column>
        <Column { ...columnProps } name="average_age_abn">{ render(numbers.average_age_abn, 1) }</Column>
      </div>
    )
  }
}
