import React, {
  useRef, useState, useEffect, useLayoutEffect
} from 'react'

const { $, ReactRailsUJS } = window

export default function Delayed({ className, url }) {
  const container = useRef()
  const [content, setContent] = useState(undefined)

  useEffect(() => {
    setTimeout(() => {
      $.get(url).then((result) => {
        setContent(result)
      })
    }, 10000)
  }, [])

  useLayoutEffect(() => {
    if (container.current) {
      ReactRailsUJS.mountComponents(container.current)
    }
  }, [content])

  if (!content) {
    return (
      <div className={ `delayed-loading ${className}` }>
        <i className="icon big spinner spin" />
      </div>
    )
  }

  return (
    <div
      ref={ container }
      dangerouslySetInnerHTML={{ __html: content }}
      className={ className }
    />
  )
}
