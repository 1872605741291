import React from 'react'
import { map } from 'lodash'
import CalibrationSubmitter from '../../Submitter'

import Base from './Base'
import Regrouping from './Grouping'
import Chart from '../../Chart'

const updateAt = (array, index, func) => (
  map(array, (value, i) => (
    index === i ? (
      func(value)
    ) : value
  ))
)

const deleteAt = (array, index) => array.slice(0, index).concat(array.slice(index + 1))

const IsSteady = ({ base_variable, yes, no }) => (
  <>
    <div className="calibration-info">
      <p>The Chart shows:</p>
      <ul>
        <li>X-axis: { base_variable.name }</li>
        <li>
          Left y-axis: Current Average Yearly Break Rate
        </li>
        <li>
          Right y-axis: Current length of pipes
        </li>
      </ul>

      <p>
        Does the Average Yearly Break Rate increase or decrease steadily
        (ignore off-trend modalities that have very small length)?
      </p>
    </div>
    <div className="calibration-submit">
      <div className="row calibration-variables-variable-buttonsRow">
        <div className="col-6">
          <button
            type="button"
            className="btn btn-primary"
            onClick={ yes }
          >Yes</button>
        </div>
        <div className="col-6">
          <button
            type="button"
            className="btn btn-primary"
            onClick={ no }
          >No</button>
        </div>
      </div>
    </div>
  </>
)

export default class Qualitative extends React.Component {
  state = {
    is_steady: undefined,
    regrouped_variables: [],
    open_variable_index: null
  }

  openRegrouping = (index) => this.setState({ open_variable_index: index })

  selectedSteady = () => this.setState({ is_steady: true })

  selectedUnsteady = () => this.setState({ is_steady: false })

  createRegrouping = () => this.setState(({ regrouped_variables }) => ({
    regrouped_variables: [...regrouped_variables, { groups: [] }],
    open_variable_index: regrouped_variables.length
  }))

  onGroupingChange = (index, groups) => this.setState(({ regrouped_variables }) => ({
    regrouped_variables: updateAt(regrouped_variables, index, (variable) => ({
      ...variable,
      groups
    }))
  }))

  saveGrouping = () => this.setState({ open_variable_index: null })

  cancelGrouping = (index) => this.setState(({ regrouped_variables }) => ({
    regrouped_variables: deleteAt(regrouped_variables, index),
    open_variable_index: null
  }))

  render() {
    const {
      chart_url,
      run_tests_url,
      base_variable,
      label
    } = this.props

    const {
      is_steady,
      regrouped_variables,
      open_variable_index
    } = this.state

    let content = null

    const open_variable = (open_variable_index !== null) && regrouped_variables[open_variable_index]

    return (
      <CalibrationSubmitter>{ ({ Form, submitting }) => {
        if (is_steady === undefined) {
          content = (
            <IsSteady
              base_variable={ base_variable }
              yes={ this.selectedSteady }
              no={ this.selectedUnsteady }
            />
          )
        } else if (open_variable) {
          content = (
            <Regrouping
              label={ label }
              base_variable={ base_variable }
              run_tests_url={ run_tests_url }
              index={ open_variable_index }
              onChange={ this.onGroupingChange }
              save={ this.saveGrouping }
              cancel={ this.cancelGrouping }
              groups={ open_variable.groups }
              form={ Form }
              submitting={ submitting }
            />
          )
        } else {
          content = (
            <Base
              base_variable={ base_variable }
              regrouped_variables={ regrouped_variables }
              is_steady={ is_steady }
              createRegrouping={ this.createRegrouping }
              openRegrouping={ this.openRegrouping }
              form={ Form }
              submitting={ submitting }
            />
          )
        }

        return (
          <div className="calibration-columns">
            <div className="calibration-chart">
              <Chart
                base_url={ chart_url }
                covariate_id={ base_variable.id }
                groups={ open_variable ? open_variable.groups : undefined }
              />
            </div>
            <div className="calibration-content">
              { content }
            </div>
          </div>
        )
      }}</CalibrationSubmitter>
    )
  }
}
