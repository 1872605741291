import React, { useEffect, useState } from "react";
import AddUserModal from "./AddUserModal";

const fetchPermissions = async () => {
    return Api.get('permissions');
};

const Permission = ({ permission, open, setOpen, users = [] }) => {
    const [_open, _setOpen] = useState(open);
    const [addUserModalOpen, setAddUserModalOpen] = useState(false);

    const toggle = () => {
        _setOpen(prevState => {
            setOpen(!prevState);
            return !prevState;
        });
    };

    const handleDeletion = (permissionId) => {
        Api.delete(`permissions/${permissionId}`).then(() =>
            alert("Permission successfully deleted")
        );
    };

    return (
        <div className="card p-3 mb-3 shadow-sm border-1 rounded-5">
            <div className="d-flex justify-content-between align-items-center">
                <h5 className="mb-0">{permission.projectName}</h5>
                <div className="d-flex gap-2" style={{gap: '4px'}}>
                    <button className="btn btn-light btn-sm" onClick={toggle}>
                        {_open ? <i className="fa-solid fa-chevron-up"></i> : <i className="fa-solid fa-chevron-down"></i>}
                    </button>
                    <button className="btn btn-light btn-sm" onClick={() => setAddUserModalOpen(!addUserModalOpen)}>
                        <i className="fa-solid fa-user-plus"></i> Add User
                    </button>
                </div>
            </div>

            {addUserModalOpen && (
                <AddUserModal close={() => setAddUserModalOpen(false)} users={users} projectId={permission.projectId} />
            )}

            {_open && (
                <table className="table mt-3 table-hover table-striped table-bordered">
                    <thead className="table-light">
                    <tr>
                        <th>User</th>
                        <th>User Status</th>
                        <th className="text-center">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {permission.users.map(user => (
                        <tr key={user.id}>
                            <td>{user.full_name}</td>
                            <td>{user.status}</td>
                            <td className="text-center">
                                <button
                                    onClick={() => handleDeletion(user.permission_id)}
                                    className="btn btn-danger btn-sm"
                                >
                                    <i className="fa-solid fa-trash"></i> Remove
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

const Permissions = () => {
    const [permissions, setPermissions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filter, setFilter] = useState('');
    const [users, setUsers] = useState([]);

    useEffect(() => {
        fetchPermissions()
            .then((r) => {
                console.log(r);
                setPermissions(r);
                setLoading(false);
            })
            .catch((error) => {
                setError("Error fetching permissions: " + error);
                console.error("Error fetching permissions: " + error);
            })
            .finally(() => setLoading(false));

        Api.get('users')
            .then((r) => {
                console.log(r);
                setUsers(r);
            })
            .catch((error) => {
                console.error("Error fetching users: " + error);
            });
    }, []);

    if (loading) {
        return <div>Loading list of permissions...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div>
            <input
                type="text"
                className="form-control my-3"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                placeholder="Filter by project name"
                style={{ padding: '12px', borderRadius: '10px' }}
            />
            <div className="d-flex flex-column gap-2">
                {permissions
                    .filter((permission) => permission.projectName.toLowerCase().includes(filter.toLowerCase()))
                    .map((permission) => (
                        <Permission permission={permission} users={users} setOpen={() => {}} open={false} />
                    ))}
            </div>
        </div>
    );
};

export default Permissions;
