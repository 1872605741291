import React from 'react'

export default (props) => {
    const { title, description, children } = props;
    return <>
        <h4>{title}</h4>
        <small><i>{description}</i></small>
        <br /><br/>
        {children}
        <hr />
    </>
}