import React, { useState, useEffect } from 'react';

const NewFilter = ({ projectId }) => {
    const fetchFilterData = () => ({
        name: '',
        active: false,
        initial: false,
        current: false,
        predicates: [],
        target: 'example_target',
        builtin_method: null,
    });

    const [formState, setFormState] = useState({
        name: '',
        active: false,
        initial: false,
        current: false,
        predicates: [],
        target: '',
    });

    useEffect(() => {
        const filterData = fetchFilterData();
        setFormState(filterData);
    }, []);

    const handleSubmit = (formData) => {
        console.log('Form data submitted:', formData);
        // TODO: Add form submission logic
    };

    const handleChange = (e) => {
        const { name, type, checked, value } = e.target;
        setFormState((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        handleSubmit(formState);
    };

    const isNewRecord = !formState.id;
    const page = formState.target === 'breaks' ? 'filters_breaks' : 'filters';

    return (
        <form
            id="filter-form"
            className="with-query-predicates with-progress"
            onSubmit={handleFormSubmit}
        >
            {/* Hidden field for target */}
            <input type="hidden" name="target" value={formState.target} />

            <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                    type="text"
                    name="name"
                    className="form-control"
                    value={formState.name}
                    onChange={handleChange}
                />
            </div>

            <div className="form-check">
                <input
                    type="checkbox"
                    name="active"
                    className="form-check-input"
                    checked={formState.active}
                    onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="active">
                    Active
                </label>
            </div>

            <div className="d-flex">
                <div className="form-check">
                    <input
                        type="checkbox"
                        name="initial"
                        className="form-check-input"
                        checked={formState.initial}
                        onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="initial">
                        Initial
                    </label>
                </div>

                <div className="form-check ml-3">
                    <input
                        type="checkbox"
                        name="current"
                        className="form-check-input"
                        checked={formState.current}
                        onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="current">
                        Current
                    </label>
                </div>
            </div>

            {formState.builtin_method ? (
                <>
                    <h4>Builtin Operation</h4>
                    <p>This filter uses a builtin operation to match records. It cannot be edited.</p>
                </>
            ) : (
                <>
                    <input
                        type="hidden"
                        name="predicates"
                        value={JSON.stringify(formState.predicates)}
                    />

                    <div className="mt-4">

                        <p>Query Builder Placeholder for page: {page}</p>
                    </div>
                </>
            )}

            <div className="actions">
                <button type="submit" className="infraplan_button_small">
                    Save
                </button>
                <a
                    href={isNewRecord ? `/projects/${projectId}/summary` : `/projects/${projectId}/filters/${formState.id}`}
                    className="btn btn-secondary"
                >
                    Cancel
                </a>
            </div>
        </form>
    );
};

export default NewFilter;
