import React, { useRef } from 'react'

import CohortsSelector from './CohortsSelector'
import PlanInput from './PlanInput'
import LimitationsInput from './LimitationsInput'
import ToggleableNumberInput from '../ToggleableNumberInput'

import CSVReader from 'react-csv-reader'

const isNullOrUndef = (x)=>(typeof x === "undefined" || x === null);

// TODO: this component is a complete mess and needs a complete rewrite when refactoring
export default ({
  scenario, onChange, cohorts, colors
}) => {
  const limitationsRef = useRef(null)

  const setCohortIds = (value) => onChange({ ...scenario, cohort_ids: value.sort() })
  const setMabr = (value) => onChange({ ...scenario, mabr: value })
  const setHorizon = (value) => onChange({ ...scenario, horizon: value })
  const setLimitations = (value) => onChange({ ...scenario, limitations: value })
  const setSmoothen = (value) => onChange({ ...scenario, smoothen: value })
  const setReplacement = (value) => onChange({ ...scenario, replacement: value })
  const setSelection = (value) => onChange({ ...scenario, selection: value })
  const setActiveConstrains = (value) => onChange({ ...scenario, active_constraints: value })
  const setMaxLengthFirstYear = (value) => onChange({ ...scenario, max_length_first_year: value })
  const setYearSteadyState = (value) => onChange({ ...scenario, year_steady_state: value })
  const setRandomness = (value) => onChange({ ...scenario, randomness: value })
  const setSchedule = (data, fileInfo) => onChange({ ...scenario, replacement_schedule: data })
  const setVirtualAgingCoefficient = (data) => onChange({ ...scenario, virtual_aging_coefficient: data })
  const setVirtualP0 = (data) => onChange({ ...scenario, virtual_p0: data })
  const removeSchedule = () => {
    clearCSVReader()
    onChange({ ...scenario, replacement_schedule: null })
  }

  const clearCSVReader = () => {
    // apparently there's not better builtin way to do this; might consider changing packages later
    if(document.querySelector('.csv-input'))
      document.querySelector('.csv-input').value = ''
  }

  const setLimitationsExample = () => {
    if (limitationsRef.current) {
      limitationsRef.current.setExample()
    }
  }

  if (!scenario) { return null }

  const {
    cohort_ids,
    mabr,
    horizon,
    limitations,
    smoothen,
    selection,
    replacement,
    active_constraints,
    max_length_first_year,
    year_steady_state,
    randomness,
    replacement_schedule,
    virtual_p0,
    virtual_aging_coefficient
  } = scenario

  let replacement_schedule_length, break_at_120 = "0.15"

  if (virtual_p0 && virtual_aging_coefficient) {
    break_at_120 = (virtual_p0 * virtual_aging_coefficient ** 120).toFixed(3)
  } else if (virtual_p0 || virtual_aging_coefficient) {
    break_at_120 = "-"
  }

  if (!replacement_schedule || Object.keys(replacement_schedule).length === 0) {
    clearCSVReader()
  } else {
    if (replacement_schedule.length) {
      replacement_schedule_length = replacement_schedule.length
    }
    // either ruby or psql is apparently very strict regarding JSON and converts an array to an object with keys 0...n
    else {
      replacement_schedule_length = Math.max(...Object.keys(replacement_schedule)) + 1
    }
  }

  return (
    <div className="plan-scenario">
      <section className="plan-scenario-cohorts plan-panel" style={{
        display: "flex",
        flexDirection: "column"
      }}>
      <div style={{flexBasis: "0px",
        flexGrow: "1",
        overflowY: "auto"}}>
        <div className={"head"}>
          Cohorts selection
        </div>
        <div className={"body"}>
          <div className="form-group">
            <CohortsSelector
                value={ cohort_ids }
                onChange={ setCohortIds }
                cohorts={ cohorts }
                colors={ colors }
            />
          </div>
        </div>
      </div>
      </section>
      <section className="plan-scenario-constraints plan-panel" style={{
        display: "flex",
        flexDirection: "column"
      }}>
      <div >
        <div className={"head"}>Scenario configuration</div>
        <div className={"body"}>
        <div className={"row"}>
          <div className={"col-md-3"}>
            <h5>Strategy</h5>
            Replacement<br/>
            {[
              { label: "Remaining pipes", value: "false", default: true },
              { label: "Remaining + new pipes", value: "true", default: false }
            ].map((v,i) =>
                <>
                  <input type="radio" name={"replacement"} checked={(v.default && isNullOrUndef(replacement)) || replacement === v.value} onChange={()=>{setReplacement(v.value);}} />&nbsp;
                  <label htmlFor={v.value.toString()}>{v.label}</label>&nbsp;<br/>
                </>
            )}

            Selection
            <br/>

            {[
              { label: <>PBR first&nbsp;
                  <span title="Predicted Break Rate" style={{borderRadius:"100%", backgroundColor:"black", color:"white", paddingLeft: "0.25em", paddingRight: "0.25em", fontSize: "xx-small"}}>?</span>
                </>,
                value: "LOF", default: true },
              { label: <>PBN first&nbsp;
                <span title="Predicted Break Number" style={{borderRadius:"100%", backgroundColor:"black", color:"white", paddingLeft: "0.25em", paddingRight: "0.25em", fontSize: "xx-small"}}>?</span>
              </>, value: "pbn", default: false },
              { label: <>BRE first&nbsp;
                <span title="Business Risk Exposure" style={{borderRadius:"100%", backgroundColor:"black", color:"white", paddingLeft: "0.25em", paddingRight: "0.25em", fontSize: "xx-small"}}>?</span>
                </>, value: "BRE", default: false },
            ].map((v,i) =>
                <>
                  <input type="radio" name={"selection"} checked={(v.default && isNullOrUndef(selection)) || selection === v.value} onChange={()=>{setSelection(v.value);}} />&nbsp;
                  <label htmlFor={v.value.toString()}>{v.label}</label>&nbsp;<br/>
                </>
            )}
          </div>

          <div className={"col-md-3"}>
            <h5>Constraints</h5>
            <div>
              <input name="active_constraints"
                       type="radio"
                       checked={isNullOrUndef(active_constraints) || active_constraints==="length"}
                       onClick={()=>{setActiveConstrains("length")}} /> Length
              <span style={{width: "5em"}}>&nbsp;</span>
              <input name="active_constraints"
                     type="radio"
                     style={{marginLeft: "2em"}}
                     checked={active_constraints==="break_rate"}
                     onClick={()=>{setActiveConstrains("break_rate")}}/> Break Rate
            </div>
            <br />
            <div className="form-group" style={{display: (isNullOrUndef(active_constraints) || active_constraints==="length")?"inherit":"none"}}>
              <label htmlFor="limitations_input">
                Schedule of Replacement
                <br />
                <a
                  style={{"marginDown": "2em"}}
                  href="javascript:void"
                  onClick={ setLimitationsExample }
                 >(See example)</a>
              </label>
              <LimitationsInput
                  ref={ limitationsRef }
                  value={ limitations }
                  onChange={ setLimitations }
                  cohorts={ cohorts }
                  htmlId="limitations_input"
              />
            </div>
            <div className="form-group" style={{display: active_constraints==="break_rate"?"inherit":"none"}}>
              <div className={"row"}>
                <div style={{padding:"0.25em"}} className={"col-md-4"}>
                  <label htmlFor="mabr_input">Max Break Rate <small>(Nb Breaks/mi/yr)</small></label>
                </div>
                <div style={{padding:"0.25em"}} className={"col-md-8"}>
                  <PlanInput
                      value={ mabr }
                      onChange={ setMabr }
                      htmlId="mabr_input"
                      type="number"
                      min="0"
                      step="0.1"
                  />
                </div>
                <div style={{padding:"0.25em"}} className={"col-md-4"}>
                  <label htmlFor="max_length_first_year_input">R&R length first year<br/></label>
                </div>
                <div style={{padding:"0.25em"}} className={"col-md-8"}>
                  <PlanInput
                      value={ max_length_first_year }
                      onChange={ setMaxLengthFirstYear }
                      htmlId="max_length_first_year_input"
                      type="number"
                      min="0"
                      step="0.1"
                  />
                </div>
                <div style={{padding:"0.25em"}} className={"col-md-4"}>
                  <label htmlFor="year_steady_state_input">Year steady state<br/></label>
                </div>
                <div style={{padding:"0.25em"}} className={"col-md-8"}>
                  <PlanInput
                      value={ year_steady_state }
                      onChange={ setYearSteadyState }
                      htmlId="year_steady_state_input"
                      type="number"
                      min="0"
                      step="0.1"
                  />
                </div>
              </div>
            </div>

          </div>

          <div className="col-md-4">
            <h5>Replacement Schedule <span title="Upload an existing replacement schedule. Accepts a CSV file with Pipe and Year headers, where Pipe is the ID of the pipe and Year is the year of scheduled replacement." style={{borderRadius:"100%", backgroundColor:"black", color:"white", paddingLeft: "0.25em", paddingRight: "0.25em", fontSize: "xx-small"}}>?</span></h5>
            <div className="form-group">
              <CSVReader
                style={{"display": "inline"}}
                onFileLoaded={ setSchedule }
                inputId="schedule_input"
                parserOptions={{ header: true, skipEmptyLines: true }}
              />
              { !replacement_schedule_length && <span>No replacement schedule loaded.</span>}
              { replacement_schedule_length && <span>{replacement_schedule_length} pipes for replacement. <a href="#" onClick={ removeSchedule }>Remove schedule.</a></span> }
            </div>
            <div>
              <h5>Replacement Pipes</h5>
                <div className="form-group">
                  <div className={"row"}>
                    <div style={{padding:"0.25em"}} className={"col-md-4"}>
                      <label htmlFor="virtual_aging_coefficient">Aging coefficient<br /></label>
                    </div>
                    <div style={{padding:"0.25em"}} className={"col-md-8"}>
                      <PlanInput
                          value={ virtual_aging_coefficient }
                          onChange={ setVirtualAgingCoefficient }
                          htmlId="virtual_aging_coefficient"
                          type="number"
                          min="1"
                          step="0.001"
                          placeholder="1.0365"
                      />
                    </div>
                    <div style={{padding:"0.25em"}} className={"col-md-4"}>
                      <label htmlFor="virtual_p0">Initial break rate<br/></label>
                    </div>
                    <div style={{padding:"0.25em"}} className={"col-md-8"}>
                      <PlanInput
                          value={ virtual_p0 }
                          onChange={ setVirtualP0 }
                          htmlId="virtual_p0"
                          type="number"
                          min="0.0001"
                          step="0.0001"
                          placeholder="0.002"
                      />
                    </div>
                    <div style={{padding:"0.25em"}} className={"col-md-4"}>
                      <label htmlFor="break_at_120">Break rate at 120 years of age<br/></label>
                    </div>
                    <div style={{padding:"0.25em"}} className={"col-md-8"}>
                      <input
                        className="form-control"
                        value={ break_at_120 }
                        htmlId="break_at_120"
                        disabled
                      />
                    </div>
                </div>
            </div>
            </div>
          </div>

          <div className={"col-md-2"}>
            <h5>Conditions</h5>

            <ToggleableNumberInput
                value={ smoothen }
                onChange={ setSmoothen }
            />
            <div className="form-group">
              <label htmlFor="horizon_input">Last year of planning horizon</label>
              <PlanInput
                  value={ horizon }
                  onChange={ setHorizon }
                  htmlId="horizon_input"
                  type="number"
                  min="0"
                  step="1"
              />
            </div>
            <div className="form-group">
              <label htmlFor="randomness_input">Random Replacement Ratio</label>
              <PlanInput
                  value={ randomness }
                  onChange={ setRandomness }
                  htmlId="randomness_input"
                  type="number"
                  min="0.0"
                  step="0.01"
                  max="1.0"
              />
            </div>

          </div>

        </div>


        </div>

      </div>
      </section>
    </div>
  )
}
