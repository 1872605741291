import React from 'react'
import ReactModal from 'react-modal'

ReactModal.setAppElement('#top')

export default class Modal extends React.Component {
  render() {
    const {
      children, ...props
    } = this.props

    return (
      <ReactModal
        { ...props }
        className="reactModal-modal"
        overlayClassName="reactModal-overlay"
        bodyOpenClassName="reactModal-bodyOpen"
        closeTimeoutMS={ 200 }
      ><div className="reactModal-inner">{ children }</div></ReactModal>
    )
    /*
     * react modal focuses modal on open,
     * if modal is taller than screen, it appears scrolled
     * to the middle.
     * to avoid that, we limit height of the modal in .modal
     * and show actual content in .inner
     */
  }
}
