import React from 'react'
import NumberInput from "../Misc/numberInput";

export default ({title, dist, setDist}) => {

    const updateLine = (index, prop) => (value) => setDist(current => {
        let newDist = [...current];
        newDist[index][prop] = value;
        return newDist;
    });

    const addLine = (initial) => () => setDist(current => {
        let newDist = [...current];
        newDist.push(initial);
        return newDist;
    });

    const removeLine = (index) => () => setDist(current => {
        return current.filter((_, i) => i !== index);
    });

    return <>
        <h5>{title} distribution</h5>

        <table style={{width: "auto"}}>
        <thead>
        <tr>
            <th>Min value</th>
            <th>Max value</th>
            <th>Prob (%)</th>
            <th style={{width: "1px"}}></th>
        </tr>
        </thead>

        <tbody>
        {
            dist.map((line, index) => <tr key={index}>
                <td><NumberInput min={0} max={10000000000} initial={line.min} onValue={updateLine(index, "min")} /></td>
                <td><NumberInput min={0} max={10000000000} initial={line.max} onValue={updateLine(index, "max")} /></td>
                <td><NumberInput style={{ width: "4em" }} min={0} max={100} step={0.1} initial={line.prob} onValue={updateLine(index, "prob")} />%</td>
                <td><i style={{cursor: "pointer"}} className='fa-solid fa-remove' onClick={removeLine(index)}></i></td>
            </tr>)
        }
        </tbody>
        </table>

        <button className={"btn btn-secondary infraplan_button_grey"} onClick={addLine({min: 0, max: 0, prob: 0})}>
            <i className="fa-solid fa-plus"></i> Add
        </button>
        <br/>
    </>;

}