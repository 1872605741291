import React, {useEffect, useState} from 'react';
import {GoogleAuthProvider, signInWithPopup} from "firebase/auth";
import {auth} from "../../utils/firebase";

const Register = () => {
    const [formData, setFormData] = useState({
        givenName: '',
        familyName: '',
        email: '',
        password: '',
        confirmPassword: '',
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [googleProvider, setGoogleProvider] = useState(null);
    const [canSubmit, setCanSubmit] = useState(false);
    const [restrictAccess, setRestrictAccess] = useState(true);
    const [restrictToken, setRestrictToken] = useState('');
    const [isTokenInvalid, setIsTokenInvalid] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        document.title = 'Create Account | InfraSOFT';
        // Checks if the tokens has been given in the url
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        if (token) {
            setRestrictToken(token);
            tryValidateAccess(token).then(() => {
                setRestrictAccess(false);
            }).catch(() => {
                setIsTokenInvalid(true);
            });
        }
    }, []);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleGoogleLogin = async (e) => {
        e.preventDefault();

        try {
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(auth, provider);
            setGoogleProvider(result);
            console.log(result);
        } catch (error) {
            console.error(error);
        }
    }

    const tryValidateAccess = async (token) => {
        const response = await Api.post('users/register/validate-access', {
            token: token
        })

        if (response.message === 'Valid token') {
            setRestrictAccess(false);
        } else {
            toastr.error('Invalid token');
            setIsTokenInvalid(true)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.password !== formData.confirmPassword && !googleProvider) {
            setErrorMessage('Passwords do not match');
            return;
        }

        if (googleProvider) {
            Api.post('users/with-google', {
                email: googleProvider.user.email,
                uid: googleProvider.user.uid,
                givenName: formData.givenName,
                familyName: formData.familyName,
            })
                .then((response) => {
                    console.log(response);
                    setIsSubmitting(false);
                    setErrorMessage('');
                    window.location.href = '/';
                })
                .catch((error) => {
                    console.error(error);
                    setIsSubmitting(false);
                    setErrorMessage('An error occurred. Please try again later.');
                });
            return;
        }

        Api.post('users/new-version', {
            givenName: formData.givenName,
            familyName: formData.familyName,
            email: formData.email,
            password: formData.password,
        })
            .then((response) => {
                console.log(response);
                setIsSubmitting(false);
                setErrorMessage('');
                window.location.href = '/';
            })
            .catch((error) => {
                console.error(error);
                setIsSubmitting(false);
                setErrorMessage('An error occurred. Please try again later.');
            });
    }

    const centerLoginStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '60px',
    };

    const cardStyle = {
        padding: '2rem',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        width: '600px',
        backgroundColor: '#fff',
    };

    const sectionHeaderStyle = {
        fontSize: '1.2rem',
        fontWeight: '600',
        marginBottom: '1rem',
        color: '#333',
    };

    const dividerStyle = {
        margin: '20px 0',
        borderRight: '1px solid #ddd',
        textAlign: 'center',
        color: '#aaa',
    };

    const orTextStyle = {
        backgroundColor: '#fff',
        padding: '0 10px',
        position: 'relative',
        top: '-13px',
    };

    useEffect(() => {
        const personalDetails = formData.givenName && formData.familyName;
        const loginDetails = formData.email && formData.password && formData.confirmPassword;
        const passwordMatch = formData.password === formData.confirmPassword;
        setCanSubmit(
            (personalDetails && loginDetails && passwordMatch) || (personalDetails && googleProvider)
        )
    }, [formData, googleProvider])

    const handleTokenSubmit = async (e) => {
        e.preventDefault()
        try {
            await tryValidateAccess(restrictToken)
        } catch (error) {
            console.error(error);
            toastr.error(error.message);
            setIsTokenInvalid(true)
        }

    }

    if (restrictAccess) {
        return (
            <div style={centerLoginStyle}>
                <div style={cardStyle}>
                    <h1 className="text-center mb-4">Unlock Register Page</h1>
                    <div className="form-group">
                        <label>Token</label>
                        <textarea
                            name="token"
                            aria-multiline={true}
                            rows={3}
                            style={{resize: 'none'}}
                            className={`form-control ${isTokenInvalid ? 'error' : ''}`}
                            value={restrictToken}
                            onChange={(e) => setRestrictToken(e.target.value)}
                            required
                        />
                </div>
                {isTokenInvalid && (
                    <div className="alert alert-danger mt-3" role="alert">
                        Invalid token
                    </div>
                    )}
                <button
                    type="submit"
                    onClick={handleTokenSubmit}
                    className={`btn btn-primary w-100 mt-3`}
                >
                    Unlock
                </button>
            </div>
            </div>
        )
    }

    return (
        <div style={centerLoginStyle}>
            <div style={cardStyle}>
                <h1 className="text-center mb-4">Create Your Account</h1>

                {/* Name Fields Section */}
                <div>
                    <div className="d-flex" style={{alignItems: 'baseline'}}>
                        <div style={sectionHeaderStyle}>1. Your Personal Details</div>
                        {formData.givenName && formData.familyName && <i

                            className="fa-solid fa-circle-check ml-2" style={{color: 'green'}}></i>}
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Given Name</label>
                                <input
                                    type="text"
                                    name="givenName"
                                    className="form-control"
                                    value={formData.givenName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Family Name</label>
                                <input
                                    type="text"
                                    name="familyName"
                                    className="form-control"
                                    value={formData.familyName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Email and Password Section */}
                <div>
                    <div className="d-flex" style={{alignItems: 'baseline'}}>
                        <div style={sectionHeaderStyle}>2. Your Login Details</div>
                        {((formData.email &&
                                formData.password &&
                                formData.confirmPassword &&
                                formData.password === formData.confirmPassword)
                                || googleProvider) &&
                            <i className="fa-solid fa-circle-check ml-2" style={{color: 'green'}}></i>}
                    </div>
                    <div className="row">
                        <div className="col-md-5">
                            {/* Email, Password, Confirm Password */}
                            <div className="form-group">
                                <label>Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    disabled={googleProvider !== null}
                                    className="form-control"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className="form-group position-relative">
                                <label>Password</label>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    className="form-control"
                                    disabled={googleProvider !== null}
                                    value={formData.password}
                                    onChange={handleChange}
                                    required
                                    style={{paddingRight: '2.5rem'}} // Space for the icon
                                />
                                <button
                                    type="button"
                                    className="btn btn-link position-absolute"
                                    onClick={togglePasswordVisibility}
                                    style={{
                                        top: '50%',
                                        right: '10px',
                                        padding: '0',
                                        fontSize: '1.25rem',
                                        color: '#6c757d',
                                    }}
                                >
                                    {showPassword ? (
                                        <i className="fa-solid fa-eye-slash"></i> // Font Awesome "eye-slash" icon for hiding
                                    ) : (
                                        <i className="fa-solid fa-eye"></i> // Font Awesome "eye" icon for showing
                                    )}
                                </button>
                            </div>

                            <div className="form-group position-relative">
                                <label>Confirm Password</label>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    name="confirmPassword"
                                    className="form-control"
                                    disabled={googleProvider !== null}
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                    required
                                    style={{paddingRight: '2.5rem'}} // Space for the icon
                                />
                                <button
                                    type="button"
                                    className="btn btn-link position-absolute"
                                    onClick={togglePasswordVisibility}
                                    style={{
                                        top: '50%',
                                        right: '10px',
                                        padding: '0',
                                        fontSize: '1.25rem',
                                        color: '#6c757d',
                                    }}
                                >
                                    {showPassword ? (
                                        <i className="fa-solid fa-eye-slash"></i> // Font Awesome "eye-slash" icon for hiding
                                    ) : (
                                        <i className="fa-solid fa-eye"></i> // Font Awesome "eye" icon for showing
                                    )}
                                </button>
                            </div>
                        </div>

                        <div className="col-md-1" style={dividerStyle}/>

                        {/* Google Login Section */}
                        <div className="col-md-6 d-flex align-items-center justify-content-center">
                            <div>
                                <p className="text-center mb-3">Or continue with</p>
                                <button
                                    onClick={handleGoogleLogin}
                                    disabled={googleProvider !== null}
                                    className={`btn ${googleProvider ? 'btn-success' : 'btn-outline-primary'}  w-100`}
                                    style={{height: '50px'}}>
                                    <i className={googleProvider ? 'fa-solid fa-check' : `fab fa-google`}></i>
                                    {googleProvider ? ` Using Google` : ' Google Account'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {errorMessage && (
                    <div className="alert alert-danger mt-3" role="alert">
                        {errorMessage}
                    </div>
                )}

                <button
                    type="submit"
                    onClick={handleSubmit}
                    className={`${isSubmitting||!canSubmit ? 'disabled' : 'btn btn-primary'} w-100 mt-3`}
                    disabled={isSubmitting || !canSubmit}
                >
                    {isSubmitting ? 'Creating Account...' : 'Create Account'}
                </button>
            </div>
        </div>
    );
};

export default Register;
