import React from 'react'
import { findKey, find } from 'lodash'
import Select from '../Select'

export default class YAxisSelect extends React.Component {
  state = {
    group: this.getGroupFromValue()
  }

  onGroupChange = (value) => this.setState({ group: value })

  getGroupFromValue() {
    const { value, yearly_groups, yearly_metric_groups } = this.props

    let group = findKey(yearly_metric_groups, (metrics) => (
      find(metrics, ([, metric]) => metric === value)
    ))

    if (!group) {
      group = yearly_groups[0][1]
    }

    return group
  }

  render() {
    const {
      aggregationType,
      basic_metrics,
      by_age_metrics,
      period_metrics,
      yearly_groups,
      yearly_metric_groups,
      value,
      name,
      includeBlank,
      onChange
    } = this.props

    const { group } = this.state

    if (aggregationType !== 'yearly') {
      const options = (
        aggregationType === 'by_age' ? by_age_metrics
          : aggregationType === 'period' ? period_metrics
            : basic_metrics
      )

      return (
        <Select
          name={ name }
          options={ options }
          value={ value }
          onChange={ onChange }
          includeBlank={ includeBlank }
          className="form-control"
        />
      )
    }

    return (
      <div>
        <Select
          options={ yearly_groups }
          value={ group }
          onChange={ this.onGroupChange }
          className="form-control mb-2"
        />
        <Select
          name={ name }
          options={ yearly_metric_groups[group] }
          value={ value }
          onChange={ onChange }
          includeBlank={ includeBlank }
          className="form-control"
        />
      </div>
    )
  }
}
