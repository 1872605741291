import React from 'react'
import {
  find, filter, map, groupBy, repeat
} from 'lodash'

const getPredictionsByCohort = ({ predictions }) => {
  const result = groupBy(predictions, 'cohort_id')
  result[''] = result.null

  return result
}

const Select = (props) => (
  <select { ...props } className="custom-select prediction-title-select" />
)

export default class PredictionSelect extends React.Component {
  state = {
    predictionsByCohort: getPredictionsByCohort(this.props)
  }

  componentWillReceiveProps(nextProps) {
    const { cohorts, predictions } = this.props

    if (nextProps.cohorts !== cohorts
     || nextProps.predictions !== predictions) {
      this.setState({ predictionsByCohort: getPredictionsByCohort(nextProps) })
    }
  }

  onCohortChange = (e) => {
    const { onChange } = this.props
    const cohortId = e.currentTarget.value

    onChange({
      cohort: cohortId,
      prediction: this.getDefaultPredictionFor(cohortId)
    })
  }

  getDefaultPredictionFor(cohortId) {
    const { selectedPrediction, predictions } = this.props
    const { predictionsByCohort } = this.state

    let nextPrediction

    // is there any predictions for a cohort?
    const cohortPredictions = filter(
      predictionsByCohort[cohortId],
      ({ state }) => state === 'processed'
    )
    if (!cohortPredictions.length) return ''

    // is there a prediction with the same name?
    const currentCohort = selectedPrediction
      && find(predictions, ({ id }) => selectedPrediction == id) // eslint-disable-line eqeqeq

    if (currentCohort) {
      nextPrediction = find(cohortPredictions, ({ name }) => name === currentCohort.name)
    }

    if (!nextPrediction) nextPrediction = cohortPredictions[cohortPredictions.length - 1]

    return nextPrediction ? nextPrediction.id : ''
  }

  onPredictionChange = (e) => {
    const { onChange, selectedCohort } = this.props

    onChange({ prediction: e.currentTarget.value, cohort: selectedCohort })
  }

  render() {
    const {
      baseUrl,
      cohorts,
      selectedCohort,
      selectedPrediction
    } = this.props
    const { predictionsByCohort } = this.state

    const predictions = predictionsByCohort[selectedCohort]

    return (
      <div>
        <h3>
          <a href={ `${baseUrl}/cohorts` }>Cohorts</a>
          &nbsp;<span className="dot">·</span>&nbsp;
          <Select
            value={ selectedCohort }
            onChange={ this.onCohortChange }
          >
            <option value="">(no cohort)</option>
            { map(cohorts, (cohort) => (
              <option
                key={ cohort.id }
                value={ cohort.id }
              >
                { repeat('- ', cohort.depth) }{ cohort.name }
              </option>
            )) }
          </Select>
          &nbsp;<span className="dot">·</span>&nbsp;
          { predictions ? (
            <Select
              value={ selectedPrediction }
              onChange={ this.onPredictionChange }
            >
              { map(predictions, (prediction) => (
                <option
                  key={ prediction.id }
                  value={ prediction.id }
                  disabled={ prediction.state !== 'processed' }
                >
                  { prediction.name }
                  { prediction.state === 'processed' ? ''
                    : prediction.state === 'initial' ? ' (processing)'
                      : prediction.state === 'failure' ? ` (${prediction.state_comment.toLowerCase()})`
                        : ` (${prediction.state})`}
                </option>
              )) }
            </Select>
          ) : <span className="prediction-selector-nomodels">No models</span> }
        </h3>
      </div>
    )
  }
}
