import React from 'react'
import memoize from 'lru-memoize'
import { map } from 'lodash'
import Modal from '../Modal'
import formatNumber from '../../utils/number'

const { $ } = window

const IS_ADMIN = window.currentUser && window.currentUser.is_admin

const getSubmitUrl = (props) => props.submit_url || window.location.pathname

const getSubmitParams = () => {
  const tokenName = $('meta[name=csrf-param]').attr('content')
  const tokenValue = $('meta[name=csrf-token]').attr('content')

  return {
    [tokenName]: tokenValue,
    _method: 'patch'
  }
}

const IS_REALLY_NUMBER_REGEX = /^\d+\.\d+$/
const isReallyANumber = (string) => string && IS_REALLY_NUMBER_REGEX.test(string.toString())

const Artefacts = ({ artefacts }) => (
  <div className="calibration-artefacts calibration-artefacts-modal">
    { map(artefacts, (value, key) => (
      <dl key={ key }>
        <dt>{ key }</dt>
        <dd>{
          isReallyANumber(value)
            ? formatNumber(parseFloat(value), 3)
            : (value && value.toString())
        }</dd>
      </dl>
    ))}
  </div>
)

const getForm = memoize(1)((submit, url, params) => ({ children }) => (
  <form action={ url } onSubmit={ submit } method="post">
    { map(params, (value, name) => (
      <input type="hidden" key={ name } name={ name } value={ value } />
    )) }
    { children }
  </form>
))

export default class CalibrationSubmitter extends React.Component {
  state = {
    submitting: false,
    submit_url: getSubmitUrl(this.props),
    submit_params: getSubmitParams()
  }

  submit = (e) => {
    e.preventDefault()

    const form = e.currentTarget

    this.setState({ submitting: true })

    $.ajax({
      url: `${this.state.submit_url}.json`,
      method: 'post',
      data: $(form).serialize()
    }).then(
      this.onSubmitSuccess,
      this.onSubmitError
    )
  }

  unsetSubmitting = () => this.setState({ submitting: false })

  onSubmitSuccess = (response) => {
    const redirect_url = response.redirect_url
    const errors = response.errors
    const artefacts = response.artefacts

    if (errors) {
      const error = this.props.onFailure ? this.props.onFailure(errors) : errors[0]

      if (error) {
        this.showError(error)
      } else {
        this.unsetSubmitting()
      }
    } else {
      const message = this.props.onSuccess && this.props.onSuccess(artefacts)
      const showArtefacts = IS_ADMIN

      if (message || showArtefacts) {
        this.showMessage((
          <>
            { message }
            { showArtefacts && <Artefacts artefacts={ artefacts } /> }
          </>
        ), redirect_url)
      } else if (redirect_url) {
        this.redirectTo(redirect_url)
      }
    }
  }

  onSubmitError = () => {
    this.showError('Unknown error')
  }

  showMessage = (message, redirect_url) => {
    this.unsetSubmitting()
    this.openModal({
      content: message,
      redirectOnClose: redirect_url
    })
  }

  showError = (error) => {
    this.unsetSubmitting()
    this.openModal({
      content: error,
    })
  }

  redirectTo = (url) => {
    this.setState({ submitting: true })

    window.location.href = url
  }

  openModal = ({ content, redirectOnClose }) => {
    this.setState({
      modalOpen: true,
      modalContent: content,
      redirectOnModalClose: redirectOnClose
    })
  }

  closeModal = () => {
    this.setState(({ redirectOnModalClose }) => {
      if (redirectOnModalClose) {
        this.redirectTo(redirectOnModalClose)
      }

      return {
        modalOpen: false,
        modalContent: null,
        redirectOnModalClose: null
      }
    })
  }

  render() {
    const {
      submit_url
    } = this.props

    const {
      submit_params,
      submitting,
      modalOpen,
      modalContent
    } = this.state

    const Form = getForm(this.submit, submit_url, submit_params)

    return (
      <div className="calibration-submitter">
        { this.props.children({
          Form,
          submitting
        }) }
        { submitting && (
          <div className="submitter-overlay">
            <i className="icon big spinner spin" />
          </div>
        ) }
        <Modal
          isOpen={ modalOpen }
          onRequestClose={ this.closeModal }
        >
          { modalContent }
          <div className="reactModal-buttons">
            <button
              type="button"
              className="btn btn-xs btn-primary"
              onClick={ this.closeModal }
              autoFocus
            >Continue</button>
          </div>
        </Modal>
      </div>
    )
  }
}
