import React from 'react'
import CalibrationSubmitter from './Submitter'

export default class FirstYearOfSignificantBreaks extends React.PureComponent {
  state = {
    year: this.props.year || ''
  }

  onYearChange = (e) => this.setState({ year: e.currentTarget.value })

  render() {
    const { submit_url } = this.props
    const { year } = this.state

    return (
      <CalibrationSubmitter submit_url={ submit_url }>{ ({ Form, submitting }) => (
        <Form>
          <div className="calibration-info">
            <div className="form-group">
              <p>The Chart shows:</p>
              <ul>
                <li>X-axis: YEAR</li>
                <li>
                  Left y-axis: Number of breaks that occurred that YEAR on pipes still that YEAR
                </li>
              </ul>

              <p>Provide the first year of significant and continuous breaks:</p>

              <input
                type="text"
                name="complete[year]"
                value={ year }
                onChange={ this.onYearChange }
                className="form-control year-input mt-2 mb-5"
                autoComplete="off"
                placeholder="• • • •"
              />

              <p>
                All breaks with YOB&lt;<strong>{ year || 'that year' }</strong> will be treated as issues.
              </p>
            </div>
          </div>
          <div className="calibration-submit">
            <button
              type="submit"
              className="calibration-submit btn btn-primary btn-lg"
              disabled={ submitting }
            >Continue →</button>
          </div>
        </Form>
      )}</CalibrationSubmitter>
    )
  }
}
