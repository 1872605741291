import React from 'react'
import {
  max, map, each, identity
} from 'lodash'
import memoize from 'lru-memoize'

import { Grid } from '@vx/grid'
import { Group } from '@vx/group'
import { AxisLeft, AxisBottom } from '@vx/axis'
import { scaleLinear } from '@vx/scale'
import { AreaStack } from '@vx/shape'
import { curveMonotoneX } from '@vx/curve'
import { withParentSize } from '@vx/responsive'
import {
  WithTooltip, Tooltip,
  Line as TooltipLine,
  Sensor as TooltipSensor,
  Content as TooltipContent
} from './Tooltip'

import {
  tickLabelLeft,
  tickLabelBottom,
  numTicksFromWidth,
  numTicksFromHeight,
  gray,
  getColorsMap,
  minmax,
  stackedMinmax,
  axisLabelStyle
} from './misc'

const getStackedDatum = memoize()((series) => {
  const length = max(map(series, (s) => s.data.length))
  const result = []

  for (let i = 0; i < length; i += 1) {
    const point = {}

    each(series, (s) => {
      point.x = point.x || s.data[i].x
      point[s.label] = s.data[i].y
    })

    result.push(point)
  }

  return result
})

const getLabels = memoize()((series) => map(series, 'label'))

class StackedArea extends React.PureComponent {
  static defaultProps = {
    margin: {
      left: 100,
      right: 20,
      top: 10,
      bottom: 70
    }
  }

  svg = React.createRef()

  render() {
    const {
      margin,
      series,
      parentWidth: width,
      parentHeight: height,
      renderTooltipValue,
      leftLabel,
      bottomLabel,
      leftTickFormat
    } = this.props

    if (!width || !height) return null

    const datum = getStackedDatum(series)
    const labels = getLabels(series)
    const colors = getColorsMap(series)

    const xMax = width - margin.left - margin.right
    const yMax = height - margin.top - margin.bottom

    const xScale = scaleLinear({
      domain: minmax(series, 'x'),
      range: [0, xMax]
    })

    const yScale = scaleLinear({
      domain: [0, stackedMinmax(series, 'y')[1] * 1.2],
      range: [yMax, 0]
    })

    const x = (d) => xScale(d.data.x)
    const y0 = (d) => yScale(d[0])
    const y1 = (d) => yScale(d[1])

    const numXTicks = numTicksFromWidth(width)
    const numYTicks = numTicksFromHeight(height)

    return (
      <WithTooltip
        series={ series }
        xScale={ xScale }
        margin={ margin }
        getSvg={ () => this.svg.current }
        parentWidth={ width }
        parentHeight={ height }
      >{ (tooltipProps) => (
        <>
          <svg
            width={ width }
            height={ height }
            ref={ this.svg }
          >
            <AxisLeft
              top={ margin.top }
              left={ margin.left }
              scale={ yScale }
              numTicks={ numYTicks }
              tickLabelProps={ tickLabelLeft }
              stroke={ gray }
              hideTicks
              label={ leftLabel }
              tickFormat={ leftTickFormat }
              labelProps={ axisLabelStyle }
              labelOffset={ 50 }
            />

            <AxisBottom
              top={ height - margin.bottom }
              left={ margin.left }
              scale={ xScale }
              tickLabelProps={ tickLabelBottom }
              tickFormat={ identity }
              stroke={ gray }
              hideTicks
              label={ bottomLabel }
              labelProps={ axisLabelStyle }
              labelOffset={ 20 }
            />

            <Group top={ margin.top } left={ margin.left }>
              <Grid
                stroke="#ddd"
                xScale={ xScale }
                yScale={ yScale }
                width={ xMax }
                height={ yMax }
                numTicksRows={ numYTicks }
                numTicksColumns={ numXTicks }
              />

              <AreaStack
                keys={ labels }
                data={ datum }
                curve={ curveMonotoneX }
                x={ x }
                y0={ y0 }
                y1={ y1 }
              >{ (area) => {
                const { stacks, path } = area

                return map(stacks, (stack) => {
                  return (
                    <path
                      key={ `stack-${stack.key}` }
                      d={ path(stack) }
                      stroke="transparent"
                      fill={ colors[stack.key] }
                    />
                  )
                })
              }}</AreaStack>

              <TooltipLine { ...tooltipProps } yMax={ yMax } />

              <TooltipSensor { ...tooltipProps } xMax={ xMax } yMax={ yMax } />
            </Group>
          </svg>
          <Tooltip
            { ...tooltipProps }
            margin={ margin }
            xMax={ xMax }
            yMax={ yMax }
          >
            <TooltipContent
              { ...tooltipProps }
              colors={ colors }
              renderValue={ renderTooltipValue }
            />
          </Tooltip>
        </>
      ) }</WithTooltip>
    )
  }
}

export default withParentSize(StackedArea)
