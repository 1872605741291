import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { get, some, map, trim } from 'lodash'

import Select from '../Select'
import Scenario from './Scenario'

const removeAt = (array, index) => {
  const newArray = [...array]
  newArray.splice(index, 1)

  return [...newArray]
}

const replaceAt = (array, index, newItem) => {
  const newArray = [...array]
  newArray.splice(index, 1, newItem)

  return [...newArray]
}

export default ({
  scenarios: initialScenarios, currentScenario, cohorts, colors, blankScenario,
  initialIndex, onScenarioSelect, onScenarioChange, onScenarioDelete, saveScenarios, getQuery
}) => {

  const [index, setIndex] = useState(initialIndex)
  const [scenarios, setScenarios] = useState(initialScenarios)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    const scenario = scenarios[index]

    onScenarioSelect(scenario)
  }, [index])

  useEffect(() => {
    setSaving(true)

    saveScenarios(scenarios).then(
      () => setSaving(false),
      (e) => {
        setSaving(false)
        window.alert(`Error saving scenarios: ${get(e.responseJSON, ['error'])}`) // eslint-disable-line no-alert
      }
    )
  }, [scenarios])

  const originalScenarioQuery = useMemo(() => (
    getQuery(scenarios[index])
  ), [scenarios, index])

  const checkForChanges = () => new Promise((resolve, reject) => {
    if (
      originalScenarioQuery === getQuery(currentScenario)
      || window.confirm('Current scenario is not saved, do you want to discard changes?') // eslint-disable-line no-alert
    ) {
      resolve()
      return
    }

    reject()
  })

  const setIndexWithCheck = (newIndex) => (
    checkForChanges().then(() => setIndex(newIndex))
  )

  const validateName = (name) => {
    if (name === '') return 'Please enter something.'
    if (some(scenarios, (scenario) => scenario.name === name)) return 'You already have a scenario with that name.'

    return null
  }

  const getName = (defaultName) => {
    let error = null
    let name

    while (!name || error) {
      name = window.prompt(`How would we call it?${error ? ` ${error}` : ''}`, name || defaultName) // eslint-disable-line no-alert

      if (name === null) return null

      name = trim(name)

      error = validateName(name)
    }

    return name
  }

  const rename = useCallback(() => {
    const name = getName(currentScenario.name)

    if (!name || (name === currentScenario.name)) { return }

    const newScenario = { ...currentScenario, name }
    const newScenarios = replaceAt(scenarios, index, newScenario)

    onScenarioChange(newScenario)
    setScenarios(newScenarios)
  }, [scenarios, currentScenario])


  const save = useCallback(() => {
    let name = currentScenario.name
    if (!name) {
      name = getName()
    }

    if (name === null) return

    const newScenarios = replaceAt(scenarios, index, { ...currentScenario, name })

    setScenarios(newScenarios)
  }, [scenarios, currentScenario])

  const saveAs = useCallback(() => {
    const defaultName = currentScenario ? currentScenario.name : ''
    const name = getName(defaultName)

    if (name === null) return

    const {id, ...newScenario} = currentScenario
    const newScenarios = [...scenarios, { ...newScenario, name }]

    setScenarios(newScenarios)
    setIndex(newScenarios.length - 1)
  }, [scenarios, currentScenario])

  const remove = useCallback(() => {
    if (window.confirm('Really delete?')) { // eslint-disable-line no-alert
      const newScenarios = removeAt(scenarios, index)

      onScenarioDelete(scenarios[index].id)

      setScenarios(newScenarios)
      setIndex(Math.max(index - 1, 0))
    }
  }, [scenarios, currentScenario])

  const create = () => {
    checkForChanges().then(() => {
      const name = getName()

      if (name === null) return

      const newScenarios = [...scenarios, { ...blankScenario, name }]

      setScenarios(newScenarios)
      setIndex(newScenarios.length - 1)
    })
  }

  const options = useMemo(
    () => map(scenarios, (scenario, i) => [scenario.name, i]),
    [scenarios]
  )

  const isDefault = currentScenario && !!currentScenario.name.match(/^Default( [\d.]+)?$/)

  return (
    <div>
      <div className="plan-scenarios-row mb-4">
        <div className="plan-scenarios-row-buttons mr-2">
          <button type="button" onClick={ create } className="btn btn-success ml-2" disabled={ saving }>
            <i className={'fa-solid fa-add pr-2'}></i>
            New
          </button>
          <button type="button" onClick={ saveAs } className="btn btn-info ml-2" disabled={ saving }>
            <i className={'fa-solid fa-clone pr-2'}></i>
            Clone</button>
          <button type="button" onClick={ rename } className="btn btn-info ml-2" disabled={ isDefault || saving }>
            <i className={'fa-solid fa-edit pr-2'}></i>
            Rename</button>
          <button type="button" onClick={ save } className="btn btn-success ml-2" disabled={ isDefault || saving }>
            <i className={'fa-solid fa-save pr-2'}></i>
            Save</button>
          <button type="button" onClick={ remove } className="btn btn-danger ml-2" disabled={ isDefault || saving }>
            <i className={'fa-solid fa-trash pr-2'}></i>
            Delete</button>
        </div>

        <div className="plan-scenarios-row-select">
          <Select
            options={ options }
            value={ index }
            onChange={ setIndexWithCheck }
            className="custom-select"
            disabled={ saving }
          />
        </div>
      </div>

      { scenarios && isDefault && (
        <div className="alert alert-info">
          '{currentScenario.name}' is a scenario that corresponds to an equal length of replacement every year
          until last pipe is replaced with EULs pertaining to a set
          Maximum Allowed Break Rate (MABR) of 0.2.
          Other scenarios can be created by setting specific
          lengths of replacement over various periods of time.
        </div>
      )}

      { currentScenario && (
        <Scenario
          scenario={ currentScenario }
          onChange={ onScenarioChange }
          cohorts={ cohorts }
          colors={ colors }
        />
      ) }
    </div>
  )
}
