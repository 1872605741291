import React from 'react'

const isValid = (_value, max, min) => {
  const value = parseFloat(_value)

  if (Number.isNaN(value)) { return false }
  if (min && value < min) { return false }
  if (max && value > max) { return false }

  return true
}

export default class PlanInput extends React.PureComponent {
  state = {
    value: this.props.value
  }

  ref = React.createRef()

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value })
    }
  }

  onChange = (e) => {
    this.setState({ value: e.currentTarget.value })
  }

  onBlur = () => {
    const { value } = this.state

    if (isValid(value, this.props.max, this.props.min)) {
      this.props.onChange(value)
    } else {
      this.resetValue()
    }
  }

  resetValue = (callback) => {
    this.setState({ value: this.props.value }, callback)
  }

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.onBlur()
      this.blur()
    }

    if (e.keyCode === 27) {
      this.resetValue(this.blur)
    }
  }

  blur = () => {
    if (this.ref.current) {
      this.ref.current.blur()
    }
  }

  render() {
    const { htmlId } = this.props
    const { value } = this.state

    return (
      <input
        ref={ this.ref }
        id={ htmlId }
        type={ this.props.type || "text" }
        min={this.props.min}
        max={this.props.max}
        step={this.props.step}
        className="form-control"
        value={ value }
        onChange={ this.onChange }
        onBlur={ this.onBlur }
        onKeyDown={ this.onKeyDown }
        placeholder={ this.props.placeholder }
      />
    )
  }
}
