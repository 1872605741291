import React, {useState} from 'react';

import Break from './Break'

const Pagination = ({currentPage, totalPages, itemsPerPage, onPageChange, totalItems}) => (
    <div className="pagination">
        <button
            onClick={() => onPageChange('first')}
            disabled={currentPage === 0}
        >
            First
        </button>
        &nbsp;
        <button
            onClick={() => onPageChange('prev')}
            disabled={currentPage === 0}
        >
            Previous
        </button>
        <span>Breaks [{(currentPage * itemsPerPage) + 1}, {Math.min(totalItems, ((currentPage + 1) * itemsPerPage))}] of {totalItems}</span>
        <button
            onClick={() => onPageChange('next')}
            disabled={currentPage === totalPages - 1}
        >
            Next
        </button>
        &nbsp;
        <button
            onClick={() => onPageChange('last')}
            disabled={currentPage === totalPages - 1}
        >
            Last
        </button>
    </div>
);

// BreaksTable component with simple pagination
const BreaksTable = ({breaks, changes, handleChange}) => {
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 20;

    // Calculate pagination range
    const startIndex = currentPage * itemsPerPage;
    const endIndex = (currentPage + 1) * itemsPerPage;

    // Slice breaks to display current page
    const paginatedBreaks = breaks.slice(startIndex, endIndex);

    // Calculate total number of pages
    const totalPages = Math.ceil(breaks.length / itemsPerPage);

    // Handle page change
    const handlePageChange = direction => {
        if (direction === 'prev' && currentPage > 0) {
            setCurrentPage(currentPage - 1);
        } else if (direction === 'next' && endIndex < breaks.length) {
            setCurrentPage(currentPage + 1);
        } else if (direction === 'first') {
            setCurrentPage(0);
        } else if (direction === 'last') {
            setCurrentPage(totalPages - 1);
        }
    };

    return (
        <>
            {/* Pagination on top */}
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                itemsPerPage={itemsPerPage}
                totalItems={breaks.length}
            />
            <table id="breaksTable" className="display" style={{width: '100%'}}>
                {/* Table headers */}
                <thead>
                <tr>
                    <th>Break</th>
                    <th>Selected Pipe</th>
                </tr>
                </thead>
                <tbody>
                {/* Map through paginated breaks */}
                {paginatedBreaks.map(brk => (
                    <Break key={brk.id} brk={brk} handleChange={handleChange} newPipe={changes[brk.id]}/>
                ))}
                </tbody>
            </table>
            {/* Pagination at the bottom */}
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                itemsPerPage={itemsPerPage}
                totalItems={breaks.length}
            />
        </>
    );
};

export default BreaksTable;
