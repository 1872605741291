import React from 'react'

import Qualitative from './Qualitative'
import Quantitative from './Quantitative'

export default (props) => (
  (props.base_variable.var_type === 'qualitative')
    ? <Qualitative { ...props } />
    : <Quantitative { ...props } />
)
