import qs from 'qs'

const filterFunc = (key, value) => {
  if (value === '') return undefined

  return value
}

export const stringify = (query) => {
  const { predicates, ...other } = query
  const tempQuery = { ...other, predicates: JSON.stringify(predicates) }

  return qs.stringify(tempQuery, { arrayFormat: 'brackets', filter: filterFunc })
}

export const parse = (string) => {
  const query = qs.parse(string)

  if (query.predicates) {
    try {
      query.predicates = JSON.parse(query.predicates)
    } catch (e) {
      delete query.predicates
    }
  }

  return query
}
