import React from 'react'
import { last } from 'lodash'
import CalibrationSubmitter from './Submitter'
import formatNumber from '../../utils/number'
import Chart from './Chart'

class DefaultStep extends React.Component {
  render() {
    const {
      submitting, Form, goToDecreasingSlowly, goToSingleBreakpoint, goToSeveralBreakpoints
    } = this.props

    return (
      <>
        <div className="calibration-info">
          <p>
            In this section we decide whether the cohort should be
            split and if a new variable related to YOI should be created.
          </p>

          <p>The Chart shows:</p>
          <ul>
            <li>X-axis: YOI</li>
            <li>
              Left y-axis: Average Yearly Break Rate of MATn1 pipes between the YOI
              and the YOA for ABN pipes, and between the YOI and the year of
              the project for ACT pipes.
            </li>
            <li>
              Right y-axis: length of MATn1 pipes installed
              at a certain year as of year project was created.
            </li>
          </ul>

          <p>
            Using the bottom ruler, select the YOIs for which length is substantial.
            If necessary, observe limited periods of YOI that do not include peak
            Average Yearly Break Rate values.
          </p>

          <div>
            <p>Choose the overall trend (ignore small periods of YOI where trends differ):</p>
          </div>
        </div>
        <div className="calibration-submit text-center calibration-submit-alotabuttons">
          <Form>
            <input type="hidden" name="complete[break_rate_decreasing_sharply]" value="true" />

            <button
              type="submit"
              className="btn btn-primary"
              disabled={ submitting }
            >
              The Average Yearly Break Rate is decreasing sharply and steadily as the YOI increases
            </button>
          </Form>

          <div>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={ submitting }
              onClick={ goToDecreasingSlowly }
            >The Average Yearly Break Rate is decreasing slowly as the YOI increases</button>
          </div>

          <Form>
            <input type="hidden" name="complete[break_rate_increasing]" value="true" />

            <button
              type="submit"
              className="btn btn-primary"
              disabled={ submitting }
            >The Average Yearly Break Rate is increasing as the YOI increases</button>
          </Form>

          <div>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={ goToSingleBreakpoint }
              disabled={ submitting }
            >
              The Average Yearly Break Rate
              is lower prior to a certain cutoff
              YOI as the YOI increases
            </button>
          </div>

          <div>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={ goToSeveralBreakpoints }
              disabled={ submitting }
            >
              There are several (maximum 3)
              periods of times with differing overall
              Average Yearly Break Rate
            </button>
          </div>

          <Form>
            <input type="hidden" name="complete[other_trend]" value="true" />

            <button
              type="submit"
              className="btn btn-primary"
              disabled={ submitting }
            >Other trend</button>
          </Form>
        </div>
      </>
    )
  }
}

class SingleBreakpoint extends React.PureComponent {
  render() {
    const {
      year, onYearChange,
      submitting, Form, onBack
    } = this.props

    return (
      <>
        <Form>
          <div className="calibration-info">
            <div className="mb-4">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={ onBack }
                disabled={ submitting }
              >← Go back</button>
            </div>

            <div>
              <p>
                The Average Yearly Break Rate is lower prior
                to a certain cutoff YOI as the YOI increases.
              </p>

              <p>
                Older pipes appear to have a lower overall Average Yearly Break Rate.
                There are two possible reasons why this is the case:
              </p>
              <ul>
                <li>
                  Older pipes are of a different grade, or they were subjected
                  to conditions that made them degrade faster.
                </li>
                <li>
                  Older and worse (higher Average Yearly Break Rate) pipes have been abandoned,
                  leaving the best-performing ones (very low relative Break Rate).
                </li>
              </ul>

              <p>What is the cutoff YOI?</p>

              <input
                type="text"
                name="complete[single_breakpoint_year]"
                value={ year }
                onChange={ onYearChange }
                className="form-control year-input mt-2 mb-5"
                autoComplete="off"
                placeholder="• • • •"
                autoFocus
              />
            </div>
          </div>

          <div className="calibration-submit text-center">
            <button
              type="submit"
              className="calibration-submit btn btn-primary"
              disabled={ submitting }
            >Continue →</button>
          </div>
        </Form>
      </>
    )
  }
}

class DecreasingSlowly extends React.PureComponent {
  state = {
    formShown: false
  }

  showForm = () => this.setState({ formShown: true })

  render() {
    const { formShown } = this.state
    const {
      year, onYearChange,
      submitting, Form, onBack
    } = this.props

    return (
      <>
        <div className="calibration-info">
          <div className="mb-4">
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              onClick={ onBack }
              disabled={ submitting }
            >← Go back</button>
          </div>

          <div>
            <p>
              The Average Yearly Break Rate is decreasing slowly as the YOI increases.
              The trend is as expected. It is recommended that DOI/DDP be introduced in the model.
            </p>

            <p>
              Is there a cutoff year prior to which the Average Yearly Break
              Rate is higher than at or after that cutoff year?
            </p>

            { !formShown && (
              <>
                <div className="row calibration-variables-variable-buttonsRow">
                  <div className="col-6">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={ this.showForm }
                    >Yes</button>
                  </div>

                  <div className="col-6">
                    <Form>
                      <input type="hidden" name="complete[break_rate_decreasing_slowly]" value="true" />

                      <button
                        type="submit"
                        className="btn btn-primary"
                      >No</button>
                    </Form>
                  </div>
                </div>
              </>
            )}

            { formShown && (
              <Form>
                <input type="hidden" name="complete[break_rate_decreasing_slowly]" value="true" />

                <input
                  type="text"
                  name="complete[break_rate_decreasing_slowly_year]"
                  value={ year }
                  onChange={ onYearChange }
                  className="form-control year-input mt-2 mb-5"
                  autoComplete="off"
                  placeholder="• • • •"
                  autoFocus
                />

                <button
                  type="submit"
                  className="calibration-submit btn btn-primary"
                  disabled={ submitting }
                >Continue →</button>
              </Form>
            )}

          </div>
        </div>
      </>
    )
  }
}

class SeveralBreakpoints extends React.PureComponent {
  render() {
    const {
      year, year2,
      onYearChange, onYear2Change,
      submitting, Form, onBack
    } = this.props

    return (
      <>
        <Form>
          <div className="calibration-info">
            <div className="mb-4">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={ onBack }
                disabled={ submitting }
              >← Go back</button>
            </div>

            <div>
              <p>
                There are several (maximum 3) periods of times with differing
                overall Average Yearly Break Rate.
              </p>
              <p>What are the cutoff(s) YOI? (select 1 or a maximum of 2)</p>

              <div className="row mt-2">
                <div className="col-3">
                  <input
                    type="text"
                    name="complete[several_breakpoints][]"
                    value={ year }
                    onChange={ onYearChange }
                    className="form-control year-input"
                    autoComplete="off"
                    placeholder="• • • •"
                    autoFocus
                  />
                </div>
                <div className="col-3">
                  <input
                    type="text"
                    name="complete[several_breakpoints][]"
                    value={ year2 }
                    onChange={ onYear2Change }
                    className="form-control year-input"
                    autoComplete="off"
                    placeholder="• • • •"
                  />
                </div>
              </div>
              <p className="form-text text-muted mb-5">(Cutoff year will be included in younger range)</p>
            </div>
          </div>

          <div className="calibration-submit text-center">
            <button
              type="submit"
              className="calibration-submit btn btn-primary"
              disabled={ submitting }
            >Continue →</button>
          </div>
        </Form>
      </>
    )
  }
}

export default class MaybeSplitOnYoi extends React.PureComponent {
  state = {
    screen: 'default',
    year: null,
    year2: null
  }

  onYearChange = (e) => this.setState({ year: e.currentTarget.value })
  onYear2Change = (e) => this.setState({ year2: e.currentTarget.value })

  goTo = (screen) => this.setState({ screen, year: null, year2: null })

  goToDefault = () => this.goTo('default')
  goToDecreasingSlowly = () => this.goTo('decreasing_slowly')
  goToSingleBreakpoint = () => this.goTo('single_breakpoint')
  goToSeveralBreakpoints = () => this.goTo('several_breakpoints')

  onSubmitSuccess = (artefacts) => {
    if (artefacts.break_rate_decreasing_sharply) {
      return (
        <>
          <p>
            The Average Yearly Break Rate is decreasing sharply as the YOI increases.
            The trend is as expected. It is recommended that DOI/DDP be introduced to the model.
          </p>
        </>
      )
    }

    if (artefacts.break_rate_decreasing_slowly) {
      const year = artefacts.created_variable_split_on

      return (
        <>
          <p>
            The AYBR is decreasing slowly as the YOI increases.
            This trend is as expected.
            It is recommended that DDP be introduced in the model as well as
            a new variable { artefacts.covariate_name } created with 2 modalities

            { artefacts.year_guessed
              ? ` that each represent half the length of the cohort, before ${year} or at and after ${year}`
              : ' as follows' }:
          </p>

          <ul>
            <li>OLD (before cutoff { year })</li>
            <li>YOUNG (at or after cutoff { year })</li>
          </ul>
        </>
      )
    }

    if (artefacts.break_rate_increasing) {
      if (!artefacts.tests_met) {
        return (
          <p>
            Internal computations show that the program of
            abandonment does not justify the unexpected trend.
            The model may have poor validation.
          </p>
        )
      }
    }

    if (artefacts.single_breakpoint_year) {
      if (artefacts.too_small) {
        return (
          <p>One of the groups are too small. The cohort will not be split.</p>
        )
      }

      if (artefacts.created_variable_split_on) {
        return (
          <>
            <p>
              Internal computations show that the pattern
              of abandonment justifies the unexpected trend.
              The cohort will not be split.
            </p>
            <p>
              A new variable { artefacts.covariate_name } has been created with
              2 modalities:
            </p>
            <ul>
              <li>OLD (before { artefacts.created_variable_split_on })</li>
              <li>YOUNG (at or after { artefacts.created_variable_split_on })</li>
            </ul>
            <p>
              The cohort will not be split; it is recommended
              that { artefacts.covariate_name } be introduced in the model.
            </p>
          </>
        )
      }

      if (artefacts.split) {
        const ratio = artefacts.aybr_young / artefacts.aybr_old

        return (
          <>
            <p>
              The Average Yearly Break Rate of YOUNG pipes is { formatNumber(ratio, 2) }
              &nbsp;times larger than the Average Yearly Break Rate of OLD pipes.
              Internal computations show that the program of
              abandonment does not justify the unexpected trend.

              The cohort is therefore split between:
            </p>

            <ul>
              <li>OLD (pipes installed before { artefacts.split })</li>
              <li>and YOUNG pipes (pipes installed at or after { artefacts.split }).</li>
            </ul>
          </>
        )
      }

      return (
        <>
          No specific trend has been identified.
          The cohort will not be split.
          No new variable will be created.
          The model may have poor validation.
        </>
      )
    }

    if (artefacts.several_breakpoints) {
      const years = artefacts.created_variable_split_on

      return (
        <>
          <p>
            A new variable { artefacts.covariate_name } has
            been created with the following modalities:
          </p>

          <ul>
            <li>OLD (before cutoff { years[0] })</li>
            { years.length > 1 && <li>MED (at or after { years[0] } and before { years[1] })</li> }
            <li>YOUNG (at or after cutoff { last(years) })</li>
          </ul>

          <p>
            The cohort will not be split; it is recommended
            that { artefacts.covariate_name } be introduced in the model.
          </p>
        </>
      )
    }

    if (artefacts.other_trend) {
      return (
        <p>
          No specific trend has been identified.
          The cohort will not be split.
          No new variable will be created.
          The model may have poor validation.
        </p>
      )
    }
  }

  onFailure = (errors) => {
    const error = errors[0]

    if (error.indexOf('aybr_ratio_too_small:') === 0) {
      const aybr_ratio = parseFloat(error.split(':')[1])

      if (aybr_ratio < 1) {
        return (
          <p>
            The Average Yearly Break Rate of OLD pipes is
            actually { formatNumber(1 / aybr_ratio, 2) } higher than the
            Average Yearly Break Rate of YOUNG pipes.
            Consider another case scenario.
          </p>
        )
      }

      return (
        <p>
          The Average Yearly Break Rate of OLD pipes is
          only { formatNumber(1 / aybr_ratio, 2) } times the
          Average Yearly Break Rate of YOUNG pipes.
          YOUNG pipes do not have an AYBR that is significantly higher than OLD pipes.
          Consider another case scenario.
        </p>
      )
    }

    return errors[0]
  }

  render() {
    const { screen, year, year2 } = this.state
    const { chart_url } = this.props

    const chartProps = (
      ((screen === 'single_breakpoint' || screen === 'decreasing_slowly') && year) ? { year }
        : (screen === 'several_breakpoints' && year) ? { year, year2 }
          : {})

    return (
      <div className="calibration-columns">
        <div className="calibration-chart">
          <Chart
            base_url={ chart_url }
            { ...chartProps }
          />
        </div>

        <div className="calibration-content calibration-content-wider">
          <CalibrationSubmitter
            onSuccess={ this.onSubmitSuccess }
            onFailure={ this.onFailure }
          >{ ({ Form, submitting }) => {
            const commonProps = {
              Form,
              submitting
            }

            switch (screen) {
              case 'default':
                return (
                  <DefaultStep
                    { ...commonProps }
                    goToDecreasingSlowly={ this.goToDecreasingSlowly }
                    goToSingleBreakpoint={ this.goToSingleBreakpoint }
                    goToSeveralBreakpoints={ this.goToSeveralBreakpoints }
                  />
                )
              case 'decreasing_slowly':
                return (
                  <DecreasingSlowly
                    { ...commonProps }
                    year={ year }
                    onYearChange={ this.onYearChange }
                    onBack={ this.goToDefault }
                  />
                )
              case 'single_breakpoint':
                return (
                  <SingleBreakpoint
                    { ...commonProps }
                    onBack={ this.goToDefault }
                    year={ year }
                    onYearChange={ this.onYearChange }
                  />
                )
              case 'several_breakpoints':
                return (
                  <SeveralBreakpoints
                    { ...commonProps }
                    onBack={ this.goToDefault }
                    year={ year }
                    year2={ year2 }
                    onYearChange={ this.onYearChange }
                    onYear2Change={ this.onYear2Change }
                  />
                )
              default:
                return null
            }
          } }</CalibrationSubmitter>
        </div>
      </div>
    )
  }
}
