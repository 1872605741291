import React, {useState} from 'react'
import {faWrench} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function ({projectId}) {
    const [isHovering, setIsHovering] = useState(false);

    return (
        <a href={`/projects/${projectId}/edit`}
           onMouseEnter={() => setIsHovering(true)}
           onMouseLeave={() => setIsHovering(false)}
           style={{
                display: 'flex',
                alignItems: 'center'
        }}
        >
            <FontAwesomeIcon style={{
                width: "auto",
                height: '1em',
                marginLeft: '0.6rem'
            }} icon={faWrench}/>
            {isHovering && <span style={
                {
                    position: 'absolute',
                    marginLeft: '2rem'
                }
            }>settings</span>}
        </a>
    )
}