import React from 'react'
import {
  map, filter, find, includes, mapValues, reduce, some, sortBy, uniq, without, transform
} from 'lodash'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import * as qs from 'qs'
import c from 'classnames'
import CalibrationSubmitter from './Submitter'
import formatNumber from '../../utils/number'
import { joinWithAnd } from '../../utils/string'

const ITEM_TYPE = 'material'

const parseLengths = (lengths) => mapValues(lengths, (length) => parseFloat(length))

const sortGroups = (groups) => sortBy(groups, ({ name }) => name)

const getInitialGroups = (all_materials, lengths) => (
  sortGroups(
    map(sortBy(all_materials, (material) => (lengths[material])), (material) => ({
      name: material,
      items: [material]
    }))
  )
)

const getGroupName = (groupName, items, previousItems) => {
  // group name was changed, keep it
  if (items.length > 1 && previousItems && groupName !== getGroupName(groupName, previousItems)) {
    return groupName
  }

  return items.join('+')
}

const getCanUngroup = (itemName, groups) => some(groups, ({ items }) => (
  items.length > 1 && includes(items, itemName)
))

const removeFromAnywhere = ({ groups, ignore }, itemName) => ({
  groups: map(groups, (group) => {
    if (!includes(group.items, itemName)) {
      return group
    }

    const items = without(group.items, itemName)

    return {
      name: getGroupName(group.name, items, group.items),
      items
    }
  }),
  ignore: without(ignore, itemName)
})

const createGroup = ({ groups, ignore }, itemName) => ({
  groups: [...groups, {
    name: itemName,
    items: [itemName]
  }],
  ignore
})

const addToIgnore = ({ groups, ignore }, itemName) => ({
  groups,
  ignore: [...ignore, itemName]
})

const addToGroup = ({ groups, ignore }, itemName, groupName) => ({
  groups: map(groups, (group) => {
    if (group.name !== groupName) {
      return group
    }

    const items = uniq([...group.items, itemName])

    return {
      name: getGroupName(group.name, items, group.items),
      items
    }
  }),
  ignore
})


const Item = ({
  onDrop, onDragBegin, onDragEnd,
  item: itemName, ignored
}) => {
  const [{ isDragging }, drag] = useDrag({
    begin: () => onDragBegin(itemName, ignored),
    item: { name: itemName, type: ITEM_TYPE },

    end: (item, monitor) => {
      const dropResult = monitor.getDropResult()

      onDragEnd()

      if (item && dropResult) {
        onDrop(item, dropResult)
      }
    },

    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
  })

  return (
    <div
      ref={ drag }
      className={ c('regrouping-item', `bg-${itemName}`, isDragging && 'regrouping-item--dragging') }
    >{ itemName }</div>
  )
}

const Ungroup = ({ visible }) => {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ITEM_TYPE,
    drop: () => ({ ungroup: true }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    }),
  })

  const isActive = canDrop && isOver

  return (
    <div
      ref={ drop }
      className={ c(
        'regrouping-ungroup',
        isActive && 'regrouping-ungroup--hover',
        visible && 'regrouping-ungroup--visible'
      ) }
    />
  )
}

class GroupName extends React.Component {
  state = {
    value: this.props.value
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value })
    }
  }

  onChange = (e) => this.setState({ value: e.currentTarget.value })
  onBlur = () => this.props.onChange(this.state.value)

  render() {
    const { readonly } = this.props
    const { value } = this.state

    if (readonly) {
      return <span className="regrouping-group-name-input">{ value }</span>
    }

    return (
      <input
        type="text"
        value={ value }
        onChange={ this.onChange }
        onBlur={ this.onBlur }
        className="form-control text-center regrouping-group-name-input"
      />
    )
  }
}

const Group = ({
  onDrop,
  onDragBegin,
  onDragEnd,
  index,
  name,
  items,
  length,
  onRename,
  ignored,
  className
}) => {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ITEM_TYPE,
    drop: () => ({ name, ignore: ignored }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    }),
  })

  const isActive = canDrop && isOver
  const hideName = items.length === 1 && items[0] === name
  const canRename = !!onRename
  const onRenameWithIndex = canRename && ((value) => onRename(index, value))

  return (
    <div
      ref={ drop }
      className={ c(
        'regrouping-group',
        isActive && 'regrouping-group--hover',
        hideName && 'regrouping-group--hideName',
        className
      ) }
    >
      <div className="regrouping-group-name">
        <GroupName value={ name } onChange={ onRenameWithIndex } readonly={ !canRename } />
      </div>

      <div className="regrouping-group-items">{
        map(items, (item) => (
          <Item
            key={ item }
            item={ item }
            onDrop={ onDrop }
            onDragBegin={ onDragBegin }
            onDragEnd={ onDragEnd }
            ignored={ ignored }
          />
        ))
      }</div>

      <div
        className={ c(
          'regrouping-group-length',
          (length && length < 30) && 'regrouping-group-length--invalid'
        ) }
      >
        { length !== undefined ? `${formatNumber(length, 1)} mi` : '' }
      </div>
    </div>
  )
}

export default class RegroupMat extends React.PureComponent {
  constructor(props) {
    super(props)

    const lengths = parseLengths(props.material_lengths)
    const groups = this.props.groups || getInitialGroups(props.all_materials, lengths)
    const ignore = this.props.ignore || []

    this.state = {
      canUngroup: false,
      iframeLoading: true,
      iframeSrc: this.getIframeSrc({ groups, ignore }),
      lengths,
      groups,
      ignore,
    }
  }

  iframeLoaded = () => this.setState({ iframeLoading: false })
  updateGroups = (updateFunc) => this.setState(({
    iframeSrc, iframeLoading, groups, ignore
  }) => {
    const groupsAndIgnore = updateFunc({ groups, ignore })
    const newIframeSrc = this.getIframeSrc(groupsAndIgnore)

    return {
      ...groupsAndIgnore,
      iframeSrc: newIframeSrc,
      iframeLoading: newIframeSrc !== iframeSrc ? true : iframeLoading
    }
  })

  getIframeSrc = ({ groups, ignore }) => {
    const query = qs.stringify({ groups, ignore }, { arrayFormat: 'brackets' })

    return `${this.props.chart_url}?${query}`
  }

  onDragBegin = (itemName, ignored) => this.setState(
    ({ groups }) => ({ canUngroup: ignored ? 'ignored' : getCanUngroup(itemName, groups) })
  )

  onDragEnd = () => this.setState({ canUngroup: false })

  onDrop = (droppedItem, dropResult) => {
    const itemName = droppedItem.name
    const groupName = dropResult.name

    this.updateGroups(({ groups, ignore }) => {
      let groupsAndIgnore = { groups, ignore }

      if (dropResult.ungroup) {
        groupsAndIgnore = removeFromAnywhere(groupsAndIgnore, itemName)
        groupsAndIgnore = createGroup(groupsAndIgnore, itemName)
      } else if (dropResult.ignore) {
        groupsAndIgnore = removeFromAnywhere(groupsAndIgnore, itemName)
        groupsAndIgnore = addToIgnore(groupsAndIgnore, itemName)
      } else { // move
        const newGroup = find(groups, ({ name }) => name === groupName)

        if (newGroup && includes(newGroup.items, itemName)) {
          return groupsAndIgnore
        }

        groupsAndIgnore = removeFromAnywhere(groupsAndIgnore, itemName)
        groupsAndIgnore = addToGroup(groupsAndIgnore, itemName, groupName)
      }

      return {
        groups: sortGroups(filter(groupsAndIgnore.groups, ({ items }) => items.length > 0)),
        ignore: groupsAndIgnore.ignore
      }
    })
  }

  onGroupRename = (groupIndex, newName) => this.updateGroups(({ groups, ignore }) => ({
    groups: map(groups, (group, index) => (
      index === groupIndex ? ({
        ...group,
        name: newName
      }) : group
    )),
    ignore
  }))

  render() {
    const {
      groups, ignore,
      canUngroup, iframeLoading, iframeSrc,
      lengths
    } = this.state

    const lengthByGroup = transform(groups, (result, group) => {
      result[group.name] = reduce( // eslint-disable-line no-param-reassign
        group.items,
        (sum, item) => (sum + (lengths[item] || 0)), 0
      )
    }, {})

    const lengthByGroupArray = map(lengthByGroup, (length, name) => ({ name, length }))

    const smallGroups = filter(lengthByGroupArray, ({ length }) => (length >= 3 && length < 30))
    const someAreSmall = smallGroups.length > 0
    const verySmallGroups = filter(lengthByGroupArray, ({ length }) => (length < 3))
    const someAreVerySmall = verySmallGroups.length > 0

    const formFields = (
      <>
        <input type="hidden" name="_method" value="patch" />
        <input
          type="hidden"
          name="complete[groups]"
          value={ JSON.stringify(groups) }
        />
        <input
          type="hidden"
          name="complete[ignore]"
          value={ JSON.stringify(ignore) }
        />
      </>
    )

    return (
      <CalibrationSubmitter>{ ({ Form, submitting }) => (
        <>
          <div className="calibration-columns">
            <div className="calibration-chart">
              <iframe
                title="Chart"
                src={ iframeSrc }
                className={ c(
                  'calibration-regroup-mat-iframe',
                  iframeLoading && 'calibration-regroup-mat-iframe--loading'
                ) }
                onLoad={ this.iframeLoaded }
              />
            </div>

            <div className="calibration-content">
              <div className="calibration-info">
                { someAreSmall && (
                  <>
                    <p>
                      <strong>{ joinWithAnd(map(smallGroups, 'name')) }</strong> have L &lt; 30 miles.
                      They are too small to be modeled alone. You can either create a single model
                      with MAT as a co-variate; or create a model for each MAT or
                      group of MATs with sufficient length.
                    </p>
                  </>
                ) }
                { someAreVerySmall && (
                  <p>
                    <strong>{ map(verySmallGroups, 'name').join(', ') }</strong> have length &lt; 3 miles.
                    They can be regrouped with other materials (by stacking them under one
                    of the MATs to be regrouped; a new name can be given).
                    Or they will be automatically added to the IGNORE window.
                  </p>
                ) }
              </div>
              <div className="calibration-submit">
                { someAreVerySmall && (
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg"
                    disabled
                  >Continue →</button>
                ) }
                { !someAreVerySmall && (
                  <>
                    { someAreSmall && (
                      <>
                        <Form>
                          { formFields }
                          <button
                            type="submit"
                            className="calibration-submit btn btn-primary btn-lg"
                            disabled={ submitting }
                          >Create model for each MAT →</button>
                        </Form>
                        <Form>
                          { formFields }
                          <input type="hidden" name="complete[use_covariate]" value="1" />
                          <button
                            type="submit"
                            className="calibration-submit btn btn-primary btn-lg"
                            disabled={ submitting }
                          >Create single model →</button>
                        </Form>
                      </>
                    ) }
                    { !someAreSmall && (
                      <Form>
                        { formFields }
                        <button
                          type="submit"
                          className="calibration-submit btn btn-primary btn-lg"
                          disabled={ submitting }
                        >Continue →</button>
                      </Form>
                    ) }
                  </>
                )}
              </div>
            </div>
          </div>
          <DndProvider backend={ HTML5Backend }>
            <div className="regrouping-container">
              <div className="regrouping-groups">
                <div className="regrouping-groups-ordinary">
                  <Ungroup visible={ canUngroup } />

                  { map(groups, ({ name, items }, index) => (
                    <Group
                      index={ index }
                      key={ name }
                      name={ name }
                      items={ items }
                      onDrop={ this.onDrop }
                      onDragBegin={ this.onDragBegin }
                      onDragEnd={ this.onDragEnd }
                      onRename={ this.onGroupRename }
                      length={ lengthByGroup[name] }
                    />
                  )) }
                </div>

                <Group
                  className="regrouping-groups-ignore"
                  ignored
                  name="IGNORE"
                  items={ ignore }
                  onDrop={ this.onDrop }
                  onDragBegin={ this.onDragBegin }
                  onDragEnd={ this.onDragEnd }
                />
              </div>
            </div>
          </DndProvider>
        </>
      ) }</CalibrationSubmitter>
    )
  }
}
