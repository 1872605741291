import React from 'react'

import ValidationData from './ValidationData'
import CovariatesTable from './CovariatesTable'

export default class ValidationModel extends React.PureComponent {
  render() {
    const {
      prediction: {
        version,
        prediction_model,
        validation_model,
        validation_data,
        validation_chart_by_number,
        validation_chart_by_length
      }
    } = this.props

    return (
      <div className="prediction-model mt-4">
        { validation_data && (
          <ValidationData
            data={ validation_data }
            version={ version }
            validation_model={ validation_model }
            chart_by_number={ validation_chart_by_number }
            chart_by_length={ validation_chart_by_length }
          />
        )}

        <hr />

        { prediction_model && (
          <CovariatesTable
            model={ prediction_model } 
            validation_model={ validation_model }
          />
        )}
      </div>
    )
  }
}
