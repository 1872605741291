import React, {useEffect} from 'react'
import {useState} from 'react'

const fetchUsers = async () => {
    return await Api.get('users');
}

const List = ({users}) => {
    return (
        <div>
            <div className="d-flex justify-content-between">
            <h1>Users</h1>
                <button
                    onClick={() => {
                        document.location.href = '/users/new'
                    }}
                    className="btn btn-primary"><i className="fa-solid fa-plus mr-2"></i>New User</button>
            </div>
        <table className="users alternate">
        <thead>
        <tr>
            <th className="name">Name</th>
            <th className="email">Email</th>
            <th className="last_login">Last login</th>
            <th className="login_count">Login count</th>
        </tr>
        </thead>
        <tbody>
        {users && users.map((user, index) => (
            <tr
                key={user.id} className={index % 2 === 0 ? 'odd' : 'even'}>
                <td className="name">
                    <a href={`/users/${user.id}/edit`}>{user.fullName}</a>
                    {user.status !== 'active' && <span className="status"><small style={{marginLeft: '3px'}}>(Not Active)</small></span>}
                </td>
                <td className="email">{user.email}</td>
                <td className="last_login">
                    {user.lastLoginAt ? new Date(user.lastLoginAt).toLocaleString() : 'N/A'}
                </td>
                <td className="login_count">{user.loginCount}</td>
            </tr>
        ))}
        </tbody>
        <tfoot></tfoot>
    </table>
        </div>
            )
}

const Edit = ({user = {}, newUser = false}) => {
    const [formData, setFormData] = useState({
        givenName: user.givenName || '',
        familyName: user.familyName || '',
        email: user.email || '',
        password: '',
        passwordConfirmation: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        if (formData.password !== formData.passwordConfirmation) {
            alert('Passwords do not match');
            return;
        }

        Api[newUser ? 'post' : 'put'](`users${!newUser && '/'+user.id}`, formData)
            .then(() => {
                alert(`User ${newUser ? 'created' : 'updated'} successfully`)
                document.location.href = '/users'
            })
    };

    const handleDelete = () => {
        Api.delete(`users/${user.id}`, formData)
            .then(() => {
                // redirect to /users
                alert('User deleted successfully')
                document.location.href = '/users'
            })
            .catch((error) => {
                alert('Error deleting user: ' + error)
            })
    }

    return (
        <div>
            <h1>{newUser ? 'New User' : 'Edit User'}</h1>
            <form onSubmit={handleSubmit}>
                <div className="field">
                    <label>Given Name</label>
                    <input
                        type="text"
                        name="givenName"
                        value={formData.givenName}
                        onChange={handleChange}
                    />
                </div>
                <div className="field">
                    <label>Family Name</label>
                    <input
                        type="text"
                        name="familyName"
                        value={formData.familyName}
                        onChange={handleChange}
                    />
                </div>
                <div className="field">
                    <label>Email</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                    />
                </div>
                {!newUser && (
                    <p>Only enter a password to change the current one. Leave blank to keep current password.</p>
                )}
                <div className="field">
                    <label>Password</label>
                    <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                    />
                </div>
                <div className="field">
                    <label>Password Confirmation</label>
                    <input
                        type="password"
                        name="passwordConfirmation"
                        value={formData.passwordConfirmation}
                        onChange={handleChange}
                    />
                </div>
            </form>
            <div className="actions">
                <button onClick={handleSubmit} className="infraplan-button">{newUser ? 'Create' : 'Update'} user</button>
                <button
                    onClick={() => {
                        // redirect to /users
                        document.location.href = '/users'
                    }}
                    className="infraplan-button">Cancel</button>
                {!newUser && (
                    <button
                        onClick={() => {
                            handleDelete()
                        }}
                        className="infraplan-button">Delete</button>
                )}
            </div>
        </div>
    );
}

const fetchUser = (userId) => {
    return Api.get(`users/${userId}`)
}

const Users = ({action = 'show'}) => {
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, serError] = useState(null)
    const [user, setUser] = useState({})

    useEffect(() => {

        if (action === 'edit') {
            const userId = window.location.pathname.split('/').at(-2)
            fetchUser(userId).then((user) => {
                setUser(user)
            }).catch((error) => {
                serError("Error fetching user: " + error)
            }).finally(
                () => setLoading(false)
            )
            return
        }

        fetchUsers().then((r) => {
            setUsers(r)
            setLoading(false)
        }).catch((error) => {
            serError("Error fetching users: " + error)
        }).finally(
            () => setLoading(false)
        )
    }, [])

    if (loading) {
        return <div>Loading list of users</div>
    }

    if (error) {
        return <div>{error}</div>
    }

    switch (action) {
        case 'show':
            return <List users={users}/>
        case 'edit':
            return <Edit user={user}/>
        case 'new':
            return <Edit user={{}} newUser={true}/>
        default:
            return <div>Invalid action {action}</div>
    }
}

export default Users