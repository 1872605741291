import React from 'react'
import { map, compact } from 'lodash'
import memoize from 'lru-memoize'
import Textarea from 'react-textarea-autosize'

const valueToText = memoize(1)((value) => map(value, (v) => v.join('-')).join("\n"))

const textToValue = memoize(1)((text, minmax) => (
  compact(
    map(text.split('\n'), (line) => {
      let [from, to] = map(
        line.replace(/^[^\d.]*/, '').split(/[^\d.]+/),
        (v) => parseFloat(v)
      )

      if (Number.isNaN(from) || Number.isNaN(to)) { return null }

      from = Math.min(Math.max(from, minmax[0]), minmax[1])
      to = Math.min(Math.max(to, minmax[0]), minmax[1])

      return [from, to].sort((a, b) => a - b)
    })
  )
))

const valueToJson = memoize(1)((value) => JSON.stringify(value))

export default class PeriodsInput extends React.Component {
  state = {
    text: valueToText(this.props.value)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      const text = valueToText(nextProps.value)
      this.setState({ text })
    }
  }

  onChange = (e) => {
    this.setState({ text: e.currentTarget.value })
  }

  onBlur = () => {
    const { onChange, minmax } = this.props
    const { text } = this.state

    const value = textToValue(text, minmax)

    onChange(value)
  }

  render() {
    const {
      value, className, placeholder, name, disabled
    } = this.props
    const { text } = this.state

    const json = valueToJson(value)

    return (
      <React.Fragment>
        <Textarea
          value={ text }
          onChange={ this.onChange }
          onBlur={ this.onBlur }
          className={ className }
          placeholder={ placeholder }
        />
        <input type="hidden" name={ name } value={ json } disabled={ disabled } />
      </React.Fragment>
    )
  }
}
