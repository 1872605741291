import React from 'react'
import {
  map, memoize, keys, round, ceil, isEmpty
} from 'lodash'

import Chart from './Chart'
import getInterpolationFunctions from './getInterpolationFunctions'

const pluralize = (
  (n, singular, plural) => (n == 1 ? singular : plural) // eslint-disable-line eqeqeq
)

const getFirstPoint = memoize((break_rates) => (
  ceil(
    map(
      keys(break_rates), (br) => parseFloat(br)
    ).sort((a, b) => a - b)[0]
  )
))

export default class AgingCurve extends React.PureComponent {
  render() {
    const { prediction, relevanceYear } = this.props
    const interpolationFunc = getInterpolationFunctions(prediction)

    const { geometric_adjustment_factor } = prediction

    const firstPoint = getFirstPoint(prediction.break_rates)

    return (
      <div className="prediction-curve">
        <div className="row">
          <div className="col-8">
            <Chart
              name={ prediction.name }
              breakRateByAge={ interpolationFunc.breakRateByAge }
              breakRates={ prediction.break_rates }
            />
          </div>
          <div className="col-4 prediction-right-adjusted">
            { geometric_adjustment_factor && (
              <div className="prediction-curve-formula">
                <i>PBR</i>
                <sub>year&nbsp;n+1</sub>
                <span> = </span>
                <i>PBR</i>
                <sub>year&nbsp;n</sub>
                <span> × </span>
                <strong>{ round(geometric_adjustment_factor, 4) }</strong>
              </div>
            )}

            <SummaryStatistics
              name={ prediction.name }
              summary={ prediction.summary }
              relevanceYear={ relevanceYear }
            />

            <PredictCalculations
              breakRateByAge={ interpolationFunc.breakRateByAge }
              ageByBreakRate={ interpolationFunc.ageByBreakRate }
              minAge={ firstPoint }
            />
          </div>
        </div>

      </div>
    )
  }
}

class PredictCalculations extends React.PureComponent {
  state = this.getStateFromAge(this.props.minAge)

  componentDidUpdate(prevProps) {
    if (prevProps.breakRateByAge !== this.props.breakRateByAge) {
      this.setState( // eslint-disable-line react/no-did-update-set-state
        ({ ageValue }) => this.getStateFromAge(ageValue)
      )
    }
  }

  changeAgeInput = (e) => {
    this.setState(
      this.getStateFromAge(e.currentTarget.value)
    )
  }

  getStateFromAge(value) {
    const { breakRateByAge } = this.props

    let breakRateValue = round(breakRateByAge(value), 3)
    if (Number.isNaN(breakRateValue)) {
      breakRateValue = ''
    }

    return {
      ageValue: value,
      breakRateValue
    }
  }

  changeBreakRateInput = (e) => {
    this.setState(
      this.getStateFromBreakRate(e.currentTarget.value)
    )
  }

  getStateFromBreakRate(value) {
    const { ageByBreakRate } = this.props

    let ageValue = round(ageByBreakRate(value), 2)
    if (Number.isNaN(ageValue)) {
      ageValue = ''
    }

    return {
      breakRateValue: value,
      ageValue
    }
  }

  render() {
    const { ageValue, breakRateValue } = this.state
    const { minAge } = this.props

    return (
      <div className="prediction-calc">
        <span className="prediction-calc-section">
          <span className="prediction-calc-top">PBR at age of </span>
          <input
            type="number"
            min={ minAge }
            value={ ageValue }
            onChange={ this.changeAgeInput }
            onFocus={ this.setAgePrimary }
          />
          <span className="prediction-calc-bottom">{ pluralize(ageValue, ' year ', ' years ') }</span>
        </span>
        <span className="prediction-calc-equal"> = </span>
        <span className="prediction-calc-section">
          <input
            type="number"
            step="0.01"
            min="0"
            value={ breakRateValue }
            onChange={ this.changeBreakRateInput }
            onFocus={ this.setBreakRatePrimary }
          />
          <span className="prediction-calc-bottom">breaks / mile / year</span>
        </span>
      </div>
    )
  }
}

class SummaryStatistics extends React.PureComponent {
  render() {
    const {
      relevanceYear, summary
    } = this.props

    const {
      num_breaks,
      total_length_used,
      total_length_now,
      average_age,
      break_rate,
      recent_break_rate
    } = summary

    if (isEmpty(summary)) return null

    return (
      <div className="prediction-summary-table">
        <table>
          <thead>
            <tr>
              <th colSpan="2">
                Summary as of { relevanceYear }
              </th>
            </tr>
          </thead>
          <tbody>
            { total_length_used !== undefined && (
              <tr>
                <td>Length ACT and ABN pipes used for project</td>
                <td>{ total_length_used.toFixed(2) }</td>
              </tr>
            )}

            { total_length_now !== undefined && (
              <tr>
                <td>Length as of now</td>
                <td>{ total_length_now.toFixed(2) }</td>
              </tr>
            )}

            { num_breaks !== undefined && (
              <tr>
                <td>Number of breaks used for project</td>
                <td>{ num_breaks.toFixed(0) }</td>
              </tr>
            )}

            { break_rate !== undefined && (
              <tr>
                <td>Average Yearly Break Rate</td>
                <td>{ break_rate.toFixed(3) }</td>
              </tr>
            )}

            { recent_break_rate !== undefined && (
              <tr>
                <td>Average Break Rate over last 5 complete years of breaks</td>
                <td>{ recent_break_rate.toFixed(3) }</td>
              </tr>
            )}

            { average_age !== undefined && (
              <tr>
                <td>Average Age<br /><em>as of now for ACT pipes, as of YOA for ABN pipes</em></td>
                <td>{ average_age.toFixed(1) }</td>
              </tr>
            ) }
          </tbody>
        </table>
      </div>
    )
  }
}
