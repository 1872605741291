import React from 'react'
import { map } from 'lodash'

export default class RadioButtons extends React.Component {
  onChange = (e) => {
    const { onChange } = this.props

    if (onChange) {
      onChange(e.currentTarget.value)
    }
  }

  render() {
    const { options, name, value } = this.props

    return (
      <div className="field radiogroup">
        <div className="values">{ map(options, ([label, option_value]) => (
          <React.Fragment key={ option_value }>
            <label>
              <input
                type="radio"
                name={ name }
                value={ option_value }
                checked={ option_value === value }
                onChange={ this.onChange }
              /> { label }
            </label>
          </React.Fragment>
        )) }</div>
      </div>
    )
  }
}
