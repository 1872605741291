import React, {useState} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function ({url, title, icon, action}) {
    const [isHovered, setIsHovered] = useState(false)

    return (
        <a href={url}
           style={{textDecoration: 'none'}}
           onClick={(event) => {
               if (action) {
                   action(event)
               }
           }}
        >
            <div
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={{
                    listStyleType: 'none',
                    padding: '0.2rem',
                    paddingRight: '1rem',
                    paddingLeft: '1rem',
                    alignItems: 'center',
                    borderRadius: '5px',
                    lineHeight: '1.5',
                    margin: '0.4rem',
                    display: 'flex',
                    textAlign: 'start',
                    textDecoration: 'none',
                    color: isHovered ? "#fff" : '#131313',
                    backgroundImage: isHovered ? 'linear-gradient(#155A98, #1B71BE)' : 'linear-gradient(transparent, transparent)',
                }}>
                {icon && <FontAwesomeIcon style={{
                    width: '1rem',
                    height: '1rem',
                    marginRight: '1.4rem'
                }} icon={icon}/>}
                <span
                    style={{
                        color: isHovered ? "#fff" : '#131313',
                        textDecoration: 'none'
                    }}
                >{title}</span>
            </div>
        </a>
    )
}