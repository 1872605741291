import React from 'react'
import { map } from 'lodash'
import PeriodsInput from '../../../ChartForm/PeriodsInput'

const { $ } = window

const LOADING = (
  <div className="calibration-loading">
    <i className="icon big spinner spin" />
  </div>
)

const Error = ({ retry }) => (
  <div>
    <div className="alert alert-danger">
      Something is wrong.
    </div>
    <button
      type="button"
      className="btn btn-primary btn-sm"
      onClick={ retry }
    >Retry</button>
  </div>
)

export default class Regrouping extends React.Component {
  state = {
    loading: false,
    error: false,
    can_be_split: undefined,
    can_be_used: undefined
  }

  componentDidMount() {
    this.runTests()
  }

  decidedNotToSplit = () => this.setState({ can_be_split: false })

  componentDidUpdate(prevProps) {
    if (prevProps.groups !== this.props.groups) {
      this.runTests()
    }
  }

  runTests = () => {
    const { groups } = this.props

    if (!groups || !groups.length) {
      this.setState({
        can_be_split: undefined, can_be_used: undefined, error: false, loading: false
      })
      return
    }

    this.setState({
      can_be_used: undefined, can_be_split: undefined, error: false, loading: true
    })

    $.post(this.props.run_tests_url, { groups: JSON.stringify(groups) }).then(
      ({ result: { can_be_used, can_be_split }}) => {
        this.setState(({
          loading: false,
          can_be_used,
          can_be_split
        }))
      },
      () => this.setState({ error: true, loading: false })
    )
  }

  onChange = (value) => this.props.onChange(this.props.index, value)

  cancel = () => this.props.cancel(this.props.index)

  save = () => this.props.save(this.props.index)

  render() {
    const {
      groups,
      form: Form,
      submitting,
      label,
      base_variable: { name }
    } = this.props

    const {
      error, loading, can_be_used, can_be_split
    } = this.state

    let buttons
    if (error) {
      buttons = <Error retry={ this.runTests } />
    } else if (loading) {
      buttons = LOADING
    } else if (can_be_split) {
      buttons = (
        <>
          <p>
            Internal computations show that the cohort could be split
            based on the discretized { name }.  We could now have the following cohorts:
          </p>

          <ul>
            { map(groups, (group) => (
              <li>{ label } ({ name } { group.join('-') })</li>
            ))}
          </ul>

          <p>
            You may also choose not to split the cohort based on that
            discretized variable and introduce it as a co-variate in the model.
          </p>

          <Form>
            <input type="hidden" name="complete[split_on_groups]" value={ JSON.stringify(groups) } />
            <button
              type="submit"
              className="btn btn-primary"
              disabled={ submitting }
            >Split</button>
          </Form>
          <button
            type="button"
            className="btn btn-primary"
            disabled={ submitting }
            onClick={ this.decidedNotToSplit }
          >Do not split based on discretized { name }</button>
        </>
      )
    } else if (can_be_used === true) {
      buttons = (
        <>
          <p>
            Internal computations show that it is recommended
            to introduce the discretized { name } in the model.
          </p>
          <div className="row calibration-variables-variable-buttonsRow">
            <div className="col-6">
              <button
                type="button"
                className="btn btn-primary"
                disabled={ submitting }
                onClick={ this.save }
              >Ok</button>
            </div>
          </div>
        </>
      )
    } else if (can_be_used === false) {
      buttons = (
        <>
          <p>
            Internal computations show that it is not recommended to introduce
            the discretized variable (say name) in the model.
            You may choose a different discretization or choose
            to introduce the variable in the COHORT model anyway.
          </p>
          <button
            type="button"
            className="btn btn-primary"
            disabled={ submitting }
            onClick={ this.cancel }
          >Go back</button>
          <button
            type="submit"
            className="btn btn-secondary btn-sm"
            disabled={ submitting }
            onClick={ this.save }
          >Add anyway</button>
        </>
      )
    } else {
      buttons = (
        <button
          type="submit"
          className="btn btn-secondary btn-sm"
          disabled={ submitting }
          onClick={ groups.length > 0 ? this.save : this.cancel }
        >← Go back</button>
      )
    }

    return (
      <>
        <div className="calibration-info">
          <p>
            It is recommended that a new { name } with regrouped modalities
            (VARn, n is the number of new modalities) be created so that:
          </p>
          <ul>
            <li>
              Each newly created modality has a length of at least 10% of the total length
            </li>
            <li>
              The Average Yearly Break Rate of the modalities to be regrouped are similar
            </li>
            <li>
              The Average Yearly Break Rate of the regrouped modalities differ
              by a ratio of at least 1.1
            </li>
          </ul>

          <div className="form-group">
            <PeriodsInput
              name="complete[diameter_ranges]"
              minmax={ [0, 100] }
              value={ groups }
              onChange={ this.onChange }
              className="form-control"
            />
          </div>
        </div>
        <div className="calibration-submit">
          { buttons }
        </div>
      </>
    )
  }
}
