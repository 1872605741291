import React from 'react'
import Modal from '../Modal'

const { QueryBuilder } = window

const queryBuilderNode = document.getElementById('react-query-builder')

const tmpNode = document.createElement('div')
tmpNode.style.display = 'none'
document.body.appendChild(tmpNode)

class QueryBuilderNode extends React.Component {
  ref = React.createRef()

  componentDidMount() {
    this.ref.current.appendChild(queryBuilderNode)
    queryBuilderNode.style.display = ''
  }

  componentWillUnmount() {
    tmpNode.appendChild(queryBuilderNode)
  }

  render() {
    return <div ref={ this.ref } />
  }
}

export default class QueryBuilderInput extends React.PureComponent {
  state = {
    isOpen: false
  }

  componentDidMount() {
    if (this.props.value) {
      QueryBuilder.setData(this.props.value)
    }
  }

  open = () => this.setState({ isOpen: true })

  close = () => {
    const value = QueryBuilder.getData()

    if (value && value.predicates && value.predicates.length === 0) {
      this.props.onChange(undefined)
    } else {
      this.props.onChange(value)
    }

    this.setState({ isOpen: false })
  }

  render() {
    const { value } = this.props
    const { isOpen } = this.state

    return (
      <React.Fragment>
        <button
          type="button"
          className={ `btn btn-xs btn-primary` }
          onClick={ this.open }
        >Query Builder { value ? ` (${value.predicates.length})` : '' }</button>
        <Modal
          isOpen={ isOpen }
          onRequestClose={ this.close }
        >
          <QueryBuilderNode />
          <div className="reactModal-buttons">
            <button
              type="button"
              className="btn btn-xs btn-primary"
              onClick={ this.close }
            >Apply</button>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}
