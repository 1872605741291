import React, {useEffect, useRef, useState} from 'react';
import {basicBtn} from "./StyleConstants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default ({iconSize, text, icon, index, iconOnly, isActive, href}) => {
    const [showing, setShowing] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const dropdownStyle = {
        background: (isHovered && !iconOnly) || showing || isActive ? "#fff" : "#f3f3f3",
        color: '#181818',
        fontWeight: showing || isActive ? '700' : '400',
        borderTopRightRadius: '5px',
        borderTopLeftRadius: '5px',
        borderBottomRightRadius: showing ? '0px' : '5px',
        borderBottomLeftRadius: showing ? '0px' : '5px',
        cursor: basicBtn.cursor,
        justifyContent: basicBtn.justifyContent,
        alignItems: basicBtn.alignItems,
        padding: basicBtn.padding,
        display: "flex",
        gap: '10px',
        zIndex: 100,
        position: 'relative',
        fontSize: '13pt',
        textDecoration: 'none'
    }

    return (
        <a
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            key={index}
            href={href}
            style={{textDecoration: 'none'}}
        >
            <div style={dropdownStyle} key={index}>
                <FontAwesomeIcon style={{
                    fontSize: iconSize ? iconSize : 17,
                    color: isHovered || showing || isActive ? '#1b71be' : '#181818'
                }} icon={icon}></FontAwesomeIcon>
                {text}
            </div>
        </a>
    )
}

