import React from 'react'
import c from 'classnames'

export default class Columm extends React.PureComponent {
  onMouseOver = () => {
    if (this.props.highlightColumn) { this.props.highlightColumn(this.props.name) }
  }

  onMouseOut = () => {
    if (this.props.highlightColumn) { this.props.highlightColumn(null) }
  }

  render() {
    const {
      name, children, className, wide, wider
    } = this.props
    const highlighted = name && (name === this.props.highlightedColumn)

    return (
      <div
        className={ c(
          'cohorts-cohort-column',
          wide && 'wide',
          wider && 'wider',
          className,
          highlighted && 'cohorts-cohort-column-highlighted'
        ) }
        onMouseOver={ this.onMouseOver }
        onMouseOut={ this.onMouseOut }
        onFocus={ this.onMouseOver }
        onBlur={ this.onMouseOut }
      >{ children }</div>
    )
  }
}
