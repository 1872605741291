import React from 'react'
import {
    faCog,
    faList,
    faUserCheck,
    faUsers
} from "@fortawesome/free-solid-svg-icons";
import Button from "./Button";

export default ({projectId}) => {
    let menuItems = [
        {
            title: 'Projects', icon: faList, url: ['/projects']
        },
        {
            title: 'Users', icon: faUsers, url: ['/users']
        },
        {
            title: 'Permissions', icon: faUserCheck, url: ['/permissions']
        },
        {
            title: 'Settings', icon: faCog, url: ['/settings']
        }
    ]

    const isInCurrentUrl = (menu) => menu.url.map(u => {
        let path = window.location.pathname
        if(path === '/' && u === '/projects') return true
        return (path.includes(u))
    }).find(t => t)

    return (
        <ul
            style={{
                display: "flex",
                justifyContent: "space-between",
                gap: '0.5rem'
            }}
        >
            {menuItems.map((menu, index) => {
                return <Button
                    text={menu.title}
                    isActive={isInCurrentUrl(menu)}
                    icon={menu.icon}
                    href={menu.url[0]}
                    key={index}>
                </Button>
            })}
        </ul>
    )
}