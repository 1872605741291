import React from 'react'
import {useEffect, useState} from "react";

const RegisterCode = () => {
    const [code, setCode] = useState('')

    useEffect(() => {
        Api.get('users/register/validate-token').then(
            (res) => {
                setCode(res.token)
            }
        ).catch(
            (err) => {
                console.error(err)
            })
    }, [])

    return (
        <div>
            <h1>Register Code</h1>
            <p>Send this link to the new user: {`${window.location.host}/register?token=${code}`}</p>
        </div>
    )
}

export default RegisterCode;