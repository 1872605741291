import React, { useState, useCallback } from 'react'

export default ({
  value, onChange: _onChange
}) => {
  const [open, setOpen] = useState(!!(value && value !== '0'))

  const onChange = useCallback((e) => {
    _onChange(e.currentTarget.value)
  }, [_onChange])

  const onToggle = useCallback((e) => {
    const { currentTarget: { checked }} = e

    if (checked) {
      setOpen(true)

      if (!value && value !== 0) {
        _onChange(8)
      }
    } else {
      setOpen(false)
      _onChange(0)
    }
  })

  return (
    <div className="form-group">
      <label>
        <input
          type="checkbox"
          checked={ open }
          onChange={ onToggle }
        /> { open ? 'Smoothen by ' : 'Smoothen' }
      </label>
      { open && (
        <input
          type="text"
          className="form-control"
          value={ value }
          onChange={ onChange }
        />
      )}
    </div>
  )
}
