import React from 'react'
import FileUploader from "../Misc/fileUploader";
import Section from "./Section";

export default ()=>
    <Section title={"Import costs/cofs"}
             description={"This allows for submitting a file to update the costs/cofs for all existing pipes"}
        >
            <FileUploader
                onFileSelectError={({error})=>alert(error)}
                accept={[".csv"]}
                maxSize={20480}
                onFileUpload={async (file)=>{
                    helpers.showProgress(true, {
                        message: ("importing costs and cofs...")
                    });

                    try {
                        await Api.upload(`projects/${Api.getProjectId()}/pipes/costs/csv/up`, file);
                        toastr.success("costs and cofs were updated!");
                    } catch (error) {
                        toastr.error("an error occured when updating costs and cofs");
                        console.error(error);
                    } finally {
                        helpers.showProgress(false);
                    }
                }}
            />
    </Section>;