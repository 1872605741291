import React from 'react'
import { debounce, mapValues, isObject } from 'lodash'
import c from 'classnames'
import qs from 'qs'
import shallowEqual from '../../utils/shallowEqual'

export default class Chart extends React.Component {
  state = {
    loading: true,
    src: this.getSrc(this.props)
  }

  setLoading = () => this.setState({ loading: true })

  onLoaded = () => this.setState({ loading: false })

  componentDidUpdate(prevProps, prevState) {
    if (prevState.src !== this.state.src) {
      this.setLoading()
    }

    if (!shallowEqual(prevProps, this.props)) {
      this.debouncedUpdateSrc()
    }
  }

  getSrc() {
    const { base_url, ...params } = this.props

    const paramsString = qs.stringify(
      mapValues(params, (value) => (
        isObject(value) ? JSON.stringify(value) : value
      ))
    )

    return `${base_url}?${paramsString}`
  }

  updateSrc = () => this.setState({ src: this.getSrc() })
  debouncedUpdateSrc = debounce(this.updateSrc, 500)

  render() {
    const { src, loading } = this.state

    if (!src) return null

    return (
      <iframe
        title="Chart"
        src={ src }
        className={ c(
          'calibration-regroup-mat-iframe',
          loading && 'calibration-regroup-mat-iframe--loading'
        ) }
        onLoad={ this.onLoaded }
      />
    )
  }
}
