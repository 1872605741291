import {faChartPie, faHandSparkles, faHatWizard, faListCheck} from "@fortawesome/free-solid-svg-icons";

export default ()=>[
    {
        title: 'Clean',
        icon: faHandSparkles,
        options: [
            { title: 'Summary', url: [
                    '/projects/:projectId/summary',
                    '/projects/:projectId/issues/*',
                    '/projects/:projectId/filters/*',
                    '/projects/:projectId/remedies/*'
                ]},
            { title: 'Pipes', url: '/projects/:projectId/pipes' },
            { title: 'Breaks', url: '/projects/:projectId/breaks' },
            { title: 'Map', url: '/projects/:projectId/pipes/map' },
            { title: 'Missing Values', url: '/projects/:projectId/ml_missing_values' },
            { title: 'Assign Pipes to Breaks', url: '/projects/:projectId/assign_breaks' },
            { title: 'Tasks', url: '/projects/:projectId/tasks'},
        ]
    },
    {
        title: 'Stats',
        icon: faChartPie,
        options: [
            { title: 'Charts', url: '/projects/:projectId/charts/*' },
            { title: 'Variables', url: '/projects/:projectId/covariates' }
        ]
    },
    {
        title: 'Predict',
        icon: faHatWizard,
        options: [
            { title: 'Cohorts', url: '/projects/:projectId/cohorts' },
            { title: 'Validation', url: '/projects/:projectId/ml_calibration' },
            { title: 'Prediction', url: '/projects/:projectId/ml_predictions' },
            { title: 'Aging Curves', url: '/projects/:projectId/ml_aging_curves' }
        ]
    },
    {
        title: 'Plan',
        icon: faListCheck,
        options: [
            { title: 'Scenario Simulation', url: '/projects/:projectId/plan' },
            { title: 'COF/Cost Import', url: '/projects/:projectId/cost_import' }
        ]
    }
];
