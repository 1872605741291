import React, { useState } from "react";

const AddUserModal = ({ projectId, users, close }) => {
    const [selectedUserId, setSelectedUserId] = useState("");
    const [error, setError] = useState("");

    const handleAddUser = () => {
        if (!selectedUserId) {
            setError("Please select a user.");
            return;
        }

        const selectedUser = users.find(user => user.id === selectedUserId);

        Api.post('permissions', {
            projectId,
            userId: selectedUser.id
        })
            .then(() => alert("User successfully added to permission"))
            .catch((error) => {
                console.error("Error adding user to permission: " + error);
                setError("Failed to add user. Try again.");
            })
            .finally(() => close());
    };

    const handleChange = (e) => {
        setSelectedUserId(e.target.value);
        setError("");  // Clear error on user selection
    };

    return (
        <div className="add-user-container p-3 bg-light rounded border">
            <h6>Add User to Project</h6>
            <div className="form-group d-flex align-items-center gap-3">
                <select
                    className={`form-select ${error ? 'is-invalid' : ''}`}
                    value={selectedUserId}
                    onChange={handleChange}
                >
                    <option value="" disabled>Select a user</option>
                    {users.map(user => (
                        <option key={user.id} value={user.id}>
                            {user.fullName}
                        </option>
                    ))}
                </select>
                <button className="btn btn-primary" onClick={handleAddUser}>
                    Add User
                </button>
            </div>
            {error && <div className="text-danger mt-2">{error}</div>}
            <button className="btn btn-link text-secondary mt-2" onClick={close}>
                Cancel
            </button>
        </div>
    );
};

export default AddUserModal;
