import React, {useEffect} from 'react'
import DropdownButton from "./DropdownButton";
import {faCircleUser, faCog, faFolder, faKey, faRightFromBracket, faUsers} from "@fortawesome/free-solid-svg-icons";
import ProjectHeader from "./ProjectHeader";
import ListItem from "./ListItem";
import ProjectSettingsButton from "./ProjectSettingsButton";
import UserHeader from "./UserHeader";
import {auth} from "../../utils/firebase";

export default ({user, project}) => {
    const dropdownItems = [
        {title: 'Projects', url: '/projects', icon: faFolder},
        {title: 'Users', url: '/users', icon: faUsers},
        {title: 'Permissions', url: '/permissions', icon: faKey},
        {title: 'Settings', url: '/settings', icon: faCog},
        {
            title: 'Logout', url: '#', action: (e) => {
                e.preventDefault()
                auth.signOut().then(
                    () => Api.logout()
                )
                document.querySelector('#logout').click();
            }, icon: faRightFromBracket
        },
    ]

    useEffect(() => {
        const unsubscribe =  auth.onAuthStateChanged(async (_user) => {
            if (window.location.pathname.includes('register')) return
            if (window.location.pathname.includes('login')) return

            if (!_user) {
                Api.logout()
                document.querySelector('#logout').click();
            } else {
                const currToken = Cookies.get('authToken');
                const newToken = await _user.getIdToken();
                Cookies.set('authToken', newToken);
                if (currToken !== newToken) {
                    console.log('Token refreshed')
                    window.location.reload()

            }}
        })

        return () => unsubscribe()
    }, [])

    return (
        <header style={{
            padding: '1rem',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            height: '4.5rem',
            background: '#f3f3f3',
            gap: '2rem'
        }}>
            <div style={{
                display: 'flex',
                alignItems: 'baseline',
            }}>
                <h1 style={{userSelect: "none"}} className={'brand'}>infra<b>SOFT</b></h1>
                {project &&
                    <>
                        <h4>{project.name}</h4>
                        <ProjectSettingsButton projectId={project.id}/>
                    </>
                }
            </div>
            {project ? <ProjectHeader projectId={project.id}/> : user && <UserHeader/>}
            {user &&
                <div style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
                    <span style={{fontSize: 16}}>{user.name}</span>
                    <DropdownButton icon={faCircleUser} iconSize={26} iconOnly={true}>
                        <ul style={{
                            position: "absolute",
                            background: "#fff",
                            padding: '0.2rem',
                            borderTopLeftRadius: '5px',
                            borderBottomLeftRadius: '5px',
                            borderBottomRightRadius: '5px',
                            boxShadow: "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
                            zIndex: 99,
                            right: '1rem'
                        }}>
                            {project ? dropdownItems.map((dropDownItem, index) =>
                                    <ListItem title={dropDownItem.title} url={dropDownItem.url} key={index}
                                              icon={dropDownItem.icon} action={dropDownItem.action}/>
                                ) : <ListItem title={dropdownItems[dropdownItems.length-1].title}
                                              url={dropdownItems[dropdownItems.length-1].url}
                                              icon={dropdownItems[dropdownItems.length-1].icon}
                                              action={dropdownItems[dropdownItems.length-1].action}/>}
                        </ul>
                    </DropdownButton>
                </div>
            }
        </header>
    )
}