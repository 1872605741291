import React, {useEffect, useRef, useState} from 'react';
import {basicBtn} from "./StyleConstants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {listenForOutsideClicks} from "./listenToOutsideClick";

export default ({children, iconSize, text, icon, index, iconOnly, isActive}) => {
    const [showing, setShowing] = useState(false);
    const [listening, setListening] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const dropdownRef = useRef(null);

    const dropdownStyle = {
        background: (isHovered && !iconOnly) || showing || isActive ? "#fff" : "#f3f3f3",
        color: '#181818',
        fontWeight: showing || isActive ? '700' : '400',
        borderTopRightRadius: '5px',
        borderTopLeftRadius: '5px',
        borderBottomRightRadius: showing ? '0px' : '5px',
        borderBottomLeftRadius: showing ? '0px' : '5px',
        cursor: basicBtn.cursor,
        justifyContent: basicBtn.justifyContent,
        alignItems: basicBtn.alignItems,
        padding: basicBtn.padding,
        display: "flex",
        gap: '5px',
        zIndex: 100,
        position: 'relative',
        fontSize: '13pt',
        userSelect: 'none'
    }

    const toggleDropdown = () => {
        setShowing((prev) => !prev);
    }

    useEffect(listenForOutsideClicks(listening, setListening, dropdownRef, setShowing));

    return (
        <div
            ref={dropdownRef}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => toggleDropdown()}
            key={index}
        >
            <div style={dropdownStyle} key={index}>
                <FontAwesomeIcon style={{
                    fontSize: iconSize ? iconSize : 17,
                    color: isHovered || showing || isActive ? '#1b71be' : '#181818'
                }} icon={icon}></FontAwesomeIcon>
                {text}
            </div>
            {
                showing &&
                children
            }
        </div>
    )
}

