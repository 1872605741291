import React, { useState, useEffect } from 'react'
import MLStatus from '../MLStatus'
import PredictionsTable from '../MLPredictionsTable'

function roundUp(n, decimalPlaces) {
    const multiplier = Math.pow(10, decimalPlaces)
    return Math.ceil(n * multiplier) / multiplier
}

export default function MLMissingValues(props) {
    const projectId = Api.getProjectId()

    const [results, setResults] = useState({})
    const [predPipes, setPredPipes] = useState([])
    const [valPipes, setValPipes] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [resultsExist, setResultsExist] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        setResults({})
        Api.get(`projects/${projectId}/ml/missing_values_validation`)
          .then(x => {
            setResults(x)
            setResultsExist(true)
            setIsLoading(false)
          })
          .catch(error => {
            // toastr.error("Could not fetch validation results!")
            console.error(error)
            setResultsExist(false)
            setIsLoading(false)
          })
      }, [])
    
      useEffect(() => {
        setPredPipes([])
        Api.get(`projects/${projectId}/ml/missing_values_pipes`, {type: "prediction"})
          .then(x => setPredPipes(x))
          .catch(error => {
            setPredPipes([])
            // toastr.error("Could not fetch pipes!")
            console.error(error)
          })
      }, [])

      useEffect(() => {
        setValPipes([])
        Api.get(`projects/${projectId}/ml/missing_values_pipes`, {type: "validation"})
          .then(x => setValPipes(x))
          .catch(error => {
            setValPipes([])
            // toastr.error("Could not fetch pipes!")
            console.error(error)
          })
      }, [])

    // TODO: coupling but oh well
    const baseRef = `/projects/${projectId}/pipes/`
    const predCols = [
        {
            Header: "ID",
            accessor: "Utility Pipe ID",
            Cell: props => <a href={baseRef + props.row.original.ID}>{props.value ? props.value : "No ID - " + props.row.original.ID}</a>
        }
    ]
    if (resultsExist) {
        if (!results["Diameter"]["error"]) {
            predCols.push({
                Header: "Predicted Diameter",
                accessor: "Predicted Diameter",
                Cell: props => isNaN(props.value) ? props.value : props.value?.toFixed(1) || "Known"
            })
        }
        if (!results["Material"]["error"]) {
            predCols.push({
                Header: "Predicted Material",
                accessor: "Predicted Material",
                Cell: props => isNaN(props.value) ? props.value : props.value ? props.value : "Known"
            })
        }
        if (!results["Year of Installation"]["error"]) {
            predCols.push({
                Header: "Predicted Year of Installation",
                accessor: "Predicted Year of Installation",
                Cell: props => isNaN(props.value) ? props.value : props.value?.toFixed(1) || "Known"
            })
        }
    }

    const valCols = [
        {
            Header: "ID",
            accessor: "Utility Pipe ID",
            Cell: props => <a href={baseRef + props.row.original.ID}>{props.value ? props.value : "No ID - " + props.row.original.ID}</a>
        }
    ]
    if (resultsExist) {
        if (!results["Diameter"]["error"]) {
            valCols.push({
                Header: "Actual Diameter",
                accessor: "Diameter",
                Cell: props => props.value?.toFixed(1) || "-"
            })
            valCols.push({
                Header: "Predicted Diameter",
                accessor: "Validation Diameter",
                Cell: props => props.value?.toFixed(1) || "Not Used"
            })
        }
        if (!results["Material"]["error"]) {
            valCols.push({
                Header: "Actual Material",
                accessor: "Material",
                Cell: props => props.value ? props.value : "-"
            })
            valCols.push({
                Header: "Predicted Material",
                accessor: "Validation Material",
                Cell: props => props.value ? props.value : "Not Used"
            })
        }
        if (!results["Year of Installation"]["error"]) {
            valCols.push({
                Header: "Actual Year of Installation",
                accessor: "Year of Installation",
                Cell: props => props.value?.toFixed(0) || "-"
            })
            valCols.push({
                Header: "Predicted Year of Installation",
                accessor: "Validation Year of Installation",
                Cell: props => props.value?.toFixed(1) || "Not Used"
            })
        }
    }

    return (
        <>
        <MLStatus disabled={isLoading} pipelineType="missing_values" />
        {!isLoading && !resultsExist && <p>No machine learning pipeline runs were found for this project. Please run the pipeline and wait for results.</p>}
        {!isLoading && resultsExist && 
            <>
                <hr />
                <h3>Validation Report</h3>
                <p>Validation conducted on the population of pipes not missing the variable. We remove the (actual) value for 20% of those pipes, predict it with our model and compare predicted and actual values.</p>
                <div>
                    {Object.keys(results).filter((k) => k != "project_id" && !results[k]["error"]).map((k) => 
                        <div key={k} style={{marginTop: "20px"}}>
                            <h5>{k}</h5>
                            <span>Missing in {results[k]["missing_ratio_count"] < 0.0001 ? "less than" : ""} {roundUp(results[k]["missing_ratio_count"] * 100.0, 2)}% of pipes and {results[k]["missing_ratio_length"] < 0.0001 ? "less than" : ""} {roundUp(results[k]["missing_ratio_length"] * 100.0, 2)}% of length.</span><br />
                            {results[k]["validation_accuracy"] && 
                                <>
                                    <span>Validation Accuracy: <b>{(results[k]["validation_accuracy"] * 100).toFixed(1)}%</b></span><br />
                                    <span>This value is {
                                        Object.keys(results[k]).filter(
                                            (e) => e.includes("missing_") && !e.includes("ratio")
                                        ).reduce((acc, e) => acc + `${(results[k][e] * 100).toFixed(1)}% if missing ${e.split("missing_")[1].split("-")[0]} other value${e.split("missing_")[1].split("-")[0] == 1 ? "" : "s"}. `, "")}</span>
                                    {/* <span>Baseline Accuracy: <b>{(results[k]["baseline_accuracy"] * 100).toFixed(1)}%</b></span> */}
                                </>
                            }
                            {results[k]["validation_mae"] &&
                                <>
                                    {/* <span>Validation R2 Score: <b>{results[k]["validation_r2_score"].toFixed(2)}</b></span><br /> */}
                                    <span>Validation Mean Absolute Error: <b>{results[k]["validation_mae"].toFixed(2)}</b></span><br />
                                    <span>This value is {Object.keys(results[k]).filter((e) => e.includes("missing_") && !e.includes("ratio")).reduce((acc, e) => acc + `${results[k][e].toFixed(2)} if missing ${e.split("missing_")[1].split("-")[0]} other value${e.split("missing_")[1].split("-")[0] == 1 ? "" : "s"}. `, "")}</span>
                                    {/* <span>Baseline Mean Absolute Error: <b>{results[k]["baseline_mae"].toFixed(2)}</b></span><br /> */}
                                </>
                            }
                        </div>
                    )}
                </div>
                <hr />
                <h4>Validation Pipes</h4>
                <PredictionsTable data={valPipes} initColumns={valCols} downloadable={true} />
                <hr />
                <h3>Prediction</h3> 
                <PredictionsTable data={predPipes} initColumns={predCols} downloadable={true} />
            </>
        }
        </>
    )
}