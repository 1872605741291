import React from 'react'
import CalibrationSubmitter from './Submitter'

export default class MaybeSplitOnYoiIsLengthSteady extends React.PureComponent {
  onSubmitSuccess = (artefacts) => {
    if (artefacts.ok) {
      return (
        <p>The program of abandonment justifies the trend.</p>
      )
    }

    if (artefacts.not_ok) {
      return (
        <p>
          Internal computations show that the program of
          abandonment does not justify the unexpected trend.
          The cohort can be modeled but expect possibly
          unacceptable results for the model.
        </p>
      )
    }
  }
  render() {
    return (
      <CalibrationSubmitter
        onSuccess={ this.onSubmitSuccess }
      >{ ({ Form, submitting }) => (
        <>
          <div className="calibration-info">
            <div className="form-group">
              <p>The above Chart shows:</p>
              <ul>
                <li>x-axis: YEAR</li>
                <li>
                  Left y-axis: Current Average Break Rate of the pipes that were abandoned at YEAR
                </li>
                <li>
                  Right y-axis: Current length of the pipes that were abandoned at YEAR
                </li>
              </ul>
              <p>
                Using the bottom ruler, select the YEARs for which length is substantial.
                If necessary, observe limited periods of YEAR that do not include peak
                Average Yearly Break Rate values.
              </p>

              <p>Is length of abandonment steady over time or has abandonment begun early?</p>
            </div>
          </div>
          <div className="calibration-submit">
            <div className="row">
              <div className="col-6">
                <Form>
                  <input type="hidden" name="complete[ok]" value="1" />
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg"
                    disabled={ submitting }
                  >Yes</button>
                </Form>
              </div>
              <div className="col-6">
                <Form>
                  <input type="hidden" name="complete[not_ok]" value="1" />
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg"
                    disabled={ submitting }
                  >No</button>
                </Form>
              </div>
            </div>
          </div>
        </>
      )}</CalibrationSubmitter>
    )
  }
}
