import React, { useCallback } from 'react'
import {
  compact, get, last, filter, map, cloneDeep, uniqBy
} from 'lodash'
import memoize from 'lru-memoize'

import Select from '../../Select'

const getCohortOptions = memoize()((cohorts) => [
  ['All', ''],
  ...map(cohorts, ({ name }) => ([name, name]))
])

const optionsWithMissingValue = memoize()((options, value) => (
  uniqBy(compact([
    ...options,
    value ? [`${value} (!)`, value] : null
  ]), ([, v]) => v)
))

const EXAMPLE = [
  {
    years: '2020-2030',
    cohorts: null,
    limit: '50'
  },
  {
    years: '2031-2040',
    cohorts: null,
    limit: '30'
  }
]

const TextInput = React.forwardRef(({
  value, onChange: _onChange, onEnter, onEsc, onKeyDown: _onKeyDown, ...props
}, ref) => {
  const onChange = useCallback(
    ({ currentTarget: { value: newValue }}) => _onChange(newValue),
    [_onChange]
  )

  const onKeyDown = useCallback((e) => {
    if (onEnter && e.keyCode === 13) {
      e.preventDefault()
      onEnter()
    }

    if (!value && onEsc && (e.keyCode === 27 || e.keyCode === 8)) {
      e.preventDefault()
      onEsc()
    }

    if (_onKeyDown) { _onKeyDown(e) }
  }, [value, onEsc, onEnter])

  return (
    <input
      { ...props }
      type="text"
      className="form-control"
      value={ value }
      disabled={props.disabled}
      onChange={ onChange }
      onKeyDown={ onKeyDown }
      ref={ ref }
    />
  )
})

const Years = React.forwardRef((props, ref) => <TextInput { ...props } placeholder="Years" ref={ ref } />)
const Cohorts = (props) => (
  <Select
    className="form-control"
    { ...props }
  />
)
const Limit = (props) => <TextInput { ...props } placeholder="Limit" />

class Row extends React.Component {
  years = React.createRef()

  onChange = (value) => {
    const { index, onChange } = this.props

    onChange(index, value)
  }

  onYearsChange = (years) => this.onChange({ ...this.props.value, years })
  onCohortsChange = (cohorts) => this.onChange({ ...this.props.value, cohorts })
  onLimitChange = (limit) => this.onChange({ ...this.props.value, limit })

  focus = () => {
    if (this.years.current) {
      this.years.current.focus()
    }
  }

  onBlur = () => {
    this._blurTimeout = setTimeout(this.onBlurForSure, 50)
  }

  onFocus = () => {
    if (this._blurTimeout) {
      clearTimeout(this._blurTimeout)
    }
  }

  onBlurForSure = () => {
    const { value, index, onRemove } = this.props

    if (!value.years && !value.limit && !value.cohorts) {
      onRemove(index)
    }
  }

  maybeRemove = () => {
    this.onBlurForSure()
  }

  remove = () => {
    this.props.onRemove(this.props.index)
  }

  maybeAdd = () => {
    const { onAdd } = this.props

    if (onAdd) { onAdd() }
  }

  render() {
    const { value, cohortOptions } = this.props

    const rowCohortOptions = optionsWithMissingValue(cohortOptions, value.cohorts)

    return (
      <div className="limitations-row">
        <div className="limitations-cell">
          <Years
            value={ value.years }
            onChange={ this.onYearsChange }
            onBlur={ this.onBlur }
            onFocus={ this.onFocus }
            ref={ this.years }
            onEsc={ this.maybeRemove }
          />
        </div>
        {/* This is not supported with the new engine for the time being
        <div className="limitations-cell">
          <Cohorts
            options={ rowCohortOptions }
            value={ value.cohorts }
            onChange={ this.onCohortsChange }
            onBlur={ this.onBlur }
            onFocus={ this.onFocus }
          />
        </div>
        */}
        <div className="limitations-cell">
          <Limit
            value={ value.limit }
            onChange={ this.onLimitChange }
            onBlur={ this.onBlur }
            onFocus={ this.onFocus }
            onEnter={ this.maybeAdd }
          />
        </div>
        <div className="limitations-remove">
          <button
            type="button"
            onClick={ this.remove }
            className="limitations-remove-button"
          >×</button>
        </div>
      </div>
    )
  }
}

const EMPTY_ROW = {
  years: '',
  cohorts: '',
  limit: ''
}

export default class LimitationsInput extends React.Component {
  lastRowRef = React.createRef()

  static defaultProps = {
    value: []
  }

  componentDidUpdate(prevProps) {
    const { value, cohorts } = this.props

    if (prevProps.value.length + 1 === this.props.value.length) {
      if (this.lastRowRef && this.lastRowRef.current) {
        this.lastRowRef.current.focus()
      }
    }
  }

  add = () => {
    const { value, onChange } = this.props
    const newRow = { ...EMPTY_ROW }

    const lastYears = get(last(value), 'years')
    const lastYear = lastYears && parseInt(last(lastYears.toString().match(/\d+/g)), 10)

    if (lastYear && !Number.isNaN(lastYear)) {
      newRow.years = lastYear + 1
    }

    onChange([...value, newRow])
  }

  onRowChange = (newIndex, newValue) => {
    this.props.onChange(
      map(this.props.value, (value, index) => (
        index === newIndex ? newValue : value
      ))
    )
  }

  onRowRemove = (removeIndex) => {
    this.props.onChange(
      filter(this.props.value, (value, index) => (
        index !== removeIndex
      ))
    )
  }

  setExample = () => {
    this.props.onChange(cloneDeep(EXAMPLE))
  }

  render() {
    const { value, cohorts } = this.props

    const cohortOptions = getCohortOptions(cohorts)

    return (
      <div className="limitations-container">
        {
          map(value, (row, index) => (
            <Row
              key={ index }
              index={ index }
              value={ row }
              onChange={ this.onRowChange }
              onRemove={ this.onRowRemove }
              onAdd={ index === value.length - 1 ? this.add : null }
              cohortOptions={ cohortOptions }
              ref={ index === value.length - 1 ? this.lastRowRef : null }
            />
          ))
        }
        <div className="limitations-row limitations-row-add">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={ this.add }
          >Add</button>
          <div className="limitations-remove" />
        </div>
      </div>
    )
  }
}
