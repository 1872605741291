import React from 'react'
import CalibrationSubmitter from './Submitter'

export default class CanBeModelled extends React.PureComponent {
  onSubmitSuccess = (artefacts) => {
    if (artefacts.user_said_ok) {
      return (
        <p>The trend is as expected. The cohort can be modeled.</p>
      )
    }

    if (!artefacts.tests_met) {
      return (
        <p>
          Internal computations show that the program of
          abandonment does not justify the unexpected trend.
          The model may have poor validation.
        </p>
      )
    }
  }

  render() {
    return (
      <CalibrationSubmitter
        onSuccess={ this.onSubmitSuccess }
      >{ ({ Form, submitting }) => (
        <>
          <div className="calibration-info">
            <div className="form-group">
              <p>The chart shows:</p>
              <ul>
                <li>X-axis: Age</li>
                <li>
                  Left y-axis: Current Break Rate of the pipes that
                  reach that Age during the period of Breaks observation
                </li>
                <li>
                  Right y-axis: Current length of the pipes that
                  reach that Age during the period of Breaks observation
                </li>
              </ul>

              <p>Using the bottom ruler, select Age for which length is substantial.</p>
              <p>The Break Rate is expected to increase as the Age increases.</p>
              <p>Is the Break Rate increasing as the Age increases?</p>
            </div>
          </div>
          <div className="calibration-submit">
            <div className="row">
              <div className="col-6">
                <Form>
                  <input type="hidden" name="complete[user_said_ok]" value="1" />
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg"
                    disabled={ submitting }
                  >Yes</button>
                </Form>
              </div>
              <div className="col-6">
                <Form>
                  <input type="hidden" name="complete[user_said_not_ok]" value="1" />
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg"
                    disabled={ submitting }
                  >No</button>
                </Form>
              </div>
            </div>
          </div>
        </>
      )}</CalibrationSubmitter>
    )
  }
}
