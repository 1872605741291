import React from 'react'
import CalibrationSubmitter from './Submitter'

class Step1 extends React.PureComponent {
  render() {
    const {
      onNo, Form, submitting
    } = this.props

    return (
      <div>
        <div className="calibration-info">
          <p>
            In order to validate a model it must be run on a period of time
            shorter than the window of breaks observation (say what it is in parenthesis).
            The predicted breaks over the remaining of the window of breaks observation
            are compared to the actual breaks that occurred during that time.
          </p>
          <p>The Chart shows:</p>
          <ul>
            <li>X-axis: YEAR</li>
            <li>
              Left y-axis: Break Rate at YEAR of the pipes still ACT at YEAR
            </li>
            <li>
              Right y-axis: Net Cumulative length of the pipes still ACT at YEAR
            </li>
          </ul>

          <p>Is the Break Rate increasing steadily as the YEAR increases?</p>
        </div>
        <div className="calibration-submit text-center">
          <div className="row">
            <div className="col-6">
              <Form>
                <input type="hidden" name="complete[break_rate_increasing]" value="true" />

                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={ submitting }
                >Yes</button>
              </Form>
            </div>
            <div className="col-6">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={ onNo }
                disabled={ submitting }
              >No</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class Step2 extends React.PureComponent {
  onSubmit = (e) => {
    if (!this.state.year) {
      e.preventDefault()
    } else {
      this.props.setSubmitting()
    }
  }

  render() {
    const {
      onBack, onYes, Form, submitting,
      year, onYearChange
    } = this.props

    return (
      <Form>
        <input type="hidden" name="_method" value="PATCH" />

        <div className="calibration-info">
          <div className="mb-4">
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              onClick={ onBack }
              disabled={ submitting }
            >← I&apos;m not sure, go back</button>
          </div>

          <p>Give the YEAR when the Break Rate started to decline?</p>

          <input
            type="text"
            value={ year }
            onChange={ onYearChange }
            name="complete[year]"
            className="form-control year-input mt-2 mb-5"
            autoComplete="off"
            placeholder="• • • •"
            autoFocus
          />
        </div>
        <div className="calibration-submit text-center">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={ onYes }
            disabled={ !year || submitting }
          >Continue →</button>
        </div>
      </Form>
    )
  }
}

class Step3 extends React.PureComponent {
  render() {
    const {
      onBack, Form, submitting, year
    } = this.props

    return (
      <div>
        <div className="calibration-info">
          <div className="mb-4">
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              onClick={ onBack }
              disabled={ submitting }
            >← I&apos;m not sure, go back</button>
          </div>

          <p>
            The Break Rate is not increasing as the YEAR increases.
            The trend is not as expected.
            However, internal computations show that the program of
            ABN does not justify the unexpected trend.
          </p>

          <p>
            If there an external reason why the Break Rate has
            recently declined (clement weather; change of pressure)?
          </p>
        </div>
        <div className="calibration-submit text-center">
          <div className="row">
            <div className="col-6">
              <Form>
                <input type="hidden" name="_method" value="PATCH" />
                <input type="hidden" name="complete[break_rate_unstable_for_external_reason]" value="true" />
                <input type="hidden" name="complete[year]" value={ year } />

                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={ submitting }
                >Yes</button>
              </Form>
            </div>
            <div className="col-6">
              <Form>
                <input type="hidden" name="_method" value="PATCH" />
                <input type="hidden" name="complete[break_rate_unstable_for_no_reason]" value="true" />

                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={ submitting }
                >No</button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default class ValidationPeriod extends React.PureComponent {
  state = {
    year: '',
    step: '1'
  }

  onYearChange = (e) => this.setState({ year: e.currentTarget.value })

  goToStep1 = () => this.setState({ step: '1' })
  goToStep2 = () => this.setState({ step: '2' })
  goToStep3 = () => this.setState({ step: '3' })

  onSubmitSuccess = (artefacts) => {
    const { calibration_period, validation_period } = artefacts

    const periods = (
      <>
        <p>The period of calibration should be: { calibration_period.join('-') }</p>
        <p>The period of validation should: { validation_period.join('-') }</p>
      </>
    )

    if (artefacts.break_rate_unstable_for_no_reason) {
      return (
        <>
          <p>
            The unexpected trend is not due to better weather or
            lower pressure during the period of validation.
          </p>
          { periods }
          <p>Expect poor validation.</p>
        </>
      )
    }

    if (artefacts.break_rate_unstable_for_external_reason) {
      return (
        <>
          <p>
            The unexpected trend may be due to better weather or lower
            pressure during the period of validation.
          </p>
          { periods }
        </>
      )
    }

    if (artefacts.tests_met) {
      return (
        <>
          <p>
            The Break Rate is not increasing as the YEAR increases.
            The trend is not as expected.  However, internal computations
            show that the program of ABN justifies the unexpected trend.
          </p>
          { periods }
        </>
      )
    }

    if (artefacts.break_rate_increasing) {
      return (
        <>
          <p>
            The Break Rate is increasing as the YEAR increases. The trend is as expected.
          </p>
          { periods }
        </>
      )
    }
  }

  onFailure = (errors) => {
    const error = errors[0]

    if (error === 'tests_failed') {
      this.goToStep3()

      return null
    }

    return error
  }

  render() {
    const { step, year } = this.state

    return (
      <CalibrationSubmitter
        onSuccess={ this.onSubmitSuccess }
        onFailure={ this.onFailure }
      >{ ({ Form, submitting }) => {
        const commonProps = {
          submitting,
          Form
        }

        switch (step) {
          case '1':
            return (
              <Step1
                { ...commonProps }
                onNo={ this.goToStep2 }
              />
            )
          case '2':
            return (
              <Step2
                { ...commonProps }
                onBack={ this.goToStep1 }
                year={ year }
                onYearChange={ this.onYearChange }
              />
            )
          case '3':
            return (
              <Step3
                { ...commonProps }
                year={ year }
                onBack={ this.goToStep1 }
              />
            )
          default:
            return null
        }
      }}</CalibrationSubmitter>
    )
  }
}
