import React from 'react'
import memoize from 'lru-memoize'
import Select from '../Select'


const TOTAL_VALUE = '___!!!TOTAL!!!___'
const YEARLY_VALUE = '___!!!YEAR!!!___'
const BY_AGE_VALUE = '___!!!BY_AGE!!!___'
const PERIOD_VALUE = '___!!!PERIOD!!!___'

const getOptions = memoize(1)((bareOptions) => ([
  ['', TOTAL_VALUE],
  ['YEAR', YEARLY_VALUE],
  ['PERIOD', PERIOD_VALUE],
  ['AGE', BY_AGE_VALUE],
  ...bareOptions
]))

const getValue = (type, id) => {
  if (type === 'total') return TOTAL_VALUE
  if (type === 'yearly') return YEARLY_VALUE
  if (type === 'by_age') return BY_AGE_VALUE
  if (type === 'period') return PERIOD_VALUE

  return id
}

export default class BarXAxisSelect extends React.Component {
  onChange = (value) => {
    const { onChange } = this.props

    if (value === TOTAL_VALUE) {
      onChange({ type: 'total', id: '' })
    } else if (value === YEARLY_VALUE) {
      onChange({ type: 'yearly', id: '' })
    } else if (value === BY_AGE_VALUE) {
      onChange({ type: 'by_age', id: '' })
    } else if (value === PERIOD_VALUE) {
      onChange({ type: 'period', id: '' })
    } else {
      onChange({ type: 'covariate', id: value })
    }
  }

  render() {
    const { aggregationType, value: bareValue, options: bareOptions } = this.props

    const value = getValue(aggregationType, bareValue)
    const options = getOptions(bareOptions)

    return (
      <Select
        options={ options }
        value={ value }
        onChange={ this.onChange }
        className="form-control"
        includeBlank={ false }
      />
    )
  }
}
