import React from 'react'
import { each, sumBy, transform } from 'lodash'
import memoize from 'lru-memoize'

export const tickLabel = (_tickValue, _index) => ({
  fontFamily: 'Arial',
  fontSize: 14,
  fill: '#000'
})

export const tickLabelLeft = (_tickValue, _index) => ({
  ...tickLabel(_tickValue, _index),
  textAnchor: 'end',
  dx: '-0.25em',
  dy: '0.25em',
})

export const tickLabelRight = (_tickValue, _index) => ({
  ...tickLabel(_tickValue, _index),
  textAnchor: 'start',
  dx: '0.25em',
  dy: '0.25em'
})

export const tickLabelBottom = (_tickValue, _index) => ({
  ...tickLabel(_tickValue, _index),
  textAnchor: 'middle',
  dy: '0.25em'
})

export const gray = '#777'

export const palette = ['#1f77b4', '#ff7f0e', '#ffbb78', '#2ca02c', '#98df8a', '#d62728', '#ff9896', '#9467bd', '#c5b0d5', '#8c564b', '#c49c94', '#aec7e8', '#e377c2', '#f7b6d2', '#7f7f7f', '#c7c7c7', '#bcbd22', '#dbdb8d', '#17becf', '#9edae5']

export const numTicksFromWidth = (width, perTick = 50) => Math.floor(width / perTick)
export const numTicksFromHeight = (height, perTick = 50) => Math.floor(height / perTick)

export const titleStyle = {
  textAnchor: 'middle',
  dy: '1em',
  fontSize: 20,
  fill: '#000',
  fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
}

export const axisLabelStyle = {
  textAnchor: 'middle',
  fontSize: 18,
  fill: '#000',
  fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
}

export const minmax = memoize(2)((series, accessor) => {
  let minValue
  let maxValue

  each(series, (arr) => {
    each(arr.data, ({ [accessor]: value }) => {
      if (minValue === undefined || (value < minValue)) minValue = value
      if (maxValue === undefined || (value > maxValue)) maxValue = value
    })
  })

  return [minValue, maxValue]
})

export const stackedMinmax = memoize(50)((series, accessor) => {
  let minValue
  let maxValue

  const length = series && series[0] && series[0].data && series[0].data.length

  if (length && length > 0) {
    for (let i = 0; i < length; i += 1) {
      const value = sumBy(
        series,
        (s) => parseFloat(s.data[i][accessor])
      ) // eslint-disable-line no-loop-func

      if (minValue === undefined || (value < minValue)) minValue = value
      if (maxValue === undefined || (value > maxValue)) maxValue = value
    }
  }

  return [minValue, maxValue]
})

export const getColorsMap = memoize(50)((series) => {
  let paletteIndex = 0

  return transform(series, (result, { id, label, color }) => {
    const key = id || label
    if(key === "New Pipes") {
      result[key] = "url(#new-pipes-gradient)"
    } else {
      result[key] = color // eslint-disable-line no-param-reassign

      if (!result[key]) {
        result[key] = palette[paletteIndex] // eslint-disable-line no-param-reassign
        paletteIndex += 1
      }
    }
  }, {})
})
