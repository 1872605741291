import React from 'react'
import c from 'classnames'
import {
  each, reduce, trim, range,
  toPairs, sortBy, min, max
} from 'lodash'
import memoize from 'lru-memoize'
import Textarea from 'react-textarea-autosize'

const PLACEHOLDER = [
  '2020-2030: 50',
  '2030-2040: 30',
  '2041: 10',
  '2042: 20',
  '2043: 30',
  '2044: 40',
  '2045: 50',
  '2046: 60',
  '2047: 70',
  '2048: 80',
  '2049: 90',
  '2050: 100',
].join('\n')

const lineToText = (currentLine) => {
  if (!currentLine) return ''

  const years = currentLine[0].length > 1 ? `${min(currentLine[0])}-${max(currentLine[0])}` : currentLine[0][0]

  return `${years}: ${currentLine[1]}\n`
}

const valueToText = memoize(1)((value) => {
  const values = sortBy(toPairs(value), ([year]) => year)
  let result = ''
  let currentLine = null

  for (let i = 0; i < values.length; i += 1) {
    const currentYear = parseInt(values[i][0], 10)
    const currentLimit = values[i][1]
    const prevYear = i > 0 && parseInt(values[i - 1][0], 10)
    const prevLimit = i > 0 && values[i - 1][1]

    if (
      prevYear
      && prevLimit
      && currentYear === prevYear + 1
      && currentLimit === prevLimit
    ) {
      currentLine[0].push(currentYear)
    } else {
      result += lineToText(currentLine)

      currentLine = [[currentYear], currentLimit]
    }
  }

  result += lineToText(currentLine)

  return result
})

const textToValue = memoize(1)((text) => {
  try {
    return reduce(text.split('\n'), (result, line) => {
      if (!trim(line)) { return result }

      let [years, limit] = line.split(':') // eslint-disable-line prefer-const

      limit = limit && parseInt(trim(limit), 10)

      if (!limit || Number.isNaN(limit)) throw new Error('limit is wrong')

      if (years.indexOf('-') !== -1) {
        let [from, to] = years.split('-')
        from = from && parseInt(from, 10)
        to = to && parseInt(to, 10)

        if (!from || Number.isNaN(from)) throw new Error('from is wrong')
        if (!to || Number.isNaN(to)) throw new Error('to is wrong')
        if (from > to) throw new Error('from > to')

        each(range(from, to + 1), (year) => {
          result[year] = limit // eslint-disable-line no-param-reassign
        })
      } else {
        const year = parseInt(years, 10)
        if (!year || Number.isNaN(year)) throw new Error('from > to')

        result[year] = limit // eslint-disable-line no-param-reassign
      }

      return result
    }, {})
  } catch (e) {
    if (typeof console !== 'undefined') {
      console.error(e) // eslint-disable-line no-console
    }

    return null
  }
})

export default class LimitationsInput extends React.Component {
  state = {
    text: valueToText(this.props.value),
    invalid: true
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      const text = valueToText(nextProps.value)
      this.setState({ text })
    }
  }

  onChange = (e) => {
    this.setState({ text: e.currentTarget.value, invalid: false })
  }

  setExample = () => {
    this.setState({ text: PLACEHOLDER, invalid: false }, this.onBlur)
  }

  onBlur = () => {
    const { onChange } = this.props
    const { text } = this.state

    const value = textToValue(text)

    if (value === null) {
      this.setState({ invalid: true })
    } else {
      onChange(value)
    }
  }

  render() {
    const { className, disabled } = this.props
    const { text, invalid } = this.state

    return (
      <Textarea
        value={ text }
        onChange={ this.onChange }
        onBlur={ this.onBlur }
        disabled={ disabled }
        className={ c('form-control', className, invalid && 'text-danger') }
      />
    )
  }
}
