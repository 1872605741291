import { min, map, memoize } from 'lodash'

export default memoize((prediction) => {
  const {
    break_rates,
    geometric_adjustment_factor: FACTOR
  } = prediction

  const BASE_AGE = min(map(Object.keys(break_rates), (i) => parseFloat(i)))
  const BASE_RATE = break_rates[BASE_AGE]

  const breakRateByAge = (x) => BASE_RATE * (FACTOR ** (x - BASE_AGE))

  /*
   * x = BREAK_RATE * (FACTOR ^ (y - BASE_AGE))
   * x / BREAK_RATE = FACTOR ^ (y - BASE_AGE)
   * log(x / BREAK_RATE) = log(FACTOR ^ (y - BASE_AGE))
   * log(x / BREAK_RATE) = (y - BASE_AGE) * log(FACTOR)
   * log(x / BREAK_RATE) / log(FACTOR) + BASE_AGE = y
   */
  const ageByBreakRate = (x) => Math.log(x / BASE_RATE) / Math.log(FACTOR) + BASE_AGE

  return {
    breakRateByAge,
    ageByBreakRate
  }
})
