import React from 'react'
import { map } from 'lodash'
import CalibrationSubmitter from '../Submitter'

import Chart from '../Chart'

const { $ } = window

const LOADING = (
  <div className="calibration-loading">
    <i className="icon big spinner spin" />
  </div>
)

const Error = ({ retry }) => (
  <div>
    <div className="alert alert-danger">
      Something is wrong.
    </div>
    <button
      type="button"
      className="btn btn-primary btn-sm"
      onClick={ retry }
    >Retry</button>
  </div>
)


export default class Qualitative extends React.Component {
  state = {
    loading: false,
    can_be_split: undefined,
    can_be_used: undefined,
    error: false,
    page: 'cohort'
  }

  componentDidMount() {
    this.runTests()
  }

  goToUse = () => this.setState({ page: 'use' })

  componentDidUpdate() {
    if (this.state.can_be_split === false && this.state.page === 'cohort') {
      this.goToUse()
    }
  }

  runTests = () => {
    this.setState({ error: false, loading: true })

    $.post(this.props.run_tests_url).then(
      ({ result: { can_be_split, can_be_used }}) => {
        this.setState({
          loading: false,
          can_be_split,
          can_be_used
        })
      },
      () => this.setState({ error: true, loading: false })
    )
  }

  render() {
    const {
      chart_url, label, base_variable: { id, name },
      possible_values
    } = this.props

    const {
      loading,
      error,
      page,
      can_be_used
    } = this.state

    let content = null

    if (loading) {
      content = LOADING
    } else if (error) {
      content = <Error retry={ this.runTests } />
    } else if (page === 'cohort') {
      content = (
        <CalibrationSubmitter>{ ({ Form, submitting }) => (
          <>
            <div className="calibration-info">
              <p>
                Internal computations show that the cohort could be split
                based on the discretized { name }. We could now have the following cohorts:
              </p>

              <ul>
                { map(possible_values, (value) => (
                  <li key={ value }>{ label } ({ name } { value })</li>
                ))}
              </ul>

              <p>
                You may also choose not to split the cohort based on that
                discretized variable and introduce it as a co-variate in the model.
              </p>
            </div>
            <div className="calibration-submit">
              <div className="row calibration-variables-variable-buttonsRow">
                <div className="col-6">
                  <Form>
                    <input type="hidden" name="complete[split]" value="true" />
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={ submitting }
                    >Split</button>
                  </Form>
                </div>
                <div className="col-6">
                  <button
                    type="button"
                    className="btn btn-primary"
                    disabled={ submitting }
                    onClick={ this.goToUse }
                  >Do not split</button>
                </div>
              </div>
            </div>
          </>
        )}</CalibrationSubmitter>
      )
    } else if (page === 'use') {
      content = (
        <CalibrationSubmitter>{ ({ Form, submitting }) => (
          <>
            <div className="calibration-info">
              { can_be_used && (
                <p>
                  Internal computations show that the cohort cannot be split based
                  on { name } and that it is recommended to introduce { name } in the model. 
                </p>
              )}
              { !can_be_used && (
                <>
                  <p>
                    Internal computations show that it is not recommended to introduce the { name } in the model.
                  </p>
                  <p>
                    You may also choose to introduce the variable in the COHORT model anyway.
                  </p>
                </>
              )}
            </div>
            <div className="calibration-submit">
              { can_be_used && (
                <Form>
                  <input type="hidden" name="complete[include_base]" value="true" />
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={ submitting }
                  >Continue</button>
                </Form>
              )}
              { !can_be_used && (
                <div className="row calibration-variables-variable-buttonsRow">
                  <div className="col-6">
                    <Form>
                      <input type="hidden" name="complete[dummy]" value="true" />
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={ submitting }
                      >Continue</button>
                    </Form>
                  </div>
                  <div className="col-6">
                    <Form>
                      <input type="hidden" name="complete[include_base]" value="true" />
                      <button
                        type="submit"
                        className="btn btn-secondary"
                        disabled={ submitting }
                      >Use anyway</button>
                    </Form>
                  </div>
                </div>
              )}
            </div>
          </>
        )}</CalibrationSubmitter>
      )
    }

    return (
      <div className="calibration-columns">
        <div className="calibration-chart">
          <Chart
            base_url={ chart_url }
            covariate_id={ id }
          />
        </div>
        <div className="calibration-content">
          { content }
        </div>
      </div>
    )
  }
}
