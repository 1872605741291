import React, { useState, useEffect } from 'react'
import { CSVLink } from "react-csv";
import PredictionsTable from '../MLPredictionsTable'
import MLStatus from '../MLStatus'

export default function MLPredictions(props) {
    const [pipes, setPipes] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [resultsExist, setResultsExist] = useState(false)

    const projectId = Api.getProjectId()
  
    useEffect(() => {
      Api.get(`projects/${projectId}/ml/meta`)
        .then(x => {
          setResultsExist(true)
        })
        .catch(error => {
          console.log(error)
          if (!error.notFound) {
            setResultsExist(true)  
          }
        })
    }, [])

    useEffect(() => {
      Api.get(`projects/${projectId}/ml/pipes`, { type: "prediction" })
        .then(x => {
          setIsLoading(false)
            setPipes(
                x.map(pipe => {
                    const t = pipe
                    t["location"] = t["Location"]
                    delete t["Location"]
                    delete t["abandonment_date"]
                    delete t["age"]
                    return t
                })
            )
        })
        .catch(x => {
          setIsLoading(false)
          // toastr.error("Could not fetch pipe data!")
        })
    }, [])

    return (
      <>
      <MLStatus disabled={isLoading} />
      { isLoading && <div>Fetching data...</div>}
      { !isLoading && pipes.length == 0 && <p>No machine learning pipeline runs were found for this project. Please run the pipeline and wait for results.</p>}
      { !isLoading && resultsExist && pipes.length > 0 &&
        <div className="mt-5">
          <div>
            <div className="row">
              <CSVLink
                data={pipes}
                target="_blank"
                className="btn btn-secondary btn-sm"
                filename={"export.csv"}
              >
                Export to CSV
              </CSVLink>
            </div>
            <div className="row">
              <PredictionsTable data={pipes} />
            </div>
          </div>
      </div>
    }
    </>
  )
}
