import React, {useState} from 'react';

export default function ({tab}) {
    const [isHovered, setIsHovered] = useState(false);

    const tabStyle = {
        paddingLeft: "1rem",
        paddingRight: "1rem",
        minWidth: "200px",
        display: "inline-block",
        paddingTop: "0.5rem",
        paddingBottom: "0.6rem",
        textAlign: "center",
        margin: "auto",
        color: tab.active ? "#000" : "#FFFFFF" ,
        background: tab.active ? "#fff" : isHovered ? "#5b636b" : "#a9b3bb",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        border: "1px solid " + tab.active ? "#fff" : "#a9b3bb",
        fontWeight: tab.active ? "bold" : "regular"
    }

    return (
        <a href={tab.href}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <li style={tabStyle}>
                {tab.text}
            </li>
        </a>
    )
}