import React from 'react'
import { each, isString } from 'lodash'
import memoize from 'lru-memoize'
import BarsAndLine from './BarsAndLine'

const { MATERIAL_STYLES: MATERIAL_COLORS } = window

const ensureFloat = (value) => (isString(value) ? parseFloat(value) : value)

const getSeries = memoize()((settings, payload) => {
  const {
    yleft,
    yright,
    yleft_legend,
    yleft_unit,
    yright_legend,
    yright_unit,
    aggregate_tag
  } = settings

  const leftLabel = `${yleft_legend}${yleft_unit ? ` (${yleft_unit})` : ''}`
  const rightLabel = `${yright_legend}${yright_unit ? ` (${yright_unit})` : ''}`

  const bar = yleft && {
    label: leftLabel,
    color: '#C63A33',
    data: []
  }
  const line = yright && {
    label: rightLabel,
    color: 'black',
    data: []
  }

  each(payload, (point) => {
    const x = point[aggregate_tag]

    if (yleft) {
      bar.data.push({
        x,
        y: ensureFloat(point[yleft]),
        color: MATERIAL_COLORS[x]
      })
    }
    if (yright) {
      line.data.push({
        x,
        y: ensureFloat(point[yright])
      })
    }
  })

  return { bar, line }
})

export default ({ settings, payload }) => {
  const { bar, line } = getSeries(settings, payload)
  const { title, use_brush, x_legend } = settings

  return (
    <BarsAndLine
      line={ line }
      bar={ bar }
      useBrush={ use_brush }
      bottomLabel={ x_legend }
      title={ title }
    />
  )
}
