export function calculateMostFittingPipe(breakData) {
    const pipes = breakData.pipes;
    const brokeDate = new Date(breakData.brokeOn);

    const rankedPipes = pipes.map(pipe => {
        // Define missing data condition based on life_status
        const missing_data =
            pipe.life_status === 'ACT' ? !pipe.installed_on : !pipe.installed_on || !pipe.abandoned_on;

        let rank;

        const installedDate = pipe.installed_on ? new Date(pipe.installed_on) : null;
        const abandonedDate = pipe.abandoned_on ? new Date(pipe.abandoned_on) : null;

        if (pipe.life_status === 'ACT') {
            if (!missing_data && (installedDate <= brokeDate)) {
                rank = 2; // coherent
            } else if (!missing_data && (installedDate > brokeDate)) {
                rank = 6; // incoherent
            } else {
                rank = 4; // missing
            }
        } else { // ABN
            if (!missing_data &&
                installedDate <= brokeDate && abandonedDate >= brokeDate && installedDate <= abandonedDate) {
                rank = 1; // coherent
            } else if (!missing_data &&
                !(installedDate <= brokeDate && abandonedDate >= brokeDate && installedDate <= abandonedDate)) {
                rank = 5; // incoherent
            } else {
                rank = 3; // missing
            }
        }
        return { ...pipe, rank };
    });

    // Sort pipes by rank, installed_on presence and value, and length
    rankedPipes.sort((a, b) => {
        // Sort by rank first
        if (a.rank !== b.rank) return a.rank - b.rank;
        // If ranks are the same, check installed_on presence
        if (a.installed_on && !b.installed_on) return -1;
        if (!a.installed_on && b.installed_on) return 1;
        // If installed_on is present for both, compare their values
        if (a.installed_on && b.installed_on) {
            const aInstalledDate = new Date(a.installed_on);
            const bInstalledDate = new Date(b.installed_on);
            if (aInstalledDate < bInstalledDate) return -1;
            if (aInstalledDate > bInstalledDate) return 1;
        }
        // If installed_on is not present or values are the same, compare length (longest pipe wins)
        return b.length - a.length;
    });

    // Return the most fitting pipe
    return rankedPipes[0];
}


export function getAssignedPipe(brk, newPipe) {
    if (!newPipe) return null;
    return typeof newPipe !== "undefined" ? newPipe : brk.selectedPipeId;
}