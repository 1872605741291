import React, {useState} from 'react'

import { HexColorPicker } from "react-colorful";

export default ({ color, onUpdateColor, onClickSave, onClickCancel }) => {

	return <div style={
		{
			position: 'fixed',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			margin: 5,
			padding: '100%',
			backdropFilter: '10px',
			borderRadius: '10px',
			background: '#e0e0e0aa'
		}}>
		<HexColorPicker color={color}
						onChange={onUpdateColor}
			/>
			<div style={{ display: "flex", flexDirection: "row" }}>
				<button
					style={{ width: '50%' }}
					className={"btn btn-primary"}
					onClick={onClickSave}><i className={"fa fa-save"}></i> Save</button>
				<button
					style={{ width: '50%' }}
					className={"btn btn-secondary"}
					onClick={onClickCancel}><i className={"fa fa-cancel"}></i> Cancel</button>
			</div>

		</div>;
}