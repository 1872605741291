import React from 'react'
import { map } from 'lodash'

const TABS = [
  ['curve', 'Aging Curve'],
  ['model', 'Model']
]

export default class TabSelect extends React.Component {
  onChange(value, e) {
    const { onChange } = this.props
    e.preventDefault()

    onChange(value)
  }

  render() {
    const { selected } = this.props

    return (
      <ul className="nav nav-tabs mb-2 mt-4">
        { map(TABS, ([value, name]) => (
          <li key={ value } className="nav-item">
            <a
              key={ value }
              href="#"
              className={ `nav-link ${value === selected ? 'active' : ''}` }
              onClick={ this.onChange.bind(this, value) }
            >
              { name }
            </a>
          </li>
        )) }
      </ul>
    )
  }
}
