import React from 'react'
import c from 'classnames'

import Button from '../../Button'

export default class CohortActions extends React.Component {
  form = React.createRef()

  state = {
    uploading: false
  }

  confirmDeletion = (e) => {
    const { cohort } = this.props

    if (!window.confirm(`Delete '${cohort.name}' cohort?`)) { // eslint-disable-line no-alert
      e.preventDefault()
      e.stopPropagation()
    }
  }

  uploadModel = (e) => {
    const input = e.currentTarget

    if (input.value) {
      const form = this.form.current

      form.submit()
      this.setState({ uploading: true })
    }
  }

  render() {
    const { cohort, urls } = this.props
    const { uploading } = this.state

    return (
      <div className="dropdown cohorts-cohort-actions">
        <a
          className="btn btn-light dropdown-toggle"
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          { cohort.name }
        </a>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <a
            href={ urls.edit_cohort_url }
            className="dropdown-item"
          >Edit</a>
          <a
            className="dropdown-item"
            target="_blank"
            rel="noopener noreferrer"
            href={ urls.download_configured_casses_input_url }
          >Create Casses input file </a>
          <form
            method="post"
            className="cohorts-cohort-prediction-upload"
            encType="multipart/form-data"
            action={ urls.upload_prediction_url }
            ref={ this.form }
          >
            <label className={ c('dropdown-item', uploading && 'disabled') }>
              <input
                type="file"
                name="casses_output_zip"
                onChange={ this.uploadModel }
                disabled={ uploading }
              />
              Upload Casses output results
            </label>
          </form>
          <Button
            method="DELETE"
            className="dropdown-item"
            onClick={ this.confirmDeletion }
            href={ urls.delete_cohort_url }
          >Delete</Button>
        </div>
      </div>
    )
  }
}
